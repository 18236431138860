import { container, title } from "assets/jss/material-kit-react.js";

const landingPageStyle = theme => ({
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    width: 'unset',
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  sm1: {
    order: '2',
    [theme.breakpoints.down('xs')]: {
      order: '1',
      marginBottom: '10px'
    }
  },
  sm2: {
    order: '1',
    [theme.breakpoints.down('xs')]: {
      order: '2',
    }
  },
  rowSmallCol: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  rowMdCol:{
    flexDirection: 'row',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  rowMlCol:{
    flexDirection: 'row',
    alignItems: 'flex-end',
    "@media (max-width: 1199px)": {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  showSmall: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex'
    }
  },
  hideSmall: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  hideM: {
    // display: 'flex',
    "@media (max-width: 992px)":{
      display: 'none',
    }
  },
  showM: {
    display: 'none',
    "@media (max-width: 992px)":{
      display: 'flex'
    }
  },
  searchBox: {
    '& .MuiFormControl-root': {
      margin: '0'
    }
  },
  resDia: {
    zIndex: '9500',
    margin: '0 auto',
    maxWidth: '800px',
    '& .MuiDialog-paper': {
      minWidth: '80%',
      [theme.breakpoints.down('xs')]: {
        margin: '0',
        width: '100%'
      },
      '& .MuiDialogTitle-root': {
  
        [theme.breakpoints.down('xs')]: {
          display: 'flex',
          flex: 'row',
          justifyContent: 'flex-start',
          padding: '16px'
        },
  
        '& .MuiButtonBase-root': {
          [theme.breakpoints.down('xs')]: {
            marginRight: '20px'
          },
        }
      },
      '& .MuiDialogContent-root':{
        [theme.breakpoints.down('xs')]: {
          padding: '20px'
        },
        '& .MuiFormControl-root': {
          [theme.breakpoints.down('xs')]: {
            width: '100%'
          }, 

        },
        '& .MuiPaper-root': {
          width: '100%',
          margin: '3px 0'
        }     
      }
    }
  },
});

export default landingPageStyle;
