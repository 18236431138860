import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Redirect, Link } from 'react-router-dom';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderRightLinks from "components/Header/HeaderRightLinks.js";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.js";
import TextField from '@material-ui/core/Textfield';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import Divider from '@material-ui/core/Divider';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import MapIcon from '@material-ui/icons/Map';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import RoomIcon from '@material-ui/icons/Room';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LocationCityIcon from '@material-ui/icons/LocationCity';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import './searchResult.css';

import findingFaberLogo from '../../assets/img/FindingFaber-Logo/FFapp_icon only.png';

import api from '../../utils/api';
import Radio from '@material-ui/core/Radio';

//Dialog
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";


// @material-ui/SnackBar
import Snackbar from '@material-ui/core/Snackbar';
import Portal from '@material-ui/core/Portal';

import DialogContentText from '@material-ui/core/DialogContentText';
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Fab from '@material-ui/core/Fab';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';

import DateRangeIcon from '@material-ui/icons/DateRange';

import CircularProgress from '@material-ui/core/CircularProgress';

//Share Components
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const usePaperStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 200,
    minWidth: 200,
    minHeight: 404
  },
  // merchantRoot: {
  //   minWidth: 300,
  //   maxWidth: 300,
  // },
  media: {
    height: 200,
  },
}));

export default function ListResult(props) {
  const { ...rest } = props;
  const cardClasses = usePaperStyles();
  const classes = useStyles();
  const [searchK, setSearchK] = React.useState('');
  const [searchCity, setSearchCity] = React.useState('');
  const [promotionList, setPromotionList] = React.useState([]);
  const [merchantList, setMerchantList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [searchObj, setSearchObj] = React.useState('');
  const [searchType, setSearchType] = React.useState('M');
  const [showAll, setShowAll] = React.useState(false);
  const [userZip, setUserZip] = React.useState(props.userZip);
  const [isLocationGranted, setLocationPermission] = React.useState(null);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [showRadius, setShowRadius] = React.useState(null);
  const [radius, setRadius] = React.useState(5);
  const [selectedCat, setSelectedCat] = React.useState({
    categoryID: 0,
    categoryName: 'All',
    subs: false
  });
  const [selectedSubCat, setSelectedSubCat] = React.useState({
    categoryID: 0,
    subCategoryID: 0,
    subCategoryName: "All"
  });
  const [showCat, setShowCat] = React.useState(false);
  const [showFilterBtn, setShowFilterBtn] = React.useState(false);
  const [showSubCat, setShowSubCat] = React.useState(false);
  const [categoryList, setCategoryList] = React.useState([]);
  const [showOpenOnly, setShowOpenOnly] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState(false);
  const [shouldSnackBarOpen, setShouldSnackBarOpen] = React.useState(false);
  const [searchedKey, setSearchedKey] = React.useState('');
  const [openMerchantDetail, setOpenMerchantDetail] = React.useState(false);
  const [openPromoDetail, setOpenPromoDetail] = React.useState(false);
  const [selectedMerchant, setSelectedMerchant] = React.useState(false);
  const [selectedPromotion, setSelectedPromotion] = React.useState(false);
  const [openSharesOptions, setOpenSharesOptions] = React.useState(null);
  const [isInitWithObj, setisInitWithObj] = React.useState(1);
  const [showNoDeals, setShowNoDeals] = React.useState(false);

  const handleShareOpen = (event) => {
    setOpenSharesOptions(event.currentTarget);
  };

  const handleShareClose = () => {
    setOpenSharesOptions(null);
  };

  const todayDay = moment().format('dddd');

  const handleClosePromo = () => {
    setOpenPromoDetail(false);
  }

  const handleOpenPromo = (promo) => {
    setSelectedPromotion(promo);
    setOpenPromoDetail(true);
    api.increasePromotionCount(promo.merchantPromotionID).then(res => {
      console.log("promoDetailcount-result: ", res);
    });
  }

  const handleViewDeals = () => {
    setIsLoading(true)
    api.getMerchantPromotion({
      merchantID: selectedMerchant.merchantID,
      idToken: null
    }).then(res => {
      if (res.data.length > 0) {
        setShowAll(false);
        setSearchType("P");
        setShowNoDeals(false);
        res.data.forEach((p, i) => {
          p.merchantName = selectedMerchant.merchantName;
          p.address1 = selectedMerchant.address1;
          p.city = selectedMerchant.city;
          p.state = selectedMerchant.state;
          p.zip = selectedMerchant.zip;
          p.contactName = selectedMerchant.contactName;
          p.logoFile = selectedMerchant.logoFile;
          p.merchantEmail = selectedMerchant.merchantEmail;
          p.phone = selectedMerchant.phone;
          p.website = selectedMerchant.website;
        });
        setPromotionList(res.data);
        setOpenMerchantDetail(false);
        setIsLoading(false);
      } else {
        setShowNoDeals(true);
        setIsLoading(false);
      }
    });
  }

  const handleCloseMerchant = () => {
    setOpenMerchantDetail(false);
  }

  const handleOpenMerchant = (merch) => {
    // console.log(merch);
    // console.log(selectedMerchant);
    // console.log(selectedPromotion);
    api.getMerchantInfos(merch.merchantID).then((res) => {
      setSelectedMerchant(res.data);
      setOpenMerchantDetail(true);
      setIsLoading(false);
      api.increaseMerchantCount(merch.merchantID).then(res => {
        console.log("Increase res: ", res);
      });
    })
  }

  const openSnackBar = (msg) => {
    setSnackMsg(msg);
    setShouldSnackBarOpen(true);
  }

  const handleSnackBarClose = () => {
    setShouldSnackBarOpen(false);
    setSnackMsg('');
  }

  const handleOpenOnly = () => {
    setShowOpenOnly(!showOpenOnly);
  }

  const handleOpenSubCat = (event) => {
    setShowSubCat(event.currentTarget);
  }

  const handleOpenCat = (event) => {
    setShowCat(event.currentTarget);
  }

  const handleOpenRadiusSet = (event) => {
    setShowRadius(event.currentTarget);
  };

  const handleCloseRadiusSet = (val) => {
    setRadius(val);
    setShowRadius(false);
  };

  const handleCloseCat = (val) => {
    setSelectedCat(val);
    // console.log("val: ", val);
    if (val.subs) {
      setSelectedSubCat(val.subs[0]);
    } else {
      setSelectedSubCat({
        categoryID: 0,
        subCategoryID: 0,
        subCategoryName: "All"
      });
    }
    setShowCat(false);
  }

  const handleCloseSubCat = (val) => {
    setSelectedSubCat(val);
    setShowSubCat(false);
  }

  const handleClickOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    submitSearch();
    setOpenFilter(false);
  };

  var counter = 0;

  const handleSearchCityChange = (e) => {
    setSearchCity(e.currentTarget.value);
  }

  const handleSearchInputChange = (e) => {
    setSearchK(e.currentTarget.value);
  }

  const checkSearchType = (k) => {
    return /^\d{5}(-\d{4})?$/.test(k);
  }

  // const handleCitySearch = () => {
  //   setIsLoading(true);
  //   api.getLatLong(searchCity).then(res => {
  //     var d = {
  //       lat: res.results[0].geometry.location.lat,
  //       long: res.results[0].geometry.location.lng
  //     }
  //     api.getAddress(d).then(dt => {
  //       var zip = extractData('zip', dt.results[0]);
  //       setSearchedKey(searchCity);
  //       var searchObj = {};
  //       var type = searchType;
  //       setShowAll(false);
  //       //If Searchtype is Promotion
  //       if (type == "P") {
  //         setMerchantList([]);
  //         searchObj = {
  //           SourceZipCode: zip,
  //           MaxDistance: radius,
  //           CategoryID: selectedCat.categoryID,
  //           SubCategoryID: selectedSubCat.subCategoryID,
  //         }
  //         api.searchPromotion({
  //           data: searchObj,
  //           idToken: null
  //         }).then(res => {
  //           console.log(res.data);
  //           setPromotionList(res.data);
  //           setIsLoading(false);
  //         });
  //       } else {
  //         setPromotionList([]);
  //         searchObj = {
  //           SourceZipCode: zip,
  //           MaxDistance: radius,
  //           CategoryID: selectedCat.categoryID,
  //           SubCategoryID: selectedSubCat.subCategoryID,
  //           MerchantName: ''
  //         }

  //         api.searchMerchant(searchObj).then(res => {
  //           if (!showOpenOnly) {
  //             setMerchantList(res.data);
  //           } else {
  //             var filterArr = [];
  //             res.data.forEach(m => {
  //               if ((getBusinessStatus(m)).indexOf('closed') == -1) {
  //                 filterArr.push(m);
  //               }
  //             });
  //             setMerchantList(filterArr);
  //           }
  //           setIsLoading(false);
  //         })
  //       }
  //     })
  //   });
  // }

  const submitSearch = async (target = null) => {
    var type;
    var isZip;
    var isBoth;
    var zipToUse = null;
    var searchObj;
    var searchedKey;

    //Use user zip if location was granted unless user enter a location
    if (isLocationGranted) {
      zipToUse = userZip;
      searchedKey = userZip;
    }
    // Check If user entered a zipcode
    if (searchCity) {
      searchedKey = searchCity;
      isZip = checkSearchType(searchCity);
      if (isZip) {
        zipToUse = searchCity;
      } else {
        zipToUse = await api.getLatLong(searchCity).then(async res => {
          var d = {
            lat: res.results[0].geometry.location.lat,
            long: res.results[0].geometry.location.lng
          }
          return await api.getAddress(d).then(dt => {
            return extractData('zip', dt.results[0]);
          })
        })
      }
    }

    if(!zipToUse){
      openSnackBar('Please Enter a search Key');
      setIsLoading(false);
      return;
    }

    if (target) {
      type = target;
    } else {
      type = searchType;
    }

    //Check If user entered both location and merchant name
    if (searchK && searchCity) {
      isBoth = true;
    }

    if(searchK){
      searchedKey = searchedKey + ", " + searchK
    }
    
    setSearchedKey(searchedKey);
    //If Searchtype is Promotion
    if (type == 'P') {
      searchObj = {
        SourceZipCode: zipToUse,
        MaxDistance: radius,
        CategoryID: selectedCat.categoryID,
        SubCategoryID: selectedSubCat.subCategoryID,
      }
      api.searchPromotion({
        data: searchObj,
        idToken: null
      }).then(res => {
        console.log(res.data);
        setPromotionList(res.data);
        setIsLoading(false);
      });
    } else {
      searchObj = {
        SourceZipCode: zipToUse,
        MaxDistance: radius,
        CategoryID: selectedCat.categoryID,
        SubCategoryID: selectedSubCat.subCategoryID,
        MerchantName: searchK ? searchK : ''
      }

      api.searchMerchant(searchObj).then(res => {
        // setMerchantList(res.data);
        if (!showOpenOnly) {
          setMerchantList(res.data);
        } else {
          var filterArr = [];
          res.data.forEach(m => {
            if ((getBusinessStatus(m)).indexOf('closed') == -1) {
              filterArr.push(m);
            }
          });
          setMerchantList(filterArr);
        }
        setIsLoading(false);
      })
    }
  }

  // const handleSearch = (newType = null) => {
  //   setIsLoading(true);
  //   if (searchCity && !searchK) {
  //     handleCitySearch();
  //     return;
  //   }
  //   setSearchedKey(searchK);
  //   var searchObj = {};
  //   var type;
  //   if (newType) {
  //     type = newType;
  //   } else {
  //     type = searchType;
  //   }
  //   setShowAll(false);
  //   //If Searchtype is Promotion
  //   if (type == "P") {
  //     setMerchantList([]);
  //     if (!searchK) {
  //       openSnackBar('Please Enter a search Key');
  //       setIsLoading(false);
  //       return;
  //     }
  //     //If user location is On with promo search
  //     if (isLocationGranted) {
  //       var isZip = checkSearchType(searchK);
  //       //If user enter a zip Code with promo search
  //       if (isZip) {
  //         searchObj = {
  //           SourceZipCode: searchK,
  //           MaxDistance: radius,
  //           CategoryID: selectedCat.categoryID,
  //           SubCategoryID: selectedSubCat.subCategoryID,
  //         }
  //         api.searchPromotion({
  //           data: searchObj,
  //           idToken: null
  //         }).then(res => {
  //           console.log(res.data);
  //           setPromotionList(res.data);
  //           setIsLoading(false);
  //         });
  //       } else { //If user enter a merchant name with promo search with granted location
  //         searchObj = {
  //           SourceZipCode: userZip,
  //           MaxDistance: radius,
  //           CategoryID: selectedCat.categoryID,
  //           SubCategoryID: selectedSubCat.subCategoryID,
  //           MerchantName: searchK
  //         }

  //         api.searchPromotion({
  //           data: searchObj,
  //           idToken: null
  //         }).then(res => {
  //           console.log(res.data);
  //           setPromotionList(res.data);
  //           setIsLoading(false);
  //         });
  //       }
  //     } else {  //If no location granted
  //       var isZip = checkSearchType(searchK);
  //       //If user enter a zip Code with no location granted
  //       if (isZip) {
  //         searchObj = {
  //           SourceZipCode: searchK,
  //           MaxDistance: radius,
  //           CategoryID: selectedCat.categoryID,
  //           SubCategoryID: selectedSubCat.subCategoryID,
  //         }
  //         api.searchPromotion({
  //           data: searchObj,
  //           idToken: null
  //         }).then(res => {
  //           console.log(res.data);
  //           setPromotionList(res.data);
  //           setIsLoading(false);
  //         });
  //       } else {
  //         openSnackBar('Please enter a valid Zip code');
  //         setIsLoading(false);
  //       }
  //     }
  //   } else { // If search type is Merchant
  //     setPromotionList([]);
  //     var isZip = checkSearchType(searchK);
  //     if (!searchK) {
  //       openSnackBar('Please Enter a search Key');
  //       setIsLoading(false);
  //       return;
  //     }
  //     //If user location is On
  //     if (isLocationGranted) {
  //       //If user enter zip code for merchant search
  //       if (isZip) {
  //         searchObj = {
  //           SourceZipCode: searchK,
  //           MaxDistance: radius,
  //           CategoryID: selectedCat.categoryID,
  //           SubCategoryID: selectedSubCat.subCategoryID,
  //           MerchantName: ''
  //         }

  //         api.searchMerchant(searchObj).then(res => {
  //           // setMerchantList(res.data);
  //           if (!showOpenOnly) {
  //             setMerchantList(res.data);
  //           } else {
  //             var filterArr = [];
  //             res.data.forEach(m => {
  //               if ((getBusinessStatus(m)).indexOf('closed') == -1) {
  //                 filterArr.push(m);
  //               }
  //             });
  //             setMerchantList(filterArr);
  //           }
  //           setIsLoading(false);
  //         })

  //       } else {  //If user enter Merchant name for merchant search with granted location
  //         searchObj = {
  //           SourceZipCode: userZip,
  //           MaxDistance: radius,
  //           CategoryID: selectedCat.categoryID,
  //           SubCategoryID: selectedSubCat.subCategoryID,
  //           MerchantName: searchK
  //         }

  //         api.searchMerchant(searchObj).then(res => {
  //           // setMerchantList(res.data);
  //           if (!showOpenOnly) {
  //             setMerchantList(res.data);
  //           } else {
  //             var filterArr = [];
  //             res.data.forEach(m => {
  //               if ((getBusinessStatus(m)).indexOf('closed') == -1) {
  //                 filterArr.push(m);
  //               }
  //             });
  //             setMerchantList(filterArr);
  //           }
  //           setIsLoading(false);
  //         })
  //       }
  //     } else { //If location was declined with merchant search
  //       //If user enter zip code for merchant search
  //       if (isZip) {
  //         searchObj = {
  //           SourceZipCode: searchK,
  //           MaxDistance: radius,
  //           CategoryID: selectedCat.categoryID,
  //           SubCategoryID: selectedSubCat.subCategoryID,
  //           MerchantName: ''
  //         }


  //         api.searchMerchant(searchObj).then(res => {
  //           // setMerchantList(res.data);
  //           if (!showOpenOnly) {
  //             setMerchantList(res.data);
  //           } else {
  //             var filterArr = [];
  //             res.data.forEach(m => {
  //               if ((getBusinessStatus(m)).indexOf('closed') == -1) {
  //                 filterArr.push(m);
  //               }
  //             });
  //             setMerchantList(filterArr);
  //           }
  //           setIsLoading(false);
  //         });

  //       } else {  //If user enter Merchant name for merchant search with declined location
  //         openSnackBar('Please enter a valid Zip code');
  //         setIsLoading(false);
  //       }
  //     }
  //   }
  //   // props.setSearchObj(searchObj);
  // }

  const shrinkDescription = (span) => {
    if (span) {
      // var spanArr = span.split(" ");
      // console.log(spanArr);
      // if (spanArr.length > 5) {
      //   return "Click to read more!"
      // var res = '';
      // spanArr.forEach(element => {
      //   res += " " + element;
      // });
      // return res + "...";
      // } else {
      return span;
      // }
    } else {
      return "Click to read more!"
    }
  }

  const handleLocationDecline = () => {
    props.setUserZip('08601');
    setUserZip('08601');
    setLocationPermission(false);
  }

  const extractData = (type, googleDt) => {
    for (var i = 0; i < googleDt.address_components.length; i++) {
      if (type == 'street_address') {
        var r = null;
        for (var j = 0; j < googleDt.address_components[i].types.length; j++) {
          if (googleDt.address_components[i].types[j].indexOf('street_number') > -1 || googleDt.address_components[i].types[j].indexOf('route') > -1) {
            r += googleDt.address_components[i].long_name + ' ';
          }
        }
        return r;
      }
      if (type == 'town') {
        for (var j = 0; j < googleDt.address_components[i].types.length; j++) {
          if (googleDt.address_components[i].types[j].indexOf('locality') > -1) {
            return googleDt.address_components[i].long_name;
          }
        }
      }
      if (type == 'county') {
        for (var j = 0; j < googleDt.address_components[i].types.length; j++) {
          if (googleDt.address_components[i].types[j].indexOf('administrative_area_level_2') > -1) {
            return googleDt.address_components[i].long_name;
          }
        }
      }
      if (type == 'state') {
        for (var j = 0; j < googleDt.address_components[i].types.length; j++) {
          if (googleDt.address_components[i].types[j].indexOf('administrative_area_level_1') > -1) {
            return googleDt.address_components[i].long_name;
          }
        }
      }
      if (type == 'country') {
        for (var j = 0; j < googleDt.address_components[i].types.length; j++) {
          if (googleDt.address_components[i].types[j].indexOf('country') > -1) {
            return googleDt.address_components[i].long_name;
          }
        }
      }
      if (type == 'zip') {
        for (var j = 0; j < googleDt.address_components[i].types.length; j++) {
          if (googleDt.address_components[i].types[j].indexOf('postal_code') > -1) {
            return googleDt.address_components[i].long_name;
          }
        }
      }
      if (type == 'full_address') {
        return googleDt.formatted_address;
      }
    }
  }

  function getPosition(position) {
    api.getAddress({ lat: position.coords.latitude, long: position.coords.longitude }).then(res => {
      var zip = extractData('zip', res.results[0]);
      var isZip = checkSearchType(zip);
      if (isZip) {
        setLocationPermission(true);
        props.setUserZip(zip);
        setUserZip(zip);
        // setIsLoading(false);
        // console.log("here....0");
        if (!props.searchObj) {
          // console.log("here....1");
          handleInitSearch(zip);
          setisInitWithObj(1);
        }
      } else {
        props.setUserZip('08601');
        setUserZip('08601');
        setLocationPermission(false);
        // setIsLoading(false);
        if (!props.searchObj) {
          handleInitSearch('08601');
          setisInitWithObj(1);
        }
        openSnackBar("An unexpected error occur while trying to detect your location. You can still search by entering your zip code!")
      }
    });
  }

  const handleInitSearch = (zip) => {
    var obj = {
      SourceZipCode: zip,
      MaxDistance: 5,
      CategoryID: 0,
      SubCategoryID: 0
    };

    api.searchPromotion({
      data: obj,
      idToken: null
    }).then(res => {
      var o = [];
      if (res.data) {
        res.data.forEach((d, i) => {
          if (i < 100) {
            o.push(d);
          }
        });
      }
      console.log(res.data);
      console.log(o);
      setPromotionList(o);
      setSearchType('P');
      setSearchedKey(zip);
      setSearchCity(zip);
      setIsLoading(false);
    });
  }

  const preparCategoryDisplay = (dt, CategoryID, SubCategoryID) => {
    var formattedCat = [];
    formattedCat.push({
      categoryID: 0,
      categoryName: 'All',
      subs: false
    })
    for (var i = 0; i < dt.categoryModelList.length; i++) {
      var cat = dt.categoryModelList[i];
      var subs = [{
        categoryID: 0,
        subCategoryID: 0,
        subCategoryName: "All " + cat.categoryName
      }];
      for (var j = 0; j < dt.subCategoryModelList.length; j++) {
        var subC = dt.subCategoryModelList[j];
        if (cat.categoryID == subC.categoryID) {
          subs.push(subC);
        }
      }
      formattedCat.push({
        categoryID: cat.categoryID,
        categoryName: cat.categoryName,
        subs: subs
      })
    }
    formattedCat.forEach(cat => {
      if (CategoryID) {
        if (cat.categoryID == CategoryID) {
          setSelectedCat(cat);
          if (SubCategoryID && cat.subs.length > 0) {
            cat.subs.forEach(subs => {
              if (subs.subCategoryID == SubCategoryID) {
                setSelectedSubCat(subs);
              }
            })
          }
        }
      }
    });
    setCategoryList(formattedCat);
    setShowFilterBtn(true);
  }

  const copy = (obj1) => {
    var obj3 = {};
    for (var attrname in obj1) { obj3[attrname] = obj1[attrname]; }
    return obj3;
  }

  const preparCat = (CategoryID, SubCategoryID) => {
    api.getCategories().then(res => {
      preparCategoryDisplay(res.data, CategoryID || null, SubCategoryID || null);
    });
  }

  //ComponentDidMount
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("List View");
    setIsLoading(true);

    if (props.searchObj) {
      setisInitWithObj(0);
      var obj = copy(props.searchObj);
      setSearchCity(obj.SourceZipCode);
      setRadius(obj.MaxDistance);
      
      console.log("here: ", isLoading);
      console.log('obj: ', obj);
      if (obj.MerchantName == undefined) {
        setSearchK('');
        if(obj.SourceZipCode){
          setSearchedKey(obj.SourceZipCode + ', ' + obj.MerchantName);
        } else {
          setSearchedKey(obj.MerchantName);
        }
        
        api.searchPromotion({
          data: obj,
          idToken: null
        }).then(res => {
          console.log(res.data);
          setPromotionList(res.data);
          setSearchType('P');
          setIsLoading(false);
        });
      } else {
        setIsLoading(true);
        preparCat();
        setRadius(obj.MaxDistance);
        setSearchK(obj.MerchantName);
        setSearchCity(obj.SourceZipCode);
        
        api.searchMerchant(obj).then(res => {
          // setMerchantList(res.data);
          if (!showOpenOnly) {
            setMerchantList(res.data);
          } else {
            var filterArr = [];
            res.data.forEach(m => {
              if ((getBusinessStatus(m)).indexOf('closed') == -1) {
                filterArr.push(m);
              }
            });
            setMerchantList(filterArr);
          }
          setSearchType('M');
          setIsLoading(false);
        })
      }
    }
    if (props.routeParams.merchantID != 'all') {
      setShowAll(false);
      setIsLoading(true);
      preparCat();
      if (props.routeParams.promotionID == '0') {
        api.getMerchantInfos(props.routeParams.merchantID).then(res => {
          setSelectedMerchant(res.data);
          setOpenMerchantDetail(true);
          api.increaseMerchantCount(res.data.merchantID).then(res => {
            console.log("Increase res: ", res);
          });
          setIsLoading(false);
        }).catch(err => {
          setIsLoading(false);
          console.log('getMerchantInfosErr: ', err);
        })
      } else {
        var sPromotion = null;
        api.getMerchantInfos(props.routeParams.merchantID).then(r => {
          setSelectedMerchant(r.data);
          if (r.data.status == 1) {
            api.getMerchantPromotion({
              merchantID: props.routeParams.merchantID,
              idToken: null
            }).then(res => {
              setShowAll(false);
              setSearchType("P");
              res.data.forEach((p, i) => {
                p.merchantName = r.data.merchantName;
                p.address1 = r.data.address1;
                p.city = r.data.city;
                p.state = r.data.state;
                p.zip = r.data.zip;
                p.contactName = r.data.contactName;
                p.logoFile = r.data.logoFile;
                p.merchantEmail = r.data.merchantEmail;
                p.phone = r.data.phone;
                p.website = r.data.website;
                if (p.merchantPromotionID.toString() == props.routeParams.promotionID) {
                  sPromotion = p;
                }
              });
              console.log(res.data);
              setPromotionList(res.data);
              setOpenMerchantDetail(false);
              if (sPromotion) {
                setSelectedPromotion(sPromotion);
                setOpenPromoDetail(true);
                api.increasePromotionCount(sPromotion.merchantPromotionID).then(res => {
                  console.log("promoDetailcount-result: ", res);
                });
              }
              setIsLoading(false);
            }).catch(err => {
              setIsLoading(false);
              console.log('getMerchantPromotionErr: ', err)
            });
          } else {
            setIsLoading(false);
            setOpenMerchantDetail(true);
            api.increaseMerchantCount(r.data.merchantID).then(res => {
              console.log("Increase res: ", res);
            });
          }
        })
      }
    }


    if (navigator.geolocation) {
      preparCat();
      navigator.geolocation.getCurrentPosition(getPosition, handleLocationDecline);
    } else {
      handleLocationDecline();
      preparCat();
    }
  }, []);

  const checkCounter = () => {
    if (counter > 1) {
      setShowAll(true);
      counter = 0;
    }
  }

  const getBusinessStatus = (dt) => {
    var openH;
    var closeH;
    var isSet = true;
    // console.log("openCloseData--: ", dt);
    if (todayDay == 'Monday') {
      if (dt.monOpen) {
        if (dt.monOpen.indexOf('AM') > -1 || dt.monOpen.indexOf('PM') > -1) {
          openH = dt.monOpen;
          closeH = dt.monClose;
        } else {
          openH = 'Closed';
          closeH = 'Closed';
        }
      }
    }
    if (todayDay == 'Tuesday') {
      if (dt.tueOpen) {
        if (dt.tueOpen.indexOf('AM') > -1 || dt.tueOpen.indexOf('PM') > -1) {
          openH = dt.tueOpen;
          closeH = dt.tueClose;
        } else {
          openH = 'Closed';
          closeH = 'Closed';
        }
      }
    }
    if (todayDay == 'Wednesday') {
      if (dt.wedOpen) {
        if (dt.wedOpen.indexOf('AM') > -1 || dt.wedOpen.indexOf('PM') > -1) {
          openH = dt.wedOpen;
          closeH = dt.wedClose;
        } else {
          openH = 'Closed';
          closeH = 'Closed';
        }
      }
    }
    if (todayDay == 'Thursday') {
      if (dt.thuOpen) {
        if (dt.thuOpen.indexOf('AM') > -1 || dt.thuOpen.indexOf('PM') > -1) {
          openH = dt.thuOpen;
          closeH = dt.thuClose;
        } else {
          openH = 'Closed';
          closeH = 'Closed';
        }
      }
    }
    if (todayDay == 'Friday') {
      if (dt.friOpen) {
        if (dt.friOpen.indexOf('AM') > -1 || dt.friOpen.indexOf('PM') > -1) {
          openH = dt.friOpen;
          closeH = dt.friClose;
        } else {
          openH = 'Closed';
          closeH = 'Closed';
        }
      }
    }
    if (todayDay == 'Saturday') {
      if (dt.satOpen) {
        if (dt.satOpen.indexOf('AM') > -1 || dt.satOpen.indexOf('PM') > -1) {
          openH = dt.satOpen;
          closeH = dt.satClose;
        } else {
          openH = 'Closed';
          closeH = 'Closed';
        }
      }
    }
    if (todayDay == 'Sunday') {
      if (dt.sunOpen) {
        if (dt.sunOpen.indexOf('AM') > -1 || dt.sunOpen.indexOf('PM') > -1) {
          openH = dt.sunOpen;
          closeH = dt.sunClose.split('-')[0] ;
        } else {
          openH = 'Closed';
          closeH = 'Closed';
        }
      }
    }

    var format = 'hh:mm:ss'

    if (openH && closeH) {

      if (openH == closeH && openH != "Closed") {
        return "(Open Now)"
      }
      if (openH == closeH && openH == "Closed") {
        return "(Outside of Business Hours)"
      }
      if (openH != closeH) {
        openH = moment(moment(openH, ["h:mm A"]).format("HH:mm"), format);
        closeH = moment(moment(closeH, ["h:mm A"]).format("HH:mm"), format);
        if (moment().isBetween(openH, closeH)) {
          return "(Open Now)"
        } else {
          return "(Outside of Business Hours)"
        }
      }
    } else {
      return ""
    }

  }

  // const formatMapQ = (address, city, state, zip) => {
  //   let formatAddress = address.replace(/ /g, '+');
  //   let formatCity = city.replace(/ /g, '+');
  //   let formatState = state.replace(/ /g, '+');
  //   let formatZip = zip.replace(/ /g, '+');

  //   let url = `${formatAddress}+${formatCity}+${formatState}+${formatZip}`;
  //   return url;
  // }

  const launchMap = (address) => {
    if (address != null && address != '') {

      var Stype = getMobileOperatingSystem();

      if (Stype == "iOS") {
        var link = "http://maps.apple.com/?q=" + address;
        window.open(link, '_blank');
      } else {
        var link = "https://maps.google.com/?q=" + address;
        window.open(link, '_blank');
      }
    }
  }

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  const formatWeb = (site) => {
    //if preceded by // leave as is else add
    if (site.includes('//')) {
      return site;
    } else {
      return `//${site}`;
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      submitSearch();
    }
  }

  return (
    <span>
      <Header
        color="white"
        routes={dashboardRoutes}
        rightLinks={<HeaderRightLinks />}
        leftLinks={<HeaderLeftLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <div style={{ width: '100%', spanAlign: 'center', padding: '15px', minHeight: 'calc(100vh - 100px)', marginTop: '100px' }}>
        <img src={require('assets/img/FF_map_only.png')}
          style={{ position: 'fixed', zIndex: '-10', width: '100%', bottom: '0', opacity: '5%' }}
        />
        <span id='searchSection' style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <span style={{}}>
              <Typography className={classes.showM} variant="body2" color="textSecondary" component="p">
                Search By...
                </Typography>
              <span style={{ display: 'flex', flexDirection: 'row' }}>

                <span style={{ display: 'flex', flexDirection: 'row', fontSize: '14px', alignItems: 'flex-end', justifyContent: 'center', marginLeft: '15px', color: 'rgb(37, 35, 38, .5)' }}>
                  <Radio
                    checked={searchType === 'M'}
                    onChange={() => { setSearchType('M'); submitSearch('M'); }}
                    value="M"
                    color="primary"
                    name="merchant-search-type"
                    inputProps={{ 'aria-label': 'A' }}
                    style={{ padding: '1px 7px' }}
                  />
                  <span onClick={() => { setSearchType('M'); submitSearch('M'); }} style={{ color: 'black', cursor: 'pointer' }}><span className={classes.hideM}>Search By </span>Business</span>
                </span>
                <span style={{ display: 'flex', flexDirection: 'row', fontSize: '14px', alignItems: 'flex-end', justifyContent: 'center', color: 'rgb(37, 35, 38, .5)' }}>
                  <Radio
                    checked={searchType === 'P'}
                    onChange={() => { setSearchType('P'); submitSearch('P'); }}
                    value="P"
                    color="primary"
                    name="promotion-search-type"
                    inputProps={{ 'aria-label': 'A' }}
                    style={{ padding: '1px 7px' }}
                  />
                  <span onClick={() => { setSearchType('P'); submitSearch('P'); }} style={{ color: 'black', cursor: 'pointer' }}><span className={classes.hideM}>Search By </span>Promotions</span>
                </span>
              </span>
            </span>
            <span className={classes.rowSmallCol} style={{ marginTop: '20px', flexDirection: 'column', display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'flex-end' }}>
              <span style={{ justifyContent: 'flex-end', paddingRight: '15px', cursor: 'pointer', display: 'flex', alignItems: 'center', color: 'rgb(37, 35, 38, .5)' }} onClick={handleClickOpenFilter}>
                <FilterListIcon color="primary" style={{ verticalAlign: 'middle' }} /> <span>Filter</span>
              </span>
              <span className={classes.rowSmallCol} style={{ display: 'flex', alignItems: 'space-between', width: '100%' }}>
                <span style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                  <TextField label={"City, State/Zip"}
                    value={searchCity} onChange={(e) => handleSearchCityChange(e)} //onFocus={() => setSearchK('')} onKeyPress={handleKeyPress}
                    style={{ padding: '0 3px', width: '50%' }} />
                  <span style={{ margin: '0 25px', paddingTop: '15px' }} className={classes.hideM}>And/Or</span>
                  <TextField label={isLocationGranted ? "Business name" : "Search by Zip Code"}
                    value={searchK} onChange={(e) => handleSearchInputChange(e)} //onFocus={() => setSearchCity('')} onKeyPress={handleKeyPress}
                    style={{ padding: '0 3px', width: '50%' }} />
                </span>
                <Button color="warning" onClick={() => submitSearch()} style={{ fontWeight: 'bold', color: '#252326', height: '40px', margin: '20px 0 0 0' }}><SearchIcon className={classes.inputIconsColor} />
                  <span className={classes.hideSmall}>Search</span>
                </Button>
              </span>
            </span>
          </span>
        </span>
        {!isLoading ? (<span>
          {(promotionList.length == 0 && merchantList.length == 0) ? (
            <span className='resultsSection' style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
              <h2>No results.</h2>
              <span>We couldn't find any results for "{searchedKey}"</span>
            </span>
          ) : null}
          <span className='resultsSection' style={{ display: 'flex', flexDirection: 'column', margin: ' auto' }}>
            <span>
              {/* Search engin */}
            </span>
            {(searchType == 'P' || showAll) ? (
              <span>
                {promotionList.length > 0 ? (
                  <span className="search-result">
                    {promotionList.map((promo, i) => {
                      var desc;
                      if (promo.description2 && promo.description3) {
                        desc = promo.description2 + " " + promo.description3;
                      } else {
                        desc = '';
                      }
                      return (
                        <span onClick={() => handleOpenPromo(promo)} style={{ padding: '7px' }} key={'promo-' + i}>
                          <Card className={cardClasses.root} style={{ minHeight: 'fit-content' }}>
                            <CardActionArea>
                              <CardMedia
                                className={cardClasses.media}
                                image={promo.coupon || (promo.logoFile || require("assets/img/FindingFaber-Logo/FFapp_icon only.png"))}
                                title={promo.merchantName}
                              />
                              <CardContent>
                                <Typography gutterBottom className='ellipses3' variant="body1" component="span" style={{ height: '4.5rem' }}>
                                  {promo.description1}
                                </Typography>
                                <Typography className='ellipses3' variant="body2" color="textSecondary" component="p" style={{ height: '4rem' }}>
                                  {shrinkDescription(desc)}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                            {/* <CardActions>
                                  <Button size="sm" color="primary">
                                  span
                                  </Button>
                              </CardActions> */}
                          </Card>
                        </span>
                      )
                    })}
                  </span>
                ) : null}
              </span>
            ) : null}
            {(searchType == 'M' || showAll) ? (
              <span className="merch-search-result">
                {merchantList.map((merch, j) => {
                  return (
                    <span style={{ padding: '16px' }} key={'merchCard-' + j} onClick={() => handleOpenMerchant(merch)}>
                      <Card className={cardClasses.merchantRoot} style={{}}>
                        <CardActionArea>
                          <span id='cardWrapper' style={{ padding: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '250px' }}>
                            <span>
                              <Typography className='ellipses2' gutterBottom variant="body1" component="span" style={{ margin: '0 auto 8px', textAlign: 'center' }}>
                                {merch.merchantName}
                              </Typography>
                              <span className={classes.rowSmallCol} style={{ display: 'flex', alignItems: 'center' }}>
                                <span id='mediaWrapper' style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
                                  <CardMedia
                                    className={cardClasses.media}
                                    image={merch.logoFile || require("assets/img/FindingFaber-Logo/FFapp_icon only.png")}
                                    title={merch.merchantName}
                                    style={{ width: '100px', maxHeight: '100px', borderRadius: '10px', marginBottom: '8px' }}
                                  />
                                  {/* <Button style={{marginTop: '15px'}} size="sm" color="primary">
                                      span
                                    </Button> */}
                                </span>
                                <CardContent style={{ padding: '8px 16px' }}>

                                  <Typography variant="body2" color="textSecondary" component="p" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <span className='ellipsesWrapper' style={{ display: 'flex', flexDirection: 'column' }}>
                                      {(merch.address1 && merch.city && merch.state && merch.zip) ? (
                                        <span style={{ margin: '5px 0px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                          <MapIcon style={{ marginRight: '10px', verticalAlign: 'middle', fontSize: '15px', color: '#0476B5' }} />
                                          <span className='ellipses2'>{merch.address1} {merch.city} {merch.state} {merch.zip}</span>
                                        </span>
                                      ) : null}
                                      {merch.phone ? (
                                        <span className='ellipsesWrapper' style={{ margin: '5px 0px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                          <PhoneIcon style={{ marginRight: '10px', verticalAlign: 'middle', fontSize: '15px', color: '#0476B5' }} />
                                          <span className='ellipses1'>{merch.phone}</span>
                                        </span>
                                      ) : null}
                                      {merch.merchantEmail ? (
                                        <span className='ellipsesWrapper' style={{ margin: '5px 0px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                          <EmailIcon style={{ marginRight: '10px', verticalAlign: 'middle', fontSize: '15px', color: '#0476B5' }} />
                                          <span className='ellipses1'>{merch.merchantEmail}</span>
                                        </span>
                                      ) : null}
                                      {merch.website ? (
                                        <span className='ellipsesWrapper' style={{ margin: '5px 0px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                          <LanguageIcon style={{ marginRight: '10px', verticalAlign: 'middle', fontSize: '15px', color: '#0476B5' }} />
                                          <span className='ellipses1'>{merch.website}</span>
                                        </span>
                                      ) : null}
                                    </span>
                                  </Typography>
                                </CardContent>
                              </span>
                            </span>
                            <Typography variant="body2" color="textSecondary" component="p" style={{ textAlign: 'center' }}>
                              View Business
                                </Typography>
                          </span>
                        </CardActionArea>
                        {/* <CardActions>
                              </CardActions> */}
                      </Card>
                    </span>
                  )
                })}
              </span>
            ) : null}
          </span>
        </span>) : (<span style={{ width: '100%', height: '100%', textAlign: 'center', display: 'flex', justifyContent: 'space-around' }}>
          <CircularProgress color="primary" size={50} />
        </span>)}
      </div>

      {/* Merchant Detail */}
      {openMerchantDetail ? (
        <Dialog
          className={classes.resDia}
          open={openMerchantDetail}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogContent>
            <span style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <div className={classes.rowSmallCol} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{width: '100px'}}>
                  <img src={selectedMerchant.logoFile || require("assets/img/FindingFaber-Logo/FFapp_icon only.png")} style={{ borderRadius: '10px', maxHeight: '100%', maxWidth: '100%', marginRight: '10px' }} alt='merchant_logo' />
                </div>
                <h2>{selectedMerchant.merchantName}</h2>
              </div>
              {selectedMerchant.status == 1 ? (
                <span style={{ textAlign: 'center', marginTop: '5px' }}>
                  {(getBusinessStatus(selectedMerchant)).indexOf('Outside of Business Hours') > -1 ? (
                    <span style={{ margin: '0 auto 10px', color: 'red' }}>{getBusinessStatus(selectedMerchant)}</span>
                  ) : (
                      <span style={{ margin: '0 auto 10px', color: 'green' }}>{getBusinessStatus(selectedMerchant)}</span>
                    )}
                </span>
              ) : (
                  <span style={{ margin: '0 auto 10px', color: 'red' }}>This Business is Inactive at the Moment</span>
                )}
              {selectedMerchant.status == 1 ? (
                <span>
                  <div style={{ padding: '7px', marginTop: '15px', borderTop: '1px solid #252326' }}>
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                      {(selectedMerchant.address1 && selectedMerchant.city && selectedMerchant.state && selectedMerchant.zip) ? (
                        <span style={{ margin: '5px 0px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <MapIcon style={{ marginRight: '10px', verticalAlign: 'middle', fontSize: '15px', color: '#0476B5' }} />
                          <span style={{ cursor: 'pointer', color: '#0476B5' }} onClick={() => launchMap(selectedMerchant.address1 + ", " + selectedMerchant.city + ", " + selectedMerchant.state + " " + selectedMerchant.zip)}>{selectedMerchant.address1} {selectedMerchant.city} {selectedMerchant.state} {selectedMerchant.zip}</span>
                        </span>
                      ) : null}
                      {selectedMerchant.phone ? (
                        <span style={{ margin: '5px 0px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <PhoneIcon style={{ marginRight: '10px', verticalAlign: 'middle', fontSize: '15px', color: '#0476B5' }} />
                          <a href={`tel:+1-${selectedMerchant.phone}`}>{selectedMerchant.phone}</a>
                        </span>
                      ) : null}
                      {selectedMerchant.merchantEmail ? (
                        <span style={{ margin: '5px 0px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <EmailIcon style={{ marginRight: '10px', verticalAlign: 'middle', fontSize: '15px', color: '#0476B5' }} />
                          <a href={`mailto:${selectedMerchant.merchantEmail}?subject=About your Business on FindingFaber:`}>{selectedMerchant.merchantEmail}</a>
                        </span>
                      ) : null}
                      {selectedMerchant.website ? (
                        <span style={{ margin: '5px 0px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <LanguageIcon style={{ marginRight: '10px', verticalAlign: 'middle', fontSize: '15px', color: '#0476B5' }} />
                          <a href={formatWeb(selectedMerchant.website)} target='_blank'>{selectedMerchant.website}</a>
                        </span>
                      ) : null}
                    </span>
                  </div>
                  {(selectedMerchant.monOpen || selectedMerchant.tueOpen || selectedMerchant.wedOpen || selectedMerchant.thuOpen || selectedMerchant.friOpen || selectedMerchant.satOpen || selectedMerchant.sunOpen) ? (
                    <div style={{ padding: '7px', borderTop: '1px solid #252326' }}>
                      <span style={{ display: 'flex', flexDirection: 'column' }}>
                        {selectedMerchant.sunClose.split('-')[1] && (
                          <span style={{ textAlign: 'center'}}>
                            <span style={{fontWeight: '700'}}>Note:</span> {selectedMerchant.sunClose.split('-').slice(1, selectedMerchant.sunClose.split('-').length).join('-')}
                          </span>
                        )}
                        <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <span><span style={todayDay == 'Monday' ? { fontWeight: 'bold' } : null}>Monday</span></span>
                          <span><span style={todayDay == 'Monday' ? { fontWeight: 'bold' } : null}>{selectedMerchant.monOpen || ''} - {selectedMerchant.monClose || ''}</span></span>
                        </span>
                        <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <span><span style={todayDay == 'Tuesday' ? { fontWeight: 'bold' } : null}>Tuesday</span></span>
                          <span><span style={todayDay == 'Tuesday' ? { fontWeight: 'bold' } : null}>{selectedMerchant.tueOpen || ''} - {selectedMerchant.tueClose || ''}</span></span>
                        </span>
                        <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <span><span style={todayDay == 'Wednesday' ? { fontWeight: 'bold' } : null}>Wednesday</span></span>
                          <span><span style={todayDay == 'Wednesday' ? { fontWeight: 'bold' } : null}>{selectedMerchant.wedOpen || ''} - {selectedMerchant.wedClose || ''}</span></span>
                        </span>
                        <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <span><span style={todayDay == 'Thursday' ? { fontWeight: 'bold' } : null}>Thursday</span></span>
                          <span><span style={todayDay == 'Thursday' ? { fontWeight: 'bold' } : null}>{selectedMerchant.thuOpen || ''} - {selectedMerchant.thuClose || ''}</span></span>
                        </span>
                        <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <span><span style={todayDay == 'Friday' ? { fontWeight: 'bold' } : null}>Friday</span></span>
                          <span><span style={todayDay == 'Friday' ? { fontWeight: 'bold' } : null}>{selectedMerchant.friOpen || ''} - {selectedMerchant.friClose || ''}</span></span>
                        </span>
                        <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <span><span style={todayDay == 'Saturday' ? { fontWeight: 'bold' } : null}>Saturday</span></span>
                          <span><span style={todayDay == 'Saturday' ? { fontWeight: 'bold' } : null}>{selectedMerchant.satOpen || ''} - {selectedMerchant.satClose || ''}</span></span>
                        </span>
                        <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <span><span style={todayDay == 'Sunday' ? { fontWeight: 'bold' } : null}>Sunday</span></span>
                          <span><span style={todayDay == 'Sunday' ? { fontWeight: 'bold' } : null}>{selectedMerchant.sunOpen || ''} - {selectedMerchant.sunClose.split('-')[0] || ''}</span></span>
                        </span>
                      </span>
                    </div>) : null}
                  {selectedMerchant.merchantOrganizationList ? (
                    <span>
                      {selectedMerchant.merchantOrganizationList.length > 0 ? (
                        <span>
                          <div style={{ padding: '7px', marginTop: '15px', borderTop: '1px solid #252326' }}>
                            <span>{selectedMerchant.merchantOrganizationList[0].organizationName ? <h4>Supporting:</h4> : null}</span>
                            {selectedMerchant.merchantOrganizationList.map((org, orgIndex) => {
                              return (
                                <span key={"orgName-" + orgIndex} style={{ display: 'flex', flexDirection: 'column' }}>
                                  {org.organizationName ? (
                                    <span style={{ margin: '5px 0px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                      <LocationCityIcon style={{ marginRight: '10px', verticalAlign: 'middle', fontSize: '15px', color: '#0476B5' }} />
                                      <span>{org.organizationName} ({org.city}, {org.state})</span>
                                    </span>) : null}
                                </span>
                              )
                            })}
                          </div>
                        </span>) : null}
                    </span>
                  ) : null}

                  {showNoDeals ? <span style={{ textAlign: 'end', color: 'red', float: 'right', margin: '15px' }}>This merchant does not currently have any deals!</span> : null}
                </span>) : null}
            </span>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseMerchant}>Close</Button>
            {selectedMerchant.status == 1 ? (
              <Button color="primary" onClick={handleViewDeals}>View Deals</Button>
            ) : null}
          </DialogActions>
        </Dialog>
      ) : null}

      {/* Promotion Detail */}
      {openPromoDetail ? (
        <Dialog
          className={classes.resDia}
          open={openPromoDetail}
          onClose={handleClosePromo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ minWidth: '80%' }}>

          {/* <DialogTitle id="alert-dialog-title">
                  
                </DialogTitle> */}
          {/* <DialogContent> */}
          {/* <span style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '7px'}}> */}
          <DialogContent>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '7px', margin: '7px 0px', alignItems: 'center' }}>
              <h4 style={{ margin: '1px', fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'center' }}>{selectedPromotion.description1}</h4>

              <div style={{ display: 'flex' }} >
                <span component={Button} className='secondaryFont'
                  onClick={() => handleOpenMerchant(selectedPromotion)}
                  style={{ fontWeight: 'bold', fontStyle: 'italic', fontSize: '1.5rem', fontFamily: "'EB Garamond', serif", color: '#0476B5', cursor: 'pointer', }}>{selectedPromotion.merchantName}</span>
              </div>

              {selectedPromotion.promotionStart && selectedPromotion.promotionEnd ? (
                <span style={{ margin: '5px 0px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  <DateRangeIcon style={{ marginRight: '10px', verticalAlign: 'middle', fontSize: '15px', color: '#0476B5' }} />
                  <span>{moment(selectedPromotion.promotionStart).format("MMM Do YYYY") + " - " + moment(selectedPromotion.promotionEnd).format("MMM Do YYYY")}</span>
                </span>
              ) : null}

            </div>

            {selectedPromotion.description2 || selectedPromotion.description3 || selectedPromotion.coupon || selectedPromotion.barcode ?
              <div className={classes.rowSmallCol} style={{ display: 'flex', padding: '7px', margin: '7px 0px', justifyContent: 'space-evenly', alignItems: 'center', borderTop: '1px solid #252326' }}>

                {selectedPromotion.description2 || selectedPromotion.description3 ?
                  <div className={classes.sm2} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    <span>{selectedPromotion.description2}</span>
                    <span>{selectedPromotion.description3}</span>
                  </div> : <> </>}


                {selectedPromotion.coupon || selectedPromotion.barcode ?
                  <div className={classes.sm1} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    {selectedPromotion.coupon ? (
                      <div style={{ margin: '5px 0px', width: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={selectedPromotion.coupon} style={{ maxHeight: '100%', maxWidth: '100%', margin: '' }} alt="coupon" />
                      </div>
                    ) : null}
                    {selectedPromotion.barcode ? (
                      <span>
                        Coupon: {selectedPromotion.barcode}
                      </span>
                    ) : null}
                  </div> : <> </>}

              </div> : <> </>}

            <span style={{
              padding: '5px 0',
              // borderTop: '1px solid #252326', 
              width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
            }}>
              <Button style={{ width: '50%', marginRight: '10px' }} color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleShareOpen}>Share</Button>
              <Menu id="share-menu" anchorEl={openSharesOptions} keepMounted open={Boolean(openSharesOptions)} onClose={handleShareClose}>
                <MenuItem>
                  <CopyToClipboard text={'www.findingFaber.com/search/' + selectedPromotion.merchantID + "/" + selectedPromotion.merchantPromotionID}
                    onCopy={() => openSnackBar('Copied!')} >
                    <span><FileCopyIcon color='primary' style={{ fontSize: 30 }} />Copy Link</span>
                  </CopyToClipboard>
                </MenuItem>
                <MenuItem>
                  <EmailShareButton
                    url={'www.findingFaber.com/search/' + selectedPromotion.merchantID + "/" + selectedPromotion.merchantPromotionID}
                    subject={'Check out this local deal on Finding Faber'}
                    body={'This Finding Faber special is being shared by one of your friends. To see similar specials and support the local business community, download the Finding Faber App on the APP store or Google Play.'}
                    separator={' | '}
                  >
                    <EmailIcon style={{ fontSize: 30, verticalAlign: 'middle' }} color="primary" /> Via Email
                        </EmailShareButton>
                </MenuItem>
                <MenuItem>
                  <FacebookShareButton
                    url={'www.findingFaber.com/search/' + selectedPromotion.merchantID + "/" + selectedPromotion.merchantPromotionID}
                    quote={"This Finding Faber special is being shared by one of your friends. To see similar specials and support the local business community, download the Finding Faber App on the APP store or Google Play."}
                  >
                    <img src={require('../../assets/img/facebook.webp')} height='30px' width='30px' alt='-' /> On FaceBook
                        </FacebookShareButton>
                </MenuItem>
                <MenuItem>
                  <TwitterShareButton
                    url={'www.findingFaber.com/search/' + selectedPromotion.merchantID + "/" + selectedPromotion.merchantPromotionID}
                    title={"This Finding Faber special is being shared by one of your friends. To see similar specials and support the local business community, download the Finding Faber App on the APP store or Google Play."}
                    hashtags={["FindingFaber"]}
                    related={["atom_93"]}
                  >
                    <img src={require('../../assets/img/twitter.webp')} height='30px' width='30px' alt='-' /> On Twitter
                        </TwitterShareButton>
                </MenuItem>
              </Menu>
              <Button style={{ width: '50%', marginLeft: '10px' }} onClick={handleClosePromo}>Close</Button>
            </span>
            <div style={{ borderTop: '1px solid #252326', padding: '10px 0', display: 'flex', justifyContent: 'center' }}>
              * brought to you by <span style={{ fontSize: '1.2rem', marginLeft: '5px' }} className='ff-finding'>Finding</span><span style={{ fontSize: '1.5rem' }} className='ff-faber'>Faber</span><span className='ff-tm'>&trade;</span>
            </div>
          </DialogContent>
        </Dialog>
      ) : null}

      {/* Filter Dialog */}
      {openFilter ? (
        <Dialog
          className={classes.resDia}
          open={openFilter}
          onClose={handleCloseFilter}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">

          <DialogTitle id="alert-dialog-title">{"Filters"}</DialogTitle>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">
                  Let Google help apps determine location. This means sending anonymous location data to
                  Google, even when no apps are running.
                </DialogContentText> */}
            <span style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <span style={{ paddingTop: '20px', paddingRight: '15px' }}>
                <span>
                  <Typography className={classes.showM} variant="body2" color="textSecondary" component="p">
                    Search By...
                  </Typography>
                  <span>
                    <Radio
                      checked={searchType === 'P'}
                      onChange={() => setSearchType('P')}
                      value="P"
                      color="primary"
                      name="promotion-search-type"
                      inputProps={{ 'aria-label': 'A' }}
                    />
                    <span onClick={() => setSearchType('P')} style={{ color: 'black', cursor: 'pointer' }}><span className={classes.hideM}>Search By </span>Promotions</span>
                  </span>
                  <span style={{ marginLeft: '15px' }}>
                    <Radio
                      checked={searchType === 'M'}
                      onChange={() => setSearchType('M')}
                      value="M"
                      color="primary"
                      name="merchant-search-type"
                      inputProps={{ 'aria-label': 'A' }}
                    />
                    <span onClick={() => setSearchType('M')} style={{ color: 'black', cursor: 'pointer' }}><span className={classes.hideM}>Search By </span>Business</span>
                  </span>
                </span>
              </span>
              <span style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <Paper style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '7px' }}>
                  <h4>Radius: </h4>
                  <span aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenRadiusSet} style={{ paddingTop: '10px', cursor: 'pointer', color: 'rgb(63, 81, 181)' }}>
                    {radius} miles <ArrowDropDownCircleIcon color="primary" style={{ verticalAlign: 'middle' }} />
                  </span>
                </Paper>
                <Menu id="simple-menu"
                  anchorEl={showRadius}
                  open={Boolean(showRadius)}>
                  <MenuItem onClick={() => handleCloseRadiusSet(5)}>5 miles</MenuItem>
                  <MenuItem onClick={() => handleCloseRadiusSet(10)}>10 miles</MenuItem>
                  <MenuItem onClick={() => handleCloseRadiusSet(15)}>15 miles</MenuItem>
                  <MenuItem onClick={() => handleCloseRadiusSet(20)}>20 miles</MenuItem>
                  <MenuItem onClick={() => handleCloseRadiusSet(25)}>25 miles</MenuItem>
                </Menu>
              </span>
              <span style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <Paper style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '7px' }}>
                  <h4>Category: </h4>
                  <span aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenCat} style={{ paddingTop: '10px', cursor: 'pointer', color: 'rgb(63, 81, 181)' }}>
                    {selectedCat.categoryName} <ArrowDropDownCircleIcon color="primary" style={{ verticalAlign: 'middle' }} />
                  </span>
                </Paper>
                <Menu id="simple-menu"
                  anchorEl={showCat}
                  open={Boolean(showCat)}>
                  {categoryList.map((cat, i) => {
                    return <MenuItem key={'cat-' + i} onClick={() => handleCloseCat(cat)}>{cat.categoryName}</MenuItem>
                  })}
                </Menu>
                {selectedCat.categoryID != 0 ? (
                  <span style={{ width: '100%' }}>
                    <Paper style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '7px' }}>
                      <h4>Sub-Category: </h4>
                      <span aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenSubCat} style={{ paddingTop: '10px', cursor: 'pointer', color: 'rgb(63, 81, 181)' }}>
                        {selectedSubCat.subCategoryName} <ArrowDropDownCircleIcon color="primary" style={{ verticalAlign: 'middle' }} />
                      </span>
                    </Paper>
                    {showFilterBtn ? (
                      <Menu id="simple-menu"
                        anchorEl={showSubCat}
                        open={Boolean(showSubCat)}>
                        {selectedCat.subs.map((sub, i) => {
                          return <MenuItem key={'sub-' + i} onClick={() => handleCloseSubCat(sub)}>{sub.subCategoryName}</MenuItem>
                        })}
                      </Menu>
                    ) : null}
                  </span>
                ) : null}
              </span>
              <span>
                <Switch
                  checked={showOpenOnly}
                  onChange={handleOpenOnly}
                  color="primary"
                  name="showIpenOnly"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> {showOpenOnly ? 'Show Open Businesses Only (ON)' : 'Show Open Businesses Only (OFF)'}
              </span>
            </span>
          </DialogContent>
          <DialogActions>
            <span style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
              <Button style={{ marginLeft: 'auto' }} onClick={handleCloseFilter} color="primary" autoFocus>
                Apply
                  </Button>
            </span>
          </DialogActions>
        </Dialog>
      ) : null}
      {shouldSnackBarOpen ? (
        <Portal>
          <Snackbar
            // anchorOrigin={{ "bottom", "center" }}
            open={shouldSnackBarOpen}
            onClose={handleSnackBarClose}
            message={snackMsg}
            key="key"
          />
        </Portal>
      ) : null}
      <Footer />
    </span>
  )
}
