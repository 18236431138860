/*eslint-disable*/
import React, { useEffect, Component } from "react";
import { Redirect } from 'react-router-dom';
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

// FireBase
import firebase from '../../config/firebase';

import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";



// const useStyles = makeStyles(styles);

class HeaderRightLinks extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      password: "",
      isFirebaseInit: false,
      activeUser: "",
      classes: null,
      isLoading: true,
      isUser: false,
      isLogOut: false
    }
  }


  componentDidMount() {
    if (this.props.user) {
      this.setState({ activeUser: this.props.user })
    }
  }

  logout = () => {
    firebase.logout().then((result) => {
      console.log(result);
      this.props.setUser(null);
      this.setState({ isLogOut: true });
    })
  }


  render() {
    const { classes } = this.props;
    return (
      <List className={classes.list}>
        {this.state.isLogOut ? <Redirect to='/' /> : null}
        {!this.props.user ? (
          <ListItem className={classes.listItem}>
            <Tooltip id="instagram-tooltip"
              title="For Our Business Admins"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}>

              {/* <Button color="transparent"
                className={classes.navLink}
                component={Link}
                to="/login" style={{ color: "white" }}
                > */}
                <Link component={Button} className={classes.navLink} to="/login" style={{ fontWeight: 'bold', backgroundColor: '#FECE00', color: "#252326" }}>
                  Business Owner Log in
                  <i className={classes.socialIcons + " fab fa-sign-in"} />
                </Link>
              {/* </Button> */}
            </Tooltip>
          </ListItem>
        ) : (
            <ListItem className={classes.listItem}>
              <Tooltip id="instagram-tooltip"
                title="Log Out"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}>
                {/* <span style={{}}>Welcome {this.props.user.userInfo.merchantName || this.props.user.userInfo.role}</span> */}
                <Button color="transparent" onClick={this.logout}
                style={{ fontWeight: 'bold', 
                // border: '1px solid #252326', backgroundColor: '#FECE00', 
                color: "#252326" }}
                  className={classes.navLink}>
                  <span>
                    Log Out
                    <i className={classes.socialIcons + " fab fa-sign-out"} />
                  </span>
                </Button>
              </Tooltip>
            </ListItem>
          )}
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HeaderRightLinks);