import * as firebase from 'firebase';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDJf6mCj6ioIXKTGcneK36nTgUuiR1JDP8",
    authDomain: "findingfaber.firebaseapp.com",
    databaseURL: "https://findingfaber.firebaseio.com",
    projectId: "findingfaber",
    storageBucket: "findingfaber.appspot.com",
    messagingSenderId: "352908996988",
    appId: "1:352908996988:web:f55d28bdcb05bb3b25b4f9",
    measurementId: "G-N5JDC2MGS2"
  };

class Firebase {
    constructor() {
        app.initializeApp(firebaseConfig)
        this.auth = app.auth()
        this.auth.setPersistence(app.auth.Auth.Persistence.SESSION)
        this.storage = app.storage();
    }

    async deleteLogo(d, resHandler){
        // Create a reference to the file to delete
        //https://firebasestorage.googleapis.com/v0/b/findingfaber.appspot.com/o/logo   %2F14044%2F   jpp-logo.jpg  ?  alt=media&token=f3c975a8-8c19-4f06-a094-734c713b7cac
        var name = d.logoFile.split('%2F'+ d.managerID + '%2F')[1].split('?')[0].split("%20").join(' ');
        var desertRef = this.storage.ref().child(`logo/${d.managerID}/${name}`);

        // Delete the file
        await desertRef.delete().then(async function() {
        // File deleted successfully
            resHandler({statusT: true, error: null});
        }).catch(function(error) {
        // Uh-oh, an error occurred!
            resHandler({statusT: false, error: error});
        });
    }
    
    async deleteCoupon(d, resHandler){
        // Create a reference to the file to delete
        //https://firebasestorage.googleapis.com/v0/b/findingfaber.appspot.com/o/logo   %2F14044%2F   jpp-logo.jpg  ?  alt=media&token=f3c975a8-8c19-4f06-a094-734c713b7cac
        var name = d.coupon.split('%2F'+ d.managerID + '%2F')[1].split('?')[0].split("%20").join(' ');
        var desertRef = this.storage.ref().child(`coupon/${d.managerID}/${name}`);

        // Delete the file
        await desertRef.delete().then(async function() {
        // File deleted successfully
            resHandler({statusT: true, error: null});
        }).catch(function(error) {
        // Uh-oh, an error occurred!
            resHandler({statusT: false, error: error});
        });
    }

    async upLoadLogo(d, resHandler){
        const uploadManager = this.storage.ref(`logo/${d.managerID}/${d.logo.name}`).put(d.logo);
        await uploadManager.on(
            "state_changed",
            snapshot => {},
            err => {
                resHandler({type: 'logo', statusT: false, url: null, error: err});
            },
            () => {
                this.storage.ref(`logo/${d.managerID}`)
                .child(d.logo.name)
                .getDownloadURL()
                .then(u => {
                    resHandler({type: 'logo', statusT: true, url: u, error: null});
                })
            }
        )
    }

    async upLoadCoupon(d, resHandler){
        const uploadManager = this.storage.ref(`coupon/${d.managerID}/${d.coupon.name}`).put(d.coupon);
        await uploadManager.on(
            "state_changed",
            snapshot => {},
            err => {
                resHandler({type: 'coupon', statusT: false, url: null, error: err})
                // console.log("Here1: ", err);
            },
            () => {
                this.storage.ref(`coupon/${d.managerID}`)
                .child(d.coupon.name)
                .getDownloadURL()
                .then(u => {
                    // console.log("Here: ", u);
                    resHandler({type: 'coupon', statusT: true, url: u, error: null});
                })
            }
        );
    }

    login(credential) {
        return this.auth.signInWithEmailAndPassword(credential.email, credential.password);
    }

    logout() {
        return this.auth.signOut()
    }

    async register(data) {
        await this.auth.createUserWithEmailAndPassword(data.email, data.password)
        return this.auth.currentUser.updateProfile({
            displayName: data.name
        });
    }

    isInitialized() {
        return new Promise(resolve => {
            this.auth.onAuthStateChanged(resolve)
        })
    }

    getUserData() {
        if (this.auth.currentUser) {
            return this.auth.currentUser.getIdToken(true).then((idT) => {
                var returnData = {
                    idToken: idT,
                    status: 200,
                    error: false,
                    email: this.auth.currentUser.email
                }
                // var u = this.auth.currentUser.getUserData();
                return returnData;
            }).catch((error) => {
                return { status: false, error: error, idToken: false }
            });
        } else {
            return { status: false, error: "no active user", idToken: false }
        }
    }
}

export default new Firebase();


    // this.auth.setPersistence(app.auth.Auth.Persistence.SESSION)
    // .then(function () {
    //     return this.auth.signInWithEmailAndPassword(credential.email, credential.password);
    // })
    // .catch(function (error) {
    //     return error;
    // });