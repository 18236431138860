import { container, title } from "assets/jss/material-kit-react.js";
import buttonStyle from "assets/jss/material-kit-react/components/buttonStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-kit-react/customCheckboxRadioSwitch.js";

const basicsStyle = theme => ({
  ...buttonStyle,
  sections: {
    padding: "45px 0",
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0 0 0'
    }
  },
  container,
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  space50: {
    height: "50px",
    display: "block"
  },
  space70: {
    height: "70px",
    display: "block"
  },
  icons: {
    width: "17px",
    height: "17px",
    color: "#FFFFFF"
  },
  verticalMargin: {
    marginRight: "8px",
    marginLeft: "8px"
  },
  dialog: {
    zIndex: '9500',
    margin: '0 auto',
    maxWidth: '800px',
    '& .MuiDialog-paper': {
  
      [theme.breakpoints.down('xs')]: {
        margin: '0',
        width: '100%'
      },
      '& .MuiDialogTitle-root': {
  
        [theme.breakpoints.down('xs')]: {
          display: 'flex',
          flex: 'row',
          justifyContent: 'flex-start',
          padding: '16px'
        },
  
        '& .MuiButtonBase-root': {
          [theme.breakpoints.down('xs')]: {
            marginRight: '20px'
          },
        }
      },
      '& .MuiDialogContent-root':{
        [theme.breakpoints.down('xs')]: {
          padding: '8px 16px'
        },
        '& .MuiFormControl-root': {
          [theme.breakpoints.down('xs')]: {
            width: '100%'
          },
          '& .MuiInputBase-root': {
            width: '250px',
            [theme.breakpoints.down('xs')]: {
              width: '100%'
            },
          },      
        },
        '& #searchOrgInput': {
          '& .MuiFormControl-root':{
          [theme.breakpoints.down('xs')]: {
            width: '80%'
          },
          }
        },
        '& #searchOrgBtn': {
          [theme.breakpoints.down('xs')]: {
            width: '20%'
          },
        },
        '& #searchOrgSelect': {
          [theme.breakpoints.down('xs')]: {
            width: '100%'
          },
        },
      }
    }
  },
  mainTray: {
    [theme.breakpoints.down('xs')]: {
      padding: '0'
    },
    '& .MuiGrid-container': {
      margin: 0,
      [theme.breakpoints.down('xs')]: {
        margin: '0 5%'
      },
      '& .MuiGrid-item:first-child>span': {
        [theme.breakpoints.down('xs')]: {
          width: '100%'
        },
        '& span':{
          width: '100%'
        }
      }
    },
    '& .MuiGrid-item': {
      justifyContent: 'flex-end',      
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center'
      },
      '& span': {
        [theme.breakpoints.down('xs')]: {
          width: '100%'
        },
      },
    },
    '& .MuiGrid-item:first-child': {
      justifyContent: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center'
      },
    },
  },
  searchArea: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '5px'
    },
    '& span:first-child': {
      [theme.breakpoints.down('sm')]: {
        width: '70%'
      },
    },
    '& span:nth-child(2)':{
      [theme.breakpoints.down('sm')]: {
        width: '30%'
      },
    },
  },
  merchResults: {
    padding: '15px',
    [theme.breakpoints.down('xs')]: {
      padding: '5px'
    },
    '& .MuiChip-root': {
        marginBottom: '7px'
    },
    '& .MuiExpansionPanel-root': {
        margin: '0'
    },
    '& .MuiExpansionPanelDetails-root': {
      [theme.breakpoints.down('xs')]: {
        padding: '0',
        margin: '0'
      },
    }
  },
    merchHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
    '& .MuiButton-root': {
      width: '100%'
    }
  },
  ...customCheckboxRadioSwitch
});

export default basicsStyle;
