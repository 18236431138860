var model = {
    Merchant: () => {
        var newMerchant = {};
        newMerchant.MerchantName = "";
        newMerchant.Address1 = "";
        newMerchant.Address2 = "";
        newMerchant.City = "";
        newMerchant.State = "";
        newMerchant.Zip = "";
        newMerchant.ContactName = "";
        newMerchant.Phone = "";
        newMerchant.Email = "";
        newMerchant.Website = "";
        newMerchant.LogoFile = "";
        newMerchant.Password = "";
        return newMerchant;
    }
}

export default model;