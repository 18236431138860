import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import lightBlue from "@material-ui/core/colors/lightBlue";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";
// core components
import Chip from '@material-ui/core/Chip';
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import Grid from '@material-ui/core/Grid';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderRightLinks from "components/Header/HeaderRightLinks.js";
import HeaderLeftLinks from 'components/Header/HeaderLeftLinks.js';
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import CardHeader from '@material-ui/core/CardHeader';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';

import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from '@material-ui/core/InputAdornment';
// @material-ui/SnackBar
import Snackbar from '@material-ui/core/Snackbar';
import Portal from '@material-ui/core/Portal';

//@material-ui/expansion
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//Dialog
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Divider from '@material-ui/core/Divider';
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Fab from '@material-ui/core/Fab';
// Icons
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import MapIcon from '@material-ui/icons/Map';
import LanguageIcon from '@material-ui/icons/Language';
import EditIcon from '@material-ui/icons/Edit';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';
import AlarmIcon from '@material-ui/icons/Alarm';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

//Select
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

//Picker
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  TimePicker,
  validate,
} from '@material-ui/pickers';

// Validator
import validator from "../../validator/validator";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import api from "utils/api";

//Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
//import base64Img from 'base64-img';

import './customStyle.css';
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import { id } from "date-fns/locale";

//Default picture
import noLogo from "assets/img/no_logo.png";

import findingFaberLogo from '../../assets/img/FindingFaber-Logo/FFapp_icon only.png'

import useMediaQuery from '@material-ui/core/useMediaQuery';

import AspectRatio from 'react-aspect-ratio';

import 'react-aspect-ratio/aspect-ratio.css';

//Firebase
import firebase from '../../config/firebase';
import { FormControlLabel } from "@material-ui/core";

class ProfilePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userType: '',
      isFirebaseInit: false,
      promoLoading: false,
      activeUser: "",
      isLoadding: false,
      promoLoading: false,
      expanded: 'panel0',
      showMerchantModal: false,
      showManagerModal: false,
      cropImageMode: false,
      updatePwMode: false,
      PromotionList: [],
      MerchantList: [],
      selectedCamp: null,
      editAddress1: "",
      editAddress1Err: false,
      editAddress2: "",
      editAddress2Err: false,
      editCity: "",
      editCityErr: false,
      editContactName: "",
      editContactNameErr: false,
      savedLogoFile: '',
      editLogoFile: '',
      stagedLogoFile: '',
      editLogoFileErr: false,
      editLogoFileErrMsg: '',
      editManagerID: "",
      editManagerIDErr: false,
      editManagerName: '',
      editManagerNameErr: false,
      editManagerEmail: '',
      editManagerEmailErr: false,
      editManagerPassword: '',
      editManagerPasswordErr: false,
      editManagerConfirmPw: '',
      editManagerConfirmPwErr: false,
      editMerchantEmail: "",
      editMerchantEmailErr: false,
      editMerchantID: null,
      editMerchantIDErr: false,
      editMerchantName: "",
      editMerchantNameErr: false,
      editPhone: "",
      editPhoneErr: false,
      editPromotionLimit: null,
      editPromotionLimitErr: false,
      editState: "",
      editStateErr: false,
      editStatus: null,
      editStatusErr: false,
      editWebsite: 'http://www.',
      editWebsiteErr: false,
      editZip: "",
      editZipErr: false,
      merchantModalType: null,
      showPromoModal: false,
      ManagerID: null,
      MerchantID: null,
      ManagerName: '',
      Email: '',
      Password: '',
      Description1: '',
      Description1Err: null,
      Description2: '',
      Description2Err: null,
      Description3: '',
      Description3Err: null,
      Barcode: null,
      BarcodeErr: null,
      Coupon: '',
      CouponErr: null,
      CouponFile: '',
      CouponFileErr: null,
      CouponFileErrMsg: '',
      Status: null,
      StatusErr: null,
      PaidWeight: null,
      PaidWeighErr: null,
      AccessCount: null,
      promoStartDate: new Date(),
      promoStartDateErr: null,
      promoEndDate: new Date(),
      promoEndDateErr: null,
      newPromoSelectedMerchant: '',
      snackMsg: '',
      shouldSnackBarOpen: false,
      isOpenMonday: true,
      monOpen: 0,
      monClose: 0,
      isOpenTuesday: true,
      tueOpen: 0,
      tueClose: 0,
      isOpenWednesday: true,
      wedOpen: 0,
      wedClose: 0,
      isOpenThursday: true,
      thuOpen: 0,
      thuClose: 0,
      isOpenFriday: true,
      friOpen: 0,
      friClose: 0,
      isOpenSaturday: true,
      satOpen: 0,
      satClose: 0,
      isOpenSunday: true,
      sunOpen: 0,
      sunClose: 0,
      rows: [],
      isAllDaySame: false,
      isGenericBusinessDay: false,
      allDayOpenH: 12,
      allDayCloseH: 12,
      weekDayOpenH: 12,
      weekDayCloseH: 12,
      weekendDayOpenH: 12,
      weekendDayCloseH: 12,
      selectedMerchant: null,
      isDetailPromoDialog: true,
      selectedPromo: null,
      orgList: [],
      linkCatID: 0,
      categoryID: 0,
      subcategoryID: 0,
      catOptions: [],
      subCatOptions: [],
      selectedCat: '',
      selectedCatErr: '',
      selectedSubCat: '',
      selectedSubCatErr: '',
      merchantCatLabels: [],
      selectedMerchantID: 0,
      promoStatus: 0,
      activePromoCount: 0,
      selectedMerchantLimit: 0,
      promoIsActive: false,
      accessCount: 0,
      followerCount: 0,
      activeTab: 0,
      previewPromo: false,
      monPicker1: false,
      isWrongCat: false,
      sessionCounter: 0,
      custID: null,
      showTimePicker: false,
      selectedTime: null,
      hour: '12',
      minute: '00',
      dayTime: 'PM',
      hours: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      minutes: ['00', '01', '02', '03', '04', '05', '06', '07', '08',
        '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', ' 19',
        '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
        '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
        '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
        '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
      ],
      businessHoursNote: '',
      showErrorDialog: false,
      jppcAdminError: null,
      isErrorCopied: false,
      shouldRemoveLogo: false,
      logoBackUp: null
    }
  }

  sessionWatcher = null;

  watchSession = () => {
    this.sessionWatcher = setInterval(() => {
      console.log('sessionCounter: ', this.props.sessionCounter);
      var newSessC = this.props.sessionCounter + 10000; //adding 10sec
      this.props.setSessionCounter(this.props.sessionCounter + 10000);
      if (newSessC > 50 * 60000) { // will show time out after 50min
        // if (newSessC > 15000) { // will show time out after 20sec
        this.props.showTimeOutWarning()
      }
    }, 10000);//each 10sec 
  }

  componentWillUnmount() {
    if (this.sessionWatcher) {
      clearInterval(this.sessionWatcher);
    }
  }


  componentDidMount() {
    console.log("Manager");
    this.watchSession();
    if (this.props.user) {
      this.setState({
        MerchantList: this.props.user.userInfo.merchantList,
        ManagerID: this.props.user.userInfo.managerID,
        ManagerName: this.props.user.userInfo.managerName,
        ManagerEmail: this.props.user.userInfo.email,
        newPromoSelectedMerchant: this.props.user.userInfo.merchantList[0].merchantID,
        newPromoSelectedMerchantName: this.props.user.userInfo.merchantList[0].merchantName, //edit this for multiple merchants
        Status: this.props.user.userInfo.status,
        userType: this.props.type
      }, () => {
        api.getMerchantPromotion({
          merchantID: this.props.user.userInfo.merchantList[0].merchantID,
          idToken: this.props.user.idToken
        }).then((list) => {
          let activePromos = 0;
          list.data.forEach((promo) => {
            if (promo.status === 1) {
              activePromos++;
            }
          });

          this.setState({ PromotionList: list.data, selectedMerchantID: this.props.user.userInfo.merchantList[0].merchantID, selectedMerchantLimit: this.props.user.userInfo.merchantList[0].promotionLimit, activePromoCount: activePromos });
        });
        api.getCategories().then(res => {
          this.setState({
            catOptions: res.data.categoryModelList,
            subCatOptions: res.data.subCategoryModelList
          })

          let labeledMerchantList = [];


          //Need to double check when manager can have more than one merchant
          this.setState({ custID: this.state.MerchantList[0].custID })

          this.state.MerchantList.forEach((merchant) => {

            if (!merchant.merchantSubcategory) {
              merchant.merchantSubcategory = {
                subcategoryID: 1,
                merchantSubcategoryID: 1,
                merchantID: merchant.merchantID
              }
              // here no cat
              this.setState({ isWrongCat: true })
            }
            let catID = this.handleFindLinkedCatFromSub(merchant.merchantSubcategory.subcategoryID);
            let getSub = this.handleFindSubCatName(merchant.merchantSubcategory.subcategoryID);
            let getCat = this.handleFindCatName(catID.categoryID);

            merchant.merchantSubcategory = {
              merchantID: merchant.merchantSubcategory.merchantID,
              merchantSubcategoryID: merchant.merchantSubcategory.merchantSubcategoryID,
              subcategoryID: merchant.merchantSubcategory.subcategoryID,
              categoryID: catID.categoryID,
              subcategoryName: getSub,
              categoryName: getCat
            }


            labeledMerchantList.push(merchant);
          })
          this.setState({
            MerchantList: labeledMerchantList
          });

        });
      });

    }
  }

  handlePanelChange = (panel) => (event, isExpanded) => {
    isExpanded ? (
      this.setState({ expanded: panel })
    ) : (
        this.setState({ expanded: false })
      )
  };
  handleFindLinkedCatFromSub = (id) => {

    let category = this.state.subCatOptions.find((option) => {
      return option.subCategoryID === id
    });

    return category;
  }

  handleFindCatName = (id) => {
    let cat = this.state.catOptions.find((option) => {
      return option.categoryID === id;
    })


    return cat.categoryName;
  }

  handleFindSubCatName = (id) => {

    let cat = this.state.subCatOptions.find((option) => {
      return option.subCategoryID === id;
    })


    return cat.subCategoryName;
  }

  editMerchant = (merchant) => {
    //find category id from subcatid

    let catID = this.handleFindLinkedCatFromSub(merchant.merchantSubcategory.subcategoryID);


    let subcatObj = this.state.subCatOptions.find((option) => { return option.subCategoryID === merchant.merchantSubcategory.subcategoryID });

    let catObj = this.state.catOptions.find((option) => { return option.categoryID === catID.categoryID });

    this.setState({
      logoUrl: null,
      croppedLogoUrl: null,
      crop: {
        unit: '%',
        width: 100,
        height: 100
        // aspect: 16 / 16,
      },
      orgList: merchant.merchantOrganizationList,
      editAddress1: merchant.address1,
      editAddress1Err: false,
      editAddress2: merchant.address2,
      editAddress2Err: false,
      editCity: merchant.city,
      editCityErr: false,
      editContactName: merchant.contactName,
      editContactNameErr: false,
      savedLogoFile: merchant.logoFile,
      editLogoFile: '',
      editLogoFileErr: false,
      stagedLogoFile: '',
      editManagerID: merchant.managerID,
      editManagerIDErr: false,
      editMerchantEmail: merchant.merchantEmail,
      editMerchantEmailErr: false,
      editMerchantID: merchant.merchantID,
      editMerchantIDErr: false,
      editMerchantName: merchant.merchantName,
      editMerchantNameErr: false,
      editPhone: merchant.phone,
      editPhoneErr: false,
      editPromotionLimit: merchant.promotionLimit,
      editPromotionLimitErr: false,
      editState: merchant.state,
      editStateErr: false,
      editStatus: merchant.status,
      editStatusErr: false,
      editWebsite: merchant.website || 'http://www.',
      editWebsiteErr: false,
      editZip: merchant.zip,
      editZipErr: false,
      monOpen: this.verifyTime(merchant.monOpen),
      monClose: this.verifyTime(merchant.monClose),
      tueOpen: this.verifyTime(merchant.tueOpen),
      tueClose: this.verifyTime(merchant.tueClose),
      wedOpen: this.verifyTime(merchant.wedOpen),
      wedClose: this.verifyTime(merchant.wedClose),
      thuOpen: this.verifyTime(merchant.thuOpen),
      thuClose: this.verifyTime(merchant.thuClose),
      friOpen: this.verifyTime(merchant.friOpen),
      friClose: this.verifyTime(merchant.friClose),
      satOpen: this.verifyTime(merchant.satOpen),
      satClose: this.verifyTime(merchant.satClose),
      sunOpen: this.verifyTime(merchant.sunOpen),
      sunClose: this.verifySundayCloseTime(merchant.sunClose),
      allDayOpenH: '09:00 AM',
      allDayCloseH: '05:00 PM',
      weekDayOpenH: '09:00 AM',
      weekDayCloseH: '05:00 PM',
      weekendDayOpenH: '09:00 AM',
      weekendDayCloseH: '05:00 PM',
      isOpenMonday: merchant.monOpen != 'Closed' ? true : false,
      isOpenTuesday: merchant.tueOpen != 'Closed' ? true : false,
      isOpenWednesday: merchant.wedOpen != 'Closed' ? true : false,
      isOpenThursday: merchant.thuOpen != 'Closed' ? true : false,
      isOpenFriday: merchant.friOpen != 'Closed' ? true : false,
      isOpenSaturday: merchant.satOpen != 'Closed' ? true : false,
      isOpenSunday: merchant.sunOpen != 'Closed' ? true : false,
      showMerchantModal: true,
      editManagerEmail: this.state.ManagerEmail,
      editManagerName: this.state.ManagerName,
      linkCatID: merchant.merchantSubcategory.merchantSubcategoryID || 0,
      // subcategoryID: merchant.merchantSubcategory.subcategoryID || 0,
      // categoryID: catID.categoryID || 0,
      selectedCat: catObj || '',
      selectedCatErr: '',
      selectedSubCat: subcatObj || '',
      selectedSubCatErr: '',
      accessCount: merchant.accessCount,
      followerCount: merchant.followerCount,
      shouldRemoveLogo: false
    });
  }


  verifySundayCloseTime = (t) => {
    //console.log('time: ', time);
    var time = null;
    if (t) {
      time = t.split('-')[0];
      console.log('t: ', t)
      if (t.split('-').length > 1) {
        var r = time + '-';
        console.log('in: ', t.replace(r, ''));
        this.setState({ businessHoursNote: t.replace(r, '') });
      } else {
        this.setState({ businessHoursNote: '' })
      }
    }

    if (time) {
      if (time.indexOf('AM') > -1 || time.indexOf('PM') > -1) {
        return time;
      }
      return '12:00 PM';
    }

    return '12:00 PM';
  }

  verifyTime = (time) => {
    //console.log('time: ', time);
    if (time) {
      if (time.indexOf('AM') > -1 || time.indexOf('PM') > -1) {
        return time;
      }
      return '12:00 PM';
    }

    return '12:00 PM';
  }

  validateInput = (type, value) => {
    var control = {
      MerchantName: this.state.editMerchantName,
      ManagerName: this.state.editManagerName,
      Email: this.state.editEmail,
      ContactName: this.state.editContactName
    }
    var isOkay = true;


    switch (true) {
      case (type == "editMerchantName" || this.state.isSubmit): {
        if (type == "editMerchantName" && !this.state.isSubmit) {
          var v = validator.name(value); //validator returns 'required' if no input

          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.name(this.state.editMerchantName);

          if (!v.valid) {
            this.setState({ isSubmit: false });
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      case (type == "Description1" || this.state.isSubmit): {
        if (type == "Description1" && !this.state.isSubmit) {
          if (!value) {
            return 'Title is required!'; //required
          } else {
            return false;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.name(this.state.description1);

          if (this.state.Description1) {
            this.setState({ isSubmit: false });
            this.openSnackBar("Invalid Input");
            return false;
          }
        }
      }
      case (type == "Description2" || this.state.isSubmit): {
        if (type == "Description2" && !this.state.isSubmit) {
          // if (!value) {
          //   return true; 
          // } else {
          return false; //not required
          // }
        }
        if (this.state.isSubmit) {
          if (this.state.Description2) {
            this.setState({ isSubmit: false });
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      case (type == "Description3" || this.state.isSubmit): {
        if (type == "Description3" && !this.state.isSubmit) {
          // if (!value) {
          //   return true;
          // } else {
          return false; //not required
          // }
        }
        if (this.state.isSubmit) {

          if (this.state.Description3) {
            this.setState({ isSubmit: false });
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      case (type == "Barcode" || this.state.isSubmit): {
        if (type == "Barcode" && !this.state.isSubmit) {
          // if (!value) {
          //   return true;
          // } else {
          return false; //not required
          //}
        }
        if (this.state.isSubmit) {

          if (this.state.Barcode) {
            this.setState({ isSubmit: false });
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      case (type == 'editAddress1' || this.state.isSubmit): {
        if (type == 'editAddress1' && !this.state.isSubmit) {
          if (!value) {//required
            return 'Address is required!';
          }
          var v = validator.address(value);
          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.address(this.state.editAddress1);
          if (!v.valid) {
            this.setState({ isSubmit: false })
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      case (type == 'editCity' || this.state.isSubmit): {
        if (type == 'editCity' && !this.state.isSubmit) {
          //required
          if (!value) {
            return 'City is required!';
          }
          if (value.length > 3) {
            return false;
          } else {
            return 'Input too short!';
          }
        }
        if (this.state.isSubmit) {
          if (this.state.editCity.length < 3) {
            this.setState({ isSubmit: false })
            this.openSnackBar("Please review City");
            return false;
          }
        }
      }
      case (type == 'editState' || this.state.isSubmit): {
        if (type == 'editState' && !this.state.isSubmit) {
          //required
          if (value) {
            return false;
          } else {
            return 'State is required!';
          }
        }
        if (this.state.isSubmit) {
          if (!this.state.editState) {
            this.setState({ isSubmit: false })
            this.openSnackBar("Please review State");
            return false;
          }
        }
      }
      case (type == 'editZip' || this.state.isSubmit): {
        if (type == 'editZip' && !this.state.isSubmit) {
          //required
          if (!value) {
            return 'Zipcode is required!';
          }
          var v = validator.zip(value);
          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.zip(this.state.editZip);
          if (!v.valid) {
            this.setState({ isSubmit: false })
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      case (type == 'editContactName' || this.state.isSubmit): {
        if (type == 'editContactName' && !this.state.isSubmit) {
          //not required, but if entered needs validation.
          if (!value) {
            return false;
          }
          var v = validator.name(value);
          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.name(this.state.editContactName);
          if (!v.valid) {
            this.setState({ isSubmit: false })
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      case (type == 'editPhone' || this.state.isSubmit): {
        if (type == 'editPhone' && !this.state.isSubmit) {
          //not required, but if entered needs validation
          if (!value) {
            return false;
          }
          var v = validator.phone(value);
          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.phone(this.state.editPhone);
          if (!v.valid) {
            this.setState({ isSubmit: false })
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      case (type == 'editWebsite' || this.state.isSubmit): {
        if (type == 'editWebsite' && !this.state.isSubmit) {
          //not required, but if entered needs validation
          if (!value) {
            return false;
          }
          var v = validator.website(value);
          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.website(this.state.editWebsite);
          if (!v.valid) {
            this.setState({ isSubmit: false })
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      // case 'logo':{
      //   if(this.state.focusedInput == type || this.state.isSubmit){

      //   return false;

      //   }
      // }
      case (type == 'editMerchantEmail' || this.state.isSubmit): {
        if (type == 'editMerchantEmail' && !this.state.isSubmit) {
          //not required, but if entered needs validation
          if (!value) {
            return false;
          }
          var v = validator.email(value);
          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          if (this.state.editEmail) {
            var v = validator.email(this.state.editEmail);
            if (!v.valid) {
              this.setState({ isSubmit: false })
              this.openSnackBar(v.reason);
              return false;

            } else {
              this.setState({ isSubmit: false });
              return v.reason;
            }

          }
        }
      }
      case (type == "editManagerName" || this.state.isSubmit): {
        if (type == "editManagerName" && !this.state.isSubmit) {
          var v = validator.name(value);

          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.name(this.state.editManagerName);

          if (!v.valid) {
            this.setState({ isSubmit: false });
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      case (type == 'editManagerEmail' || this.state.isSubmit): {
        if (type == 'editManagerEmail' && !this.state.isSubmit) {
          if (this.state.editManagerEmail) {
            var v = validator.email(value);
            if (v.valid) {
              return false;
            } else {
              return v.reason;
            }

          } else {

            this.setState({ isSubmit: false });
            return 'Email is required!';
          }

        }
        if (this.state.isSubmit) {
          if (this.state.editManagerEmail) {
            var v = validator.email(this.state.editManagerEmail);
            if (!v.valid) {
              this.setState({ isSubmit: false })
              this.openSnackBar(v.reason);
              return v.reason;
            }
          } else {
            this.setState({ isSubmit: false });
            return 'Email is required!';
          }
        }
      }
      case (type == 'editManagerPassword' || this.state.isSubmit): {
        if (type == 'editManagerPassword' && !this.state.isSubmit) {
          var v = validator.validatePassword(value, control);
          if (v.valid) {
            return false;
          } else {
            return v.reason;

            // return true;
            // this.setState({editManagerPasswordErr: v.reason});
          }
        }
        if (this.state.isSubmit) {
          var v = validator.validatePassword(this.state.editManagerPassword, control);
          if (!v.valid) {
            this.setState({ isSubmit: false })
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      case (type == 'editManagerConfirmPw' || this.state.isSubmit): {
        if (type == 'editManagerConfirmPw' && !this.state.isSubmit) {
          return false;
        }
        if (this.state.isSubmit) {
          var v = validator.validatePassword(this.state.editManagerConfirmPw, control);
          if (!v.valid) {
            this.setState({ isSubmit: false })
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
    }

    return isOkay;
  }

  handleInputChange = (event) => {
    var value = event.target.value;
    var name = event.target.name;


    this.setState({
      [name]: value
    }, () => {
      var result = this.validateInput(name, value);
      var checker = name + "Err"
      this.setState({
        [checker]: result
      })

    })
  }

  // newMerchant = () => {
  //   this.clearMerchantModalInput();
  //   this.setState({ showMerchantModal: true, merchantModalType: 'new' })
  // }

  clearMerchantModalInput = () => {
    this.setState({
      editAddress1: "",
      editAddress1Err: false,
      editAddress2: "",
      editAddress2Err: false,
      editCity: "",
      editCityErr: false,
      editContactName: "",
      editContactNameErr: false,
      savedLogoFile: '',
      editLogoFile: '', //this might not need to be here since its previous ref is now stagedLogoFile and it's only used for crop
      editLogoFileErr: false,
      stagedLogoFile: '',
      editManagerID: "",
      editManagerIDErr: false,
      editMerchantEmail: "",
      editMerchantEmailErr: false,
      editMerchantID: null,
      editMerchantIDErr: false,
      editMerchantName: "",
      editMerchantNameErr: false,
      editPhone: "",
      editPhoneErr: false,
      editPromotionLimit: null,
      editPromotionLimitErr: false,
      editState: "",
      editStateErr: false,
      editStatus: null,
      editStatusErr: false,
      editWebsite: "",
      editWebsiteErr: false,
      editZip: "",
      editZipErr: false,
      linkCatID: '',
      selectedCat: '',
      selectedCatErr: '',
      selectedSubCat: '',
      selectedSubCatErr: '',
      accessCount: 0,
      followerCount: 0
    })
  }

  handleUpdateMerchant = (editedData) => {
    if (this.state.shouldRemoveLogo) {
      this.deleteSavedLogo();
    }
    // if()
    editedData.merchantID = this.state.editMerchantID;
    editedData.merchantName = this.state.editMerchantName;
    editedData.address1 = this.state.editAddress1;
    editedData.address2 = null;
    editedData.city = this.state.editCity;
    editedData.state = this.state.editState;
    editedData.zip = this.state.editZip;
    editedData.contactName = this.state.editContactName;
    editedData.phone = this.state.editPhone;
    editedData.merchantEmail = this.state.editMerchantEmail;
    editedData.website = this.state.editWebsite;
    editedData.status = 1;
    editedData.promotionLimit = this.state.editPromotionLimit;
    editedData.managerID = this.state.ManagerID;
    editedData.monOpen = this.state.isOpenMonday ? this.state.monOpen : 'Closed';
    editedData.monClose = this.state.isOpenMonday ? this.state.monClose : 'Closed';
    editedData.tueOpen = this.state.isOpenTuesday ? this.state.tueOpen : 'Closed';
    editedData.tueClose = this.state.isOpenTuesday ? this.state.tueClose : 'Closed';
    editedData.wedOpen = this.state.isOpenWednesday ? this.state.wedOpen : 'Closed';
    editedData.wedClose = this.state.isOpenWednesday ? this.state.wedClose : 'Closed';
    editedData.thuOpen = this.state.isOpenThursday ? this.state.thuOpen : 'Closed';
    editedData.thuClose = this.state.isOpenThursday ? this.state.thuClose : 'Closed';
    editedData.friOpen = this.state.isOpenFriday ? this.state.friOpen : 'Closed';
    editedData.friClose = this.state.isOpenFriday ? this.state.friClose : 'Closed';
    editedData.satOpen = this.state.isOpenSaturday ? this.state.satOpen : 'Closed';
    editedData.satClose = this.state.isOpenSaturday ? this.state.satClose : 'Closed';
    editedData.sunOpen = this.state.isOpenSunday ? this.state.sunOpen : 'Closed';
    editedData.sunClose = this.state.isOpenSunday ? this.state.sunClose + '-' + this.state.businessHoursNote : 'Closed' + '-' + this.state.businessHoursNote;
    editedData.accessCount = this.state.accessCount;
    editedData.followerCount = this.state.followerCount;
    editedData.custID = this.state.custID;

    // editedData.merchantOrganizationList = this.state.orgList;
    // editedData.merchantSubcategory = {
    //   merchantID: this.state.editMerchantID,
    //   merchantSubcategoryID: this.state.linkCatID || 0,
    //   subCategoryID: this.state.selectedSubCat
    // };

    var data = {
      data: editedData,
      idToken: this.props.user.idToken
    }

    api.updateMerchant(data).then(res => {

      if (res.status == 200) {
        this.updateManagerProps(editedData);
        this.setState({ sessionCounter: 0 });
      } else {
        this.openSnackBar("We were not able to update the information. Please try again later. If you keep getting this error please contact us!");

      }
    })
      .catch(err => {
        var errObj = {
          ApiRoute: "/api/updateMerchant",
          Page: 'Manager',
          ApiErrMsg: err,
          ApiData: data
        }
        this.setState({
          jppcAdminError: JSON.stringify(errObj),
          showErrorDialog: true
        })
        this.openSnackBar("An unexpected error has occured. Please try again later. If you keep getting this error please contact us!");

      });

  }

  submitMerchant = (type) => {

    //if no name, email, error and return false

    // macaroni apply to category and org too

    if (!this.state.editMerchantName) {
      this.setState({ editMerchantNameErr: 'Required!' })
      return false;
    }

    if (!this.state.selectedSubCat || !this.state.linkCatID) {
      if (!this.state.selectedCat) {
        this.setState({ selectedCatErr: 'Select a Category!' })
        return false;
      }
      this.setState({ selectedSubCatErr: 'Select Subcategory!' })
      return false;
    }

    if (this.state.editMerchantNameErr || this.state.editMerchantEmailErr) {
      this.openSnackBar('Please review errors')
      return false;
    }

    let subCatData = {
      managerID: this.state.ManagerID,
      merchantID: this.state.editMerchantID,
      merchantSubcategoryID: this.state.linkCatID || 0,
      subCategoryID: this.state.selectedSubCat.subCategoryID
    };

    if (this.state.isWrongCat) {
      subCatData.merchantSubcategoryID = 0;
    }

    let updateData = {
      data: subCatData,
      idToken: this.props.user.idToken,
      merchantID: this.state.editMerchantID,
    };
    // console.log('updateData: ', updateData);
    //before checking logo, let's update subcat
    api.updateSubCat(updateData).then(res => {
      if (res.status == 200) {
        this.setState({ isWrongCat: false, sessionCounter: 0 });
        // data.merchantSubcategory
        // update state with new cat setting
        // taking care of merchant update
        var merchantList = this.state.MerchantList;
        var merchantSubcategoryID;

        merchantList.forEach((merchant, i) => {
          if (merchant.merchantID == this.state.editMerchantID) {
            merchant.merchantSubcategory.merchantSubcategoryID = res.data.merchantSubcategory.merchantSubcategoryID;
            this.setState({
              linkCatID: res.data.merchantSubcategory.merchantSubcategoryID,
              // selectedCat:  this.state.catOptions.find((option) => { return option.categoryID === catID.categoryID }),
              // selectedSubCat: this.state.subCatOptions.find((option) => { return option.subCategoryID === merchant.merchantSubcategory.subcategoryID }),
              subcategoryID: res.data.merchantSubcategory.subcategoryID,
              merchantSubcategoryID: res.data.merchantSubcategory.merchantSubcategoryID
            })
          }
        });


        if (this.state.userType === 'manager') {
          var user = this.props.user;
          user.userInfo.merchantList = merchantList;
          user.userInfo.email = this.state.editManagerEmail;
          user.userInfo.managerName = this.state.editManagerName;
          this.props.setUser(user);
        }
        //go ahead and do other updates
        if (this.state.stagedLogoFile) {
          var dt = {
            data: this.state.stagedLogoFile,
            idToken: this.props.user.idToken
          }

          var d = {
            managerID: this.props.user.userInfo.managerID,
            logo: this.state.stagedLogoFile
          }

          firebase.upLoadLogo(d, this.handleFireBaseRes);
        } else {
          //just update merchant

          this.setState({ stagedLogoFile: this.state.savedLogoFile || '' });

          var editedData = {};
          editedData.logoFile = this.state.stagedLogoFile;

          this.handleUpdateMerchant(editedData);

        }
      } else {
        this.openSnackBar("Error updating. Please try again. If you keep getting this error, please contact us!");
        console.log("update error: ", res);
      }
    }).catch(err => {
      console.log(err);
      var errObj = {
        ApiRoute: "/api/updateMerchantSubcat",
        Page: 'Manager',
        ApiErrMsg: err,
        ApiData: updateData
      }
      this.setState({
        jppcAdminError: JSON.stringify(errObj),
        showErrorDialog: true
      })
      this.openSnackBar("An unexpected error has occured. Please try again later. If you keep getting this error please contact us!");
    });
  }

  updateManagerProps = (dt) => {


    // taking care of merchant update
    var merchantList = this.state.MerchantList;

    if (dt.merchantID) {
      merchantList.forEach((merchant, i) => {
        if (merchant.merchantID == this.state.editMerchantID) {
          dt.merchantOrganizationList = merchant.merchantOrganizationList;

          dt.merchantSubcategory = {
            merchantID: merchant.merchantSubcategory.merchantID,
            merchantSubcategoryID: merchant.merchantSubcategory.merchantSubcategoryID,
            categoryID: this.state.selectedCat.categoryID, //updated
            categoryName: this.state.selectedCat.categoryName, //updated
            subcategoryID: this.state.selectedSubCat.subCategoryID, //updated
            subcategoryName: this.state.selectedSubCat.subCategoryName
          }

          merchantList[i] = dt;
        }
      });
    }

    if (this.state.userType === 'manager') {
      var user = this.props.user;
      user.userInfo.merchantList = merchantList;
      user.userInfo.email = this.state.editManagerEmail;
      user.userInfo.managerName = this.state.editManagerName;
      this.props.setUser(user);
    }

    this.setState({ MerchantList: merchantList, ManagerName: this.state.editManagerName, ManagerEmail: this.state.editManagerEmail, showMerchantModal: false, showManagerModal: false });
    this.openSnackBar('Info Updated!');
  }

  merge(obj1, obj2) {
    var obj3 = {};
    for (var attrname in obj1) { obj3[attrname] = obj1[attrname]; }
    for (var attrname in obj2) { obj3[attrname] = obj2[attrname]; }
    return obj3;
  }

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };

  createPromo = (status) => {

    //if activepromocount >= promolimit, return false & let user know 
    if (this.state.activePromoCount >= this.state.selectedMerchantLimit && this.state.promoIsActive) {
      this.setState({ previewPromo: false, promoLoading: false });
      this.openSnackBar(`You already have ${this.state.activePromoCount} active promotions. You can create this promo as inactive`);
      return false;
    }

    var dt = {
      ManagerID: this.state.ManagerID,
      MerchantID: this.state.newPromoSelectedMerchant,
      Description1: this.state.Description1,
      Description2: this.state.Description2,
      Description3: this.state.Description3,
      Coupon: this.state.Coupon,
      Barcode: this.state.Barcode,
      Status: status,
      PromotionStart: this.state.promoStartDate.toISOString(),
      PromotionEnd: this.state.promoEndDate.toISOString(),
      PaidWeight: 0,
      AccessCount: 0
    }
    var data = {
      data: dt,
      idToken: this.props.user.idToken
    }
    api.createPromotion(data).then(res => {

      if (res.data.merchantPromotionID) {
        this.setState({ sessionCounter: 0 });
        this.openSnackBar("Promotion created.");
        var pList = this.state.PromotionList;
        pList.push(res.data);
        if (status === 1) {
          this.setState({ activePromoCount: this.state.activePromoCount + 1 })
        }
        this.setState({ previewPromo: false, promoLoading: false });
        this.clearPromoDialog();
      } else {
        this.openSnackBar("Error creating promotion. Please try again later");
        this.setState({ previewPromo: false, promoLoading: false });
      }
    }).catch(err => {
      var errObj = {
        ApiRoute: "/api/createPromotion",
        Page: 'Manager',
        ApiErrMsg: err,
        ApiData: data
      }
      this.setState({
        jppcAdminError: JSON.stringify(errObj),
        showErrorDialog: true
      })
      this.openSnackBar("An unexpected error has occured. Please try again later");
      this.setState({ previewPromo: false, promoLoading: false });
    })

  }

  // submitActivePromo = () => {
  //   this.setState({promoIsActive: true});
  //   this.submitPromo();
  // }

  // submitInActivePromo = () => {
  //   this.setState({promoIsActive: false});
  //   this.submitPromo();
  // }

  submitPromo = async (setActive) => {
    //maybe check here to see if merchant has all required info?
    let thisMerchant = this.state.MerchantList.find((merchant) => { return merchant.merchantID === this.state.newPromoSelectedMerchant })
    if (!thisMerchant.merchantSubcategory) {
      this.setState({ previewPromo: false, merchantSelectErr: `Business is missing category info! Please complete business info before creating a promotion!` })
      return false;
    }

    //also if no promo title or dates, reject
    if (!this.state.Description1 || this.state.Description1Err || !this.state.promoStartDate || this.state.promoStartDate == 'Invalid Date' || !this.state.promoEndDate || this.state.promoEndDate == 'Invalid Date' || this.state.promoStartDateErr || this.state.promoEndDateErr) {
      if (!this.state.Description1) {
        this.setState({ previewPromo: false, Description1Err: 'Title is required!' })
      }

      if (!this.state.promoStartDate) {
        this.setState({ previewPromo: false, promoStartDateErr: 'Set promo dates!' })
      }
      if (!this.state.promoEndDate) {
        this.setState({ previewPromo: false, promoEndDateErr: 'Set promo dates!' })
      }
      if (this.state.promoStartDate == 'Invalid Date') {
        this.setState({ previewPromo: false, promoStartDateErr: 'Invalid Date' })
      }
      if (this.state.promoEndDate == 'Invalid Date') {
        this.setState({ previewPromo: false, promoEndDateErr: 'Invalid Date' })
      }

      if (this.state.Description1Err || this.state.promoStartDateErr || this.state.promoEndDateErr) {
        this.openSnackBar('Please review errors!')
      }

      return false;
    }

    this.setState({ promoLoading: true, promoStatus: setActive });
    //if theres a coupon file, upload the file then create promo
    //else just create promo

    if (this.state.CouponFile) {

      var d = {
        managerID: this.props.user.userInfo.managerID,
        coupon: this.state.CouponFile
      }

      firebase.upLoadCoupon(d, this.handleFireBaseRes);

    } else {
      //if isDetailPromoDialog, update promo, else create
      if (this.state.isDetailPromoDialog) {

        this.handleUpdatePromo(setActive);
      } else {
        this.createPromo(setActive);
      }
    }
  }

  handleFireBaseRes = (res, setActive) => {
    if (res.type == 'coupon') {
      if (res.statusT) {
        this.setState({ Coupon: res.url }, () => {
          //if isDetailPromoDialog, update promo, else create

          if (this.state.isDetailPromoDialog) {
            this.handleUpdatePromo(setActive);
          } else {
            this.createPromo(setActive);
          }
        });
      } else {
        this.openSnackBar("Error uploading image. Please try again. If you keep getting this error, please contact us!");
        console.log("update error: ", res.error);
        this.setState({ promoLoading: false });
      }
    }

    if (res.type == 'logo') {
      if (res.statusT) {
        var editedData = {};
        editedData.logoFile = res.url;
        this.handleUpdateMerchant(editedData);
      } else {
        this.openSnackBar("Error updating logo. Please try again. If you keep getting this error, please contact us!");
      }
    }
  }

  clearPromoDialog = (msg) => {
    this.setState({
      Description1: '',
      Description1Err: null,
      Description2: '',
      Description2Err: null,
      Description3: '',
      Description3Err: null,
      Barcode: null,
      BarcodeErr: null,
      Coupon: '',
      CouponErr: null,
      CouponFile: '',
      CouponFileErr: '',
      promoStartDate: new Date(),
      promoEndDate: new Date(),
      merchantSelectErr: null,
      promoStatus: 0,
      PaidWeight: null,
      AccessCount: null,
      promoIsActive: ''
    })
  }

  openPromoDetail = (promo) => {

    this.setState({
      Description1: promo.description1,
      Description1Err: null,
      Description2: promo.description2,
      Description2Err: null,
      Description3: promo.description3,
      Description3Err: null,
      Barcode: promo.barcode,
      BarcodeErr: null,
      Coupon: promo.coupon,
      CouponErr: null,
      CouponFile: null,
      promoStartDate: new Date(promo.promotionStart),
      promoEndDate: new Date(promo.promotionEnd),
      selectedPromo: promo,
      isDetailPromoDialog: true,
      showPromoModal: true,
      merchantSelectErr: null,
      promoStatus: promo.status,
      PaidWeight: promo.paidWeight,
      AccessCount: promo.accessCount,
      promoIsActive: promo.status ? true : false,
      // promoSetActive: promo.status ? true : false
    })
  }

  handleUpdatePromo = (setActive) => {

    this.setState({ promoStatus: setActive });
    //if activepromocount >= promolimit, return false & let user know 
    if (this.state.activePromoCount >= this.state.selectedMerchantLimit && this.state.promoIsActive && this.state.promoStatus === 0) { // we also want to check here that promo.status isnt 1
      this.setState({ previewPromo: false, promoLoading: false });
      this.openSnackBar(`Cannot activate. You already have ${this.state.activePromoCount} active promotions.`);
      return false;
    }

    var dt = {
      ManagerID: this.state.ManagerID,
      MerchantID: this.state.selectedPromo.merchantID,
      MerchantPromotionID: this.state.selectedPromo.merchantPromotionID,
      Description1: this.state.Description1,
      Description2: this.state.Description2,
      Description3: this.state.Description3,
      Coupon: this.state.Coupon,
      Barcode: this.state.Barcode,
      Status: setActive,
      PromotionStart: this.state.promoStartDate.toISOString(),
      PromotionEnd: this.state.promoEndDate.toISOString(),
      PaidWeight: this.state.PaidWeight,
      AccessCount: this.state.AccessCount
    }
    var data = {
      data: dt,
      idToken: this.props.user.idToken
    }

    api.updatePromotion(data)
      .then(res => {
        if (res.status == 200) {
          //update activePromoCount & promoList
          this.setState({ sessionCounter: 0 });
          api.getMerchantPromotion({
            merchantID: this.state.selectedMerchantID,
            idToken: this.props.user.idToken
          }).then(list => {
            this.openSnackBar(`Promotion updated!`);
            let activePromos = 0;
            list.data.forEach((promo) => {
              if (promo.status === 1) {
                activePromos++;
              }
            });

            this.setState({
              PromotionList: list.data,
              activePromoCount: activePromos,
              promoLoading: false,
              promoStatus: 0,
              showPromoModal: false,
              previewPromo: false
            });
          })
        } else {
          this.openSnackBar(`Could not update promo!`)
          this.setState({
            promoLoading: false
          })
        }
      }).catch(err => {
        var errObj = {
          ApiRoute: "/api/updatePromotion",
          Page: 'Manager',
          ApiErrMsg: err,
          ApiData: data
        }
        this.setState({
          jppcAdminError: JSON.stringify(errObj),
          showErrorDialog: true
        })
        this.openSnackBar(`An unexpected error has occurred!`)
        this.setState({
          promoLoading: false
        })
      })

    this.setState({ promoLoading: false });
  }

  deactivatePromo = () => {
    this.setState({ promoLoading: true });
    let d = {
      MerchantID: this.state.selectedPromo.merchantID,
      MerchantPromotionID: this.state.selectedPromo.merchantPromotionID,
      ManagerID: this.props.user.userInfo.managerID,
      Description1: this.state.selectedPromo.description1,
      Description2: this.state.selectedPromo.description2,
      Description3: this.state.selectedPromo.description3,
      Barcode: this.state.selectedPromo.barcode,
      Coupon: this.state.selectedPromo.coupon,
      PaidWeight: this.state.selectedPromo.paidWeight,
      PromotionStart: this.state.selectedPromo.promotionStart,
      PromotionEnd: this.state.selectedPromo.promotionEnd,
      AccessCount: this.state.selectedPromo.accessCount,

      Status: 0
    };

    api.updatePromotion({
      data: d,
      idToken: this.props.user.idToken
    })
      .then(res => {

        if (res.status == 200) {
          //update activePromoCount & promoList
          this.setState({ sessionCounter: 0 });
          api.getMerchantPromotion({
            merchantID: this.state.selectedMerchantID,
            idToken: this.props.user.idToken
          }).then(list => {
            this.openSnackBar(`Promotion updated!`);
            let activePromos = 0;

            list.data.forEach((promo) => {
              if (promo.status === 1) {
                activePromos++;
              }
            });

            this.setState({
              PromotionList: list.data,
              activePromoCount: activePromos,
              promoLoading: false,
              promoStatus: 0
            });
          })
        } else {
          this.openSnackBar(`Could not update promo!`)
          this.setState({
            promoLoading: false
          })
        }
      }).catch(err => {
        var errObj = {
          ApiRoute: "/api/updatePromotion",
          Page: 'Manager',
          ApiErrMsg: err,
          ApiData: {
            data: d,
            idToken: this.props.user.idToken
          }
        }
        this.setState({
          jppcAdminError: JSON.stringify(errObj),
          showErrorDialog: true
        })
        this.openSnackBar(`An unexpected error has occurred!`)
        this.setState({
          promoLoading: false
        })
      })
  }

  deletePromo = () => {
    this.setState({ previewPromo: false, promoLoading: true });
    //First delete coupon on Firebase if any
    // console.log("this.state.selectedPromo.coupon: ", this.state.selectedPromo)
    // this.setState({ promoLoading: false });
    if (this.state.selectedPromo.coupon) {
      var data = {
        coupon: this.state.selectedPromo.coupon,
        managerID: this.state.ManagerID
      }
      firebase.deleteCoupon(data, (res) => {
        if (res.statusT) {
          this.deletePromOnJPPServer();
        } else {
          var errObj = {
            ApiRoute: "Firebase Coupon Delete",
            Page: 'Manager',
            ApiErrMsg: res.error,
            ApiData: data
          }
          this.setState({
            jppcAdminError: JSON.stringify(errObj),
            showErrorDialog: true
          });
          this.openSnackBar('Error, please try again later');
        }
      })
    } else {
      this.deletePromOnJPPServer();
    }
  }

  deletePromOnJPPServer = () => {
    api.deletePromotion({
      merchantPromotionID: this.state.selectedPromo.merchantPromotionID,
      idToken: this.props.user.idToken
    }).then(res => {
      this.setState({ sessionCounter: 0 });
      if (res.status == 200) {
        api.getMerchantPromotion({
          merchantID: this.state.selectedMerchantID,
          idToken: this.props.user.idToken
        }).then(list => {
          let activePromos = 0;

          list.data.forEach((promo) => {
            if (promo.status === 1) {
              activePromos++;
            }
          });
          this.setState({
            showPromoModal: false,
            PromotionList: list.data,
            activePromoCount: activePromos
          });
          this.removePromotion();
          this.openSnackBar("Promotion Deleted!");
        })
      }
      this.setState({ promoLoading: false });
    })
  }

  removePromotion = () => {
    var pList = this.state.PromotionList;
    this.setState({ promoLoading: true });
    pList.forEach((promo, i) => {
      if (promo.merchantPromotionID == this.state.selectedPromo.merchantPromotionID) {
        pList.splice(i, 1);
      }
    });
    this.setState({ promoLoading: false });
  }

  openSnackBar = (msg) => {
    this.setState({ snackMsg: msg, shouldSnackBarOpen: true });
  }

  handleSnackBarClose = () => {
    this.setState({ shouldSnackBarOpen: false, snackMsg: '' });

  }

  merchantSelectChange = (event) => {
    this.setState({ newPromoSelectedMerchant: event.target.value }, () => {

    });
  }

  selectMerchantView = (event) => {
    this.setState({ selectedMerchantID: event.target.value, promoLoading: true }, () => {

      api.getMerchantPromotion({
        merchantID: this.state.selectedMerchantID,
        idToken: this.props.user.idToken
      }).then(res => {
        this.setState({ sessionCounter: 0 });
        let activePromos = 0;
        res.data.forEach((promo) => {
          if (promo.status === 1) {
            activePromos++;
          }
        });

        let selectedMerchantObj = this.state.merchantList.find((merchant) => {
          return merchant.merchantID === this.state.selectedMerchantID;
        })

        this.setState({ PromotionList: res.data, activePromoCount: activePromos, selectedMerchantLimit: selectedMerchantObj.promotionLimit, promoLoading: false })
      }).catch(err => {
        var errObj = {
          ApiRoute: "/api/Promotions/",
          Page: 'Manager',
          ApiErrMsg: err,
          ApiData: {
            merchantID: this.state.selectedMerchantID,
            idToken: this.props.user.idToken
          }
        }
        this.setState({
          jppcAdminError: JSON.stringify(errObj),
          showErrorDialog: true
        })
        console.log(err)
        this.setState({ promoLoading: false })
      })
    });
  }

  createData(Day, isOpen, opening, closing) {
    return { Day, isOpen, opening, closing };
  }

  getRows = () => {
    var row = [
      this.createData('Monday', this.state.isOpenMonday, this.state.monOpen, this.state.monClose),
      this.createData('Tuesday', this.state.isOpenTuesday, this.state.tueOpen, this.state.tueClose),
      this.createData('Wednesday', this.state.isOpenWednesday, this.state.wedOpen, this.state.wedClose),
      this.createData('Thursday', this.state.isOpenThursday, this.state.thuOpen, this.state.thuClose),
      this.createData('Friday', this.state.isOpenFriday, this.state.friOpen, this.state.friClose),
      this.createData('Saturday', this.state.isOpenSaturday, this.state.satOpen, this.state.satClose),
      this.createData('Sunday', this.state.isOpenSunday, this.state.sunOpen, this.state.sunClose)
    ]

    this.setState({ rows: row });
  }

  handleOpenDayChange = (event) => {
    var val = event.target.checked;
    var name = event.target.name;

    this.setState({
      [name]: val
    });
  }

  handleTimeChange = (value) => {
    // var val = event.target.value;
    // var name = event.target.name;

    // this.setState({
    //   [name]: val
    // });
  }

  setAllDayOpenH = (value) => {
    this.setState({
      allDayOpenH: value,
      isOpenMonday: true,
      monOpen: value,
      isOpenTuesday: true,
      tueOpen: value,
      isOpenWednesday: true,
      wedOpen: value,
      isOpenThursday: true,
      thuOpen: value,
      isOpenFriday: true,
      friOpen: value,
      isOpenSaturday: true,
      satOpen: value,
      isOpenSunday: true,
      sunOpen: value
    })
  }

  setAllDayCloseH = (value) => {
    this.setState({
      allDayCloseH: value,
      isOpenMonday: true,
      monClose: value,
      isOpenTuesday: true,
      tueClose: value,
      isOpenWednesday: true,
      wedClose: value,
      isOpenThursday: true,
      thuClose: value,
      isOpenFriday: true,
      friClose: value,
      isOpenSaturday: true,
      satClose: value,
      isOpenSunday: true,
      sunClose: value
    })
  }

  setWeekDaysOpen = (value) => {
    this.setState({
      weekDayOpenH: value,
      isOpenMonday: true,
      monOpen: value,
      isOpenTuesday: true,
      tueOpen: value,
      isOpenWednesday: true,
      wedOpen: value,
      isOpenThursday: true,
      thuOpen: value,
      isOpenFriday: true,
      friOpen: value,
    })
  }

  setWeekDaysClose = (value) => {
    this.setState({
      weekDayCloseH: value,
      isOpenMonday: true,
      monClose: value,
      isOpenTuesday: true,
      tueClose: value,
      isOpenWednesday: true,
      wedClose: value,
      isOpenThursday: true,
      thuClose: value,
      isOpenFriday: true,
      friClose: value,
    })
  }

  setWeekendDaysOpen = (value) => {
    this.setState({
      weekendDayOpenH: value,
      isOpenSaturday: true,
      satOpen: value,
      isOpenSunday: true,
      sunOpen: value
    })
  }

  setWeekendDaysClose = (value) => {
    this.setState({
      weekendDayCloseH: value,
      isOpenSaturday: true,
      satClose: value,
      isOpenSunday: true,
      sunClose: value
    });
  }

  base64ToFile = (base64String, fileName) => {

    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], `${fileName}.jpg`, { type: mime })
  }

  fileToBase64 = (file) => {
    let fileRead = new FileReader();
    fileRead.readAsDataURL(file);
    fileRead.onload = () => {
      return fileRead.result;
    }

  }

  // downloadBase64File = (base64Data, fileName) => {
  //   const element = document.createElement('a');
  //   element.setAttribute('href', base64Data);
  //   element.setAttribute('download', fileName);
  //   element.style.display = 'none';
  //   document.body.appendChild(element);
  //   element.click();
  //   document.body.removeChild(element);
  // }

  handleCropping = () => {
    //if theres a staged logo, set up cropping canvas to reflect it
    //else get ready for user to upload logo
    //editLogoFile, logoUrl, and croppedLogoUrl should get set on this screen and not referenced beyond it
    this.setState({
      logoUrl: '',
      croppedLogoUrl: '',
      editLogoFile: '',
      cropImageMode: true
    })
  }

  deleteStagedLogo = () => {
    //stagedLogoFile should be cleared, rest don't matter

    this.setState({ stagedLogoFile: '' });
  }

  deleteSavedLogo = () => {
    var data = {
      logoFile: this.state.logoBackUp,
      managerID: this.state.ManagerID
    }
    firebase.deleteLogo(data, (res) => {
      if (res.statusT) {
        this.setState({ savedLogoFile: '' });
        this.openSnackBar('Logo deleted!');
      } else {
        var errObj = {
          ApiRoute: "Firebase Logo Delete",
          Page: 'Manager',
          ApiErrMsg: res.error,
          ApiData: data
        }
        this.setState({
          jppcAdminError: JSON.stringify(errObj),
          showErrorDialog: true
        });
        this.openSnackBar('Error, please try again later');
      }
    })
  }

  saveCrop = () => {

    let fileName = this.state.editLogoFile.name;

    const croppedImgFile = this.base64ToFile(this.state.croppedLogoUrl, `${(fileName).substring(0, fileName.indexOf('.'))}`)

    this.setState({
      // editLogoFile: this.state.croppedLogoUrl,
      cropImageMode: false,
      stagedLogoFile: croppedImgFile
    })

    this.openSnackBar('Crop successful!');
  }

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedLogoUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        // i dont think this name actually gets saved
        `${this.state.editMerchantName}-logo.jpeg`
      );
      this.setState({ croppedLogoUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL('image/jpeg');
    return base64Image;

  }

  handleFileUpLoadChange = (event, type) => {
    const files = event.target.files;
    if (files[0]) {
      if (type == 2) {
        if (files[0].type.split("/")[0] == "image") {
          if (files[0].size < 1000000) {
            //this is sending the image file to state:
            this.setState({ CouponFile: files[0], CouponFileErr: false, CouponFileErrMsg: '' });
          } else {
            this.setState({ CouponFile: files[0], CouponFileErr: true, CouponFileErrMsg: 'File size must not exceed 1MB' });
          }
        } else {
          this.setState({ CouponFile: files[0], CouponFileErr: true, CouponFileErrMsg: 'unsupported file type detected' });
        }
      } else {
        if (files[0].type.split("/")[0] == "image") {
          if (files[0].size < 1000000) {

            //this is sending the image file to state:
            const reader = new FileReader();
            reader.addEventListener('load', () => {
              this.setState({ logoUrl: reader.result })
            }
            );
            reader.readAsDataURL(files[0]);

            this.setState({ editLogoFile: files[0], editLogoFileErr: false, editLogoFileErrMsg: '' });
          } else {
            this.setState({ editLogoFile: files[0], editLogoFileErr: true, editLogoFileErrMsg: 'File size must not exceed 1MB' });
          }
        } else {
          this.setState({ editLogoFile: files[0], editLogoFileErr: true, editLogoFileErrMsg: 'unsupported file type detected' });
        }
      }
    }
  }

  editManager = () => {
    /* 
      opens manager modal
    */

    this.setState({
      showManagerModal: true,
      editManagerName: this.state.ManagerName,
      editManagerNameErr: false,
      editManagerEmail: this.state.ManagerEmail,
      editManagerEmailErr: false,
    });
  }

  launchMap = (address) => {
    if (address != null && address != '') {

      var Stype = this.getMobileOperatingSystem();

      if (Stype == "iOS") {
        var link = "http://maps.apple.com/?q=" + address;
        window.open(link, '_blank');
      } else {
        var link = "https://maps.google.com/?q=" + address;
        window.open(link, '_blank');
      }
    }
  }

  getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  formatWeb = (site) => {
    //if preceded by // leave as is else add
    if (site.includes('//')) {
      return site;
    } else {
      return `//${site}`;
    }
  }

  updateManagerInfo = () => {
    /* send data to api/updateManager
          {
              public int ManagerID { get; set; }
              public string ManagerName { get; set; }
              public string Email { get; set; }
              public string Password { get; set; }
      }
        Password can be empty.
    */
    //if no email return false
    if (!this.state.editManagerEmail) {
      this.setState({ editMerchantEmailErr: 'Email is required!' })
      return false;
    }

    if (this.state.editManagerEmailErr) {
      this.openSnackBar('Please review errors!');
      return false;
    }
    let editedData = {};
    editedData.ManagerID = this.state.ManagerID;
    editedData.ManagerName = this.state.editManagerName;
    editedData.Email = this.state.editManagerEmail;
    //editedData.Password = '';

    var data = {
      data: editedData,
      idToken: this.props.user.idToken
    }

    api.updateManager(data).then(res => {
      if (res.status == 200) {
        this.setState({ sessionCounter: 0 });
        this.updateManagerProps(editedData);
      } else {
        this.openSnackBar("We were not able to update the information. Please try again later. If you keep getting this error please contact us!");
        console.log("update error: ", res);
      }
    })
      .catch(err => {
        var errObj = {
          ApiRoute: "/api/updateManager",
          Page: 'Manager',
          ApiErrMsg: err,
          ApiData: data
        }
        this.setState({
          jppcAdminError: JSON.stringify(errObj),
          showErrorDialog: true
        })
        this.openSnackBar("An unexpected error has occured. Please try again later. If you keep getting this error please contact us!");
        console.log("update error: ", err)
      });
  }

  cancelEditPw = () => {
    //set edit states to null and display false
    this.setState({
      updatePwMode: false,
      editManagerPassword: null,
      editManagerPasswordErr: false,
      editManagerConfirmPw: null,
      editManagerConfirmPwErr: false
    })

  }

  updateManagerPw = () => {
    /* send data to api/updatePassword
          {
              public int ManagerID { get; set; }
              public string ManagerName { get; set; }
              public string Email { get; set; }
              public string Password { get; set; }
      }
        ManagerName, Email can be empty.
    */

    //check to see if editManagerPassword and editManagerConfirmPw match... if so send to api

    if (this.state.editManagerPassword == this.state.editManagerConfirmPw) {
      this.setState({ editManagerConfirmPwErr: false });

      let editedData = {};
      editedData.ManagerID = this.state.ManagerID;
      editedData.Password = this.state.editManagerPassword;

      var data = {
        data: editedData,
        idToken: this.props.user.idToken
      }

      api.updatePassword(data).then(res => {
        if (res.status == 200) {
          this.setState({ sessionCounter: 0 });
          this.cancelEditPw();
          this.openSnackBar("Password updated!");
        } else {
          this.openSnackBar("We were not able to update the information. Please try again later. If you keep getting this error please contact us!");
          console.log("update error: ", res);
        }
      })
        .catch(err => {
          var errObj = {
            ApiRoute: "api/updatePassword",
            Page: 'Manager',
            ApiErrMsg: err,
            ApiData: data
          }
          this.setState({
            jppcAdminError: JSON.stringify(errObj),
            showErrorDialog: true
          })
          this.openSnackBar("An unexpected error has occured. Please try again later. If you keep getting this error please contact us!");
          console.log("update error: ", err)
        });

    } else {
      this.setState({ editManagerConfirmPwErr: 'Passwords must match!' })
    }

  }

  openTimeEditor = (title, name, val) => {
    this.setState({
      selectedTime: { displayName: title, name: name },
      hour: val.split(":")[0].length > 1 ? val.split(":")[0] : '0' + val.split(":")[0],
      minute: val.split(':')[1].split(' ')[0],
      dayTime: val.split(':')[1].split(' ')[1],
      showTimePicker: true
    })
  }

  applyTimeChange = () => {
    var newTime = this.state.hour + ':' + this.state.minute + ' ' + this.state.dayTime;
    this.setState({
      [this.state.selectedTime.name]: newTime,
      showTimePicker: false
    });
    if (this.state.selectedTime.name == 'allDayOpenH') {
      this.setAllDayOpenH(newTime)
    }
    if (this.state.selectedTime.name == 'allDayCloseH') {
      this.setAllDayCloseH(newTime)
    }
    if (this.state.selectedTime.name == 'weekDayOpenH') {
      this.setWeekDaysOpen(newTime)
    }
    if (this.state.selectedTime.name == 'weekDayCloseH') {
      this.setWeekDaysClose(newTime)
    }
    if (this.state.selectedTime.name == 'weekendDayOpenH') {
      this.setWeekendDaysOpen(newTime)
    }
    if (this.state.selectedTime.name == 'weekendDayCloseH') {
      this.setWeekendDaysClose(newTime)
    }
  }

  copyErr = () => {
    var copyText = document.getElementById("jppc-error");
    copyText.select();
    document.execCommand("copy");
    this.setState({ isErrorCopied: true });
    this.openSnackBar("Error copied! Please send it to the development team!")
  }

  // ----------------------- Render ------------------------ //

  render() {

    const materialTheme = createMuiTheme({
      overrides: {
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: lightBlue.A200,
          },
        },
        MuiPickersCalendarHeader: {
          switchHeader: {
            // backgroundColor: lightBlue.A200,
            // color: "white",
          },
        },
        MuiPickersDay: {
          day: {
            color: lightBlue.A700,
          },
          daySelected: {
            backgroundColor: lightBlue["400"],
          },
          dayDisabled: {
            color: lightBlue["100"],
          },
          current: {
            color: lightBlue["900"],
          },
        },
        MuiPickersModal: {
          dialogAction: {
            color: lightBlue["400"],
          },
        },
        '& .MuiDialog-container': {
          '& MuiPaper-root': {
            '& .MuiDialogActions-root': {
              fontWeight: '900'
            }
          }
        }
      },
    });
    const { classes } = this.props;
    //Modal Transition
    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="down" ref={ref} {...props} />;
    });
    const StyledTableCell = withStyles((theme) => ({
      head: {
        backgroundColor: '#0476B5',
        color: theme.palette.common.white,
      },
      body: {
        fontSize: 14,
      },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }))(TableRow);

    const CustomChip = withStyles((theme) => ({
      colorPrimary: {
        backgroundColor: '#0476B5',
        color: '#fff',
        '&$outlined': {
          color: '#FECE00',
          borderColor: '#FECE00',
          background: 'transparent'
        },
      }
    }))(Chip);

    const CustomCheckbox = withStyles({
      root: {
        '&$checked': {
          color: '#FECE00'
        },
      },
      checked: {},
    })((props) => <Checkbox color='default' {...props} />);
    var Montemp;
    var Tutemp;
    var Wedtemp;
    var Thutemp;
    var Fritemp;
    var Sattemp;
    var Suntemp;
    var AllWtemp;
    var AllWEtemp;
    var AllDtemp;
    return (
      <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        {!this.props.user ? <Redirect to='/login' /> : null}

        {this.state.userType === 'manager' &&
          <Header
            color="transparent"
            rightLinks={<HeaderRightLinks user={this.props.user} setUser={(u) => this.props.setUser(u)} />}
            leftLinks={<HeaderLeftLinks />}
            user={this.props.user}
            setUser={(u) => this.props.setUser(u)}
            fixed
            changeColorOnScroll={{
              height: 75,
              color: "white"
            }}
          />
        }
        {/* <Header
          color="transparent"
          brand={
            <img src={findingFaberLogo} width="50px" height="50px"></img>
          }

          rightLinks={<HeaderRightLinks user={this.props.user} setUser={(u) => this.props.setUser(u)} />}
          fixed
          changeColorOnScroll={{
            height: 100,
            color: "white"
          }}
          style={{margin: '0'}}
        /> */}
        {/* <Parallax component={'span'} small 
        // image={require("assets/img/cyan-bg.png")}
        >
          <div className={classNames(classes.container, classes.paraBtn)} >
            <GridContainer component={'span'}>
              <GridItem xs={12} sm={6} lg={8} component={'span'}>
                <Button color="primary" component={'span'} style={{ marginRight: '15px' }}>Export active Promotion</Button>
                  <Button color="primary" component={'span'} style={{ marginRight: '15px' }}>Report Promotion History</Button>
              </GridItem>

              <GridItem className={classes.gridItem} xs={12} sm={6} lg={4} component={'span'} style={{ display: 'flex' }}>
                
                <Button color="primary" component={'span'} onClick={this.newMerchant}>New Merchant</Button>
                
              </GridItem>
            </GridContainer>
          </div>
        </Parallax> */}

        <div className={classNames(classes.main)} style={{ display: 'flex', flexDirection: 'column', paddingTop: '120px' }}>
          <img src={require('assets/img/FF_map_only.png')}
            style={{ position: 'fixed', zIndex: '-10', width: '100%', bottom: '0', opacity: '5%' }}
          />

          <div>
            <div className={classNames(classes.container)} style={{ maxWidth: '100%' }}>
              <GridContainer component={'span'}>
                <GridItem component={'span'} xs={12} sm={12} md={12} style={{ padding: '0px' }}>

                  <Button className={classes.hide430} color="primary" component={'span'} onClick={() => this.editManager()} style={{ position: 'absolute', right: '15px', top: '15px', zIndex: '4' }} aria-label='edit account info'>Edit Account Info</Button>
                  {/* <IconButton variant='filled' className={classes.hide430} color='primary' component={'span'} onClick={() => this.editManager()} style={{ position: 'absolute', right: '0', top: '15px', zIndex: '4'}} aria-label='edit account info'>
                      <AccountCircleIcon/>
                  </IconButton> */}

                  <Fab className={classes.show430} color="primary" size="medium" component="span" onClick={() => this.editManager()} aria-label="edit account info" variant="extended" style={{ backgroundColor: '#0476B5', position: 'absolute', right: '3px', top: '12px', zIndex: '4', padding: '1px' }}>
                    <PersonIcon />
                  </Fab>

                  <NavPills
                    color="primary"
                    component={'span'}
                    tabs={[
                      {
                        tabButton: "Business Info",
                        tabContent: (
                          <span
                            style={{ boxShadow: '15px -15px 20px 0px rgba(158,158,158,.9)' }}
                          >
                            {this.state.MerchantList.map((merchant, index) => {

                              return (
                                <ExpansionPanel key={merchant.merchantName + '-' + index}
                                  expanded={this.state.expanded === 'panel' + index}
                                  // expanded={true}
                                  onChange={this.handlePanelChange('panel' + index)}
                                  className={classes.expansionRes}
                                  style={{ background: 'none', margin: '0' }}
                                >
                                  <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header" style={{ width: '100%', margin: '0', display: 'flex', justifyContent: 'center' }}>
                                    <Typography component={'span'} className={classes.heading} style={{ width: '100%' }}>
                                      <span style={{ fontWeight: '600', width: '100%', paddingRight: '15px', fontSize: '1.5rem', display: 'flex', justifyContent: 'center', marginLeft: '2%' }}>
                                        {merchant.merchantName}
                                        {/* <span style={{ float: 'left' }}>{merchant.merchantName}</span>
                                          <span style={{ float: 'right' }}>
                                            click to expand
                                          </span> */}
                                      </span>
                                    </Typography>
                                  </ExpansionPanelSummary>
                                  <ExpansionPanelDetails style={{ padding: '0 0 15px 0', display: 'flex', flexDirection: 'column' }}
                                  // className={classes.mobilePadless}
                                  >

                                    {/* <div> */}
                                    {/* {(this.state.expanded === 'panel' + index) ? (
                                        <span style={{ position: 'absolute', top: '20px', right: '50px', cursor: 'pointer', width: '30px', height: '30px', borderRadius: '50%', backgroundColor: '#0476B5', textAlign: 'center', paddingTop: '2px', color: 'white' }} onClick={() => this.editMerchant(merchant)}>
                                          <EditIcon />
                                        </span>
                                      ) : null } */}
                                    <div style={{ display: 'flex', justifyContent: 'center', padding: '0 15px', marginTop: '-5px' }}>
                                      <Button className={classes.smFullWidth} color='warning' style={{ fontSize: '16px', fontWeight: 'bold', color: '#252326' }} onClick={() => this.editMerchant(merchant)}>
                                        Edit Business Info
                                      </Button>
                                    </div>
                                    <span id='bizWrapper'
                                    // style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                                    >

                                      <div id='bizInfo' className={classes.cardRes}>
                                        <h5 style={{ margin: '15px 0px 5px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                          {/* <PermContactCalendarIcon style={{ marginRight: '10px' }} />  */}
                                          Business Info</h5>
                                        <Card
                                          style={{
                                            background: 'rgb(255,255,255)',
                                            background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,252 ,238,1) 100%)'
                                          }}
                                        // className={classes.cardRes}
                                        >
                                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                            <div style={{ width: '100%' }}>
                                              <h6 style={{ fontWeight: '700' }}>Contact</h6>
                                              <span style={{ display: 'flex', alignItems: 'center', margin: "7px" }}><AccountCircleIcon style={{ marginRight: '10px' }} /> {merchant.contactName}</span>
                                              <span style={{ display: 'flex', alignItems: 'center', margin: "7px" }}><PhoneIcon style={{ marginRight: '10px' }} /> {merchant.phone}</span>
                                              <span style={{ display: 'flex', alignItems: 'center', margin: "7px" }}><EmailIcon style={{ marginRight: '10px' }} /> {merchant.merchantEmail}</span>

                                              {/* <h4>Address</h4> */}
                                              <span style={{ display: 'flex', alignItems: 'center', margin: "7px" }}><MapIcon style={{ marginRight: '10px' }} /> {merchant.address1} {merchant.city} {merchant.state} {merchant.zip}</span>

                                              {/* <h4>Website</h4> */}
                                              <span style={{ display: 'flex', alignItems: 'center', margin: "7px" }}><LanguageIcon style={{ marginRight: '10px' }} /> {merchant.website}</span>
                                            </div>
                                            <div style={{ width: '100%' }}>
                                              <h6 style={{ fontWeight: '700' }}>Category</h6>
                                              {(merchant.merchantSubcategory && !this.state.isWrongCat) ? (
                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                  {merchant.merchantSubcategory.categoryName} - {merchant.merchantSubcategory.subcategoryName}
                                                </span>) : (
                                                  <span style={{ display: 'flex', alignItems: 'center', color: 'red', fontSize: '0.7.5rem' }}>
                                                    Please select and save a category under <span style={{ marginLeft: '10px', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => this.editMerchant(merchant)}> "Business Info" </span>
                                                  </span>)}
                                            </div>

                                          </CardContent>
                                        </Card>
                                      </div>

                                      <div id='bizHours' className={classes.cardRes}>
                                        <h5 style={{ margin: '15px 0px 5px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                          Business Hours
                                          </h5>
                                        <Card
                                          style={{
                                            background: 'rgb(255,255,255)',
                                            background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,252 ,238,1) 100%)'
                                          }}>
                                          <CardContent style={{ display: 'flex', flexDirection: 'column' }}>

                                            <span style={{ maxWidth: '350px' }}>
                                              {merchant.sunClose && merchant.sunClose.split('-')[1] && (
                                                <span style={{ width: '100%' }}>
                                                  <span style={{ fontWeight: '700', marginRight: '22px' }}>Note:</span> {merchant.sunClose.split('-').slice(1, merchant.sunClose.split('-').length).join('-')}
                                                  <hr />
                                                </span>
                                              )}
                                              <span style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '10px' }}>
                                                <h6 style={{ fontWeight: '700', marginRight: '22px' }}>Monday</h6>
                                                <div>
                                                  <span className={merchant.monOpen == 'Closed' ? 'closed-hour' : null}><AlarmIcon style={{ margin: '0 10px', verticalAlign: 'middle' }} /> {merchant.monOpen} </span>
                                                  <span className={merchant.monClose == 'Closed' ? 'closed-hour' : null}><AlarmOffIcon style={{ margin: '0 10px', verticalAlign: 'middle' }} /> {merchant.monClose} </span>
                                                </div>
                                              </span>
                                              <span style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '10px' }}>
                                                <h6 style={{ fontWeight: '700', marginRight: '20px' }}>Tuesday</h6>
                                                <div>
                                                  <span className={merchant.tueOpen == 'Closed' ? 'closed-hour' : null}><AlarmIcon style={{ margin: '0 10px', verticalAlign: 'middle' }} /> {merchant.tueOpen} </span>
                                                  <span className={merchant.tueClose == 'Closed' ? 'closed-hour' : null}><AlarmOffIcon style={{ margin: '0 10px', verticalAlign: 'middle' }} /> {merchant.tueClose} </span>
                                                </div>
                                              </span>
                                              <span style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '10px' }}>
                                                <h6 style={{ fontWeight: '700' }}>Wednesday</h6>
                                                <div>
                                                  <span className={merchant.wedOpen == 'Closed' ? 'closed-hour' : null}><AlarmIcon style={{ margin: '0 10px', verticalAlign: 'middle' }} /> {merchant.wedOpen} </span>
                                                  <span className={merchant.wedClose == 'Closed' ? 'closed-hour' : null}><AlarmOffIcon style={{ margin: '0 10px', verticalAlign: 'middle' }} /> {merchant.wedClose} </span>
                                                </div>
                                              </span>
                                              <span style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '10px' }}>
                                                <h6 style={{ fontWeight: '700', marginRight: '10px' }}>Thursday</h6>
                                                <div>
                                                  <span className={merchant.thuOpen == 'Closed' ? 'closed-hour' : null}><AlarmIcon style={{ margin: '0 10px', verticalAlign: 'middle' }} /> {merchant.thuOpen} </span>
                                                  <span className={merchant.thuClose == 'Closed' ? 'closed-hour' : null}><AlarmOffIcon style={{ margin: '0 10px', verticalAlign: 'middle' }} /> {merchant.thuClose} </span>
                                                </div>
                                              </span>
                                              <span style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '10px' }}>
                                                <h6 style={{ fontWeight: '700', marginRight: '31px' }}>Friday</h6>
                                                <div>
                                                  <span className={merchant.friOpen == 'Closed' ? 'closed-hour' : null}><AlarmIcon style={{ margin: '0 10px', verticalAlign: 'middle' }} /> {merchant.friOpen} </span>
                                                  <span className={merchant.friClose == 'Closed' ? 'closed-hour' : null}><AlarmOffIcon style={{ margin: '0 10px', verticalAlign: 'middle' }} /> {merchant.friClose} </span>
                                                </div>
                                              </span>
                                              <span style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '10px' }}>
                                                <h6 style={{ fontWeight: '700', marginRight: '11px' }}>Saturday</h6>
                                                <div>
                                                  <span className={merchant.satOpen == 'Closed' ? 'closed-hour' : null}><AlarmIcon style={{ margin: '0 10px', verticalAlign: 'middle' }} /> {merchant.satOpen} </span>
                                                  <span className={merchant.satClose == 'Closed' ? 'closed-hour' : null}><AlarmOffIcon style={{ margin: '0 10px', verticalAlign: 'middle' }} /> {merchant.satClose} </span>
                                                </div>
                                              </span>
                                              <span style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '10px' }}>
                                                <h6 style={{ fontWeight: '700', marginRight: '25px' }}>Sunday</h6>
                                                <div>
                                                  <span className={merchant.sunOpen == 'Closed' ? 'closed-hour' : null}><AlarmIcon style={{ margin: '0 10px', verticalAlign: 'middle' }} /> {merchant.sunOpen} </span>
                                                  <span className={merchant.sunClose && merchant.sunClose.indexOf('Closed-') > -1 ? 'closed-hour' : null}><AlarmOffIcon style={{ margin: '0 10px', verticalAlign: 'middle' }} /> {merchant.sunClose ? merchant.sunClose.split('-')[0] : merchant.sunClose} </span>
                                                </div>
                                              </span>
                                            </span>
                                          </CardContent>
                                        </Card>
                                      </div>

                                      <div id='bizLogo' className={classes.cardRes}>
                                        <h5 style={{ margin: '15px 0px 5px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>Logo</h5>
                                        <Card style={{
                                          background: 'rgb(255,255,255)',
                                          background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,252 ,238,1) 100%)'
                                        }}>
                                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                            <div style={{ width: '100%' }}>
                                              <img src={merchant.logoFile || noLogo} style={{ maxHeight: '100%', maxWidth: '100%' }}></img>
                                            </div>

                                          </CardContent>
                                        </Card>
                                      </div>

                                      <div id='bizOrgs' className={classes.cardRes}>
                                        <h5 style={{ margin: '15px 0px 5px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                          Affiliated Organizations
                                          </h5>
                                        <Card
                                          style={{
                                            background: 'rgb(255,255,255)',
                                            background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,252 ,238,1) 100%)'
                                          }}>
                                          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                            <Table className={classes.table} aria-label="affiliated orgs">
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell style={{ fontWeight: '600' }}>Name</TableCell>
                                                  <TableCell>City</TableCell>
                                                  <TableCell>State</TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {merchant.merchantOrganizationList.map((org) => (
                                                  <TableRow key={org.merchantOrganizationID}>
                                                    <StyledTableCell component="th" scope="row">
                                                      {org.organizationName}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">{org.city ? org.city : ''}</StyledTableCell>
                                                    <StyledTableCell align="center">{org.state ? org.state : ''}</StyledTableCell>
                                                  </TableRow>
                                                ))}
                                              </TableBody>
                                            </Table>
                                          </CardContent>
                                        </Card>
                                      </div>

                                    </span>
                                    {/* </div> */}
                                  </ExpansionPanelDetails>
                                </ExpansionPanel>
                              )
                              // }
                            })}
                          </span>
                        )
                      },
                      {
                        tabButton: "Promotions",
                        tabContent: (
                          <span>
                            <div className={classes.rowSmCol} style={{ margin: '25px 25px 0', justifyContent: 'space-between' }}>
                              <Button style={{ margin: '0', fontSize: '16px', fontWeight: 'bold', color: '#252326' }} className={classNames(classes.smFullWidth, classes.sm2)} color="warning" component={'span'} onClick={() => { this.clearPromoDialog(); this.setState({ showPromoModal: true, isDetailPromoDialog: false }) }}>New Promotion</Button>
                              <div className={classes.sm1} style={{
                                display: 'flex', flexDirection: 'column',
                                alignItems: 'flex-end'
                              }}>
                                {/* if there are multiple merchants, show merchant select */}
                                {this.state.MerchantList.length > 1 === true ? (
                                  <FormControl style={{ margin: '', minWidth: '250px' }}>
                                    <InputLabel id='view-promos-label'>Select Business</InputLabel>
                                    <Select labelId='view-promos-label' value={this.state.selectedMerchantID} id="viewmerchantpromoselect" onChange={this.selectMerchantView}>
                                      {this.state.MerchantList.map((merchant, index) => {
                                        return (
                                          <MenuItem key={merchant.merchantName + "-" + index} value={merchant.merchantID}>{merchant.merchantName}</MenuItem>
                                        )
                                      })}
                                    </Select>
                                  </FormControl>
                                ) : (
                                    <span style={{ fontSize: '1.5rem' }}>
                                      {this.state.newPromoSelectedMerchantName}
                                    </span>
                                  )
                                }
                                <Chip
                                  style={{ marginTop: '5px' }} label={`${this.state.activePromoCount} / ${this.state.selectedMerchantLimit} Active Promotions`}>

                                </Chip>
                              </div>
                            </div>
                            {!this.state.promoLoading ? (
                              <span>


                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                                  {this.state.PromotionList.map((promo, index) => {

                                    return (
                                      <div
                                        className={classes.promoCardDiv}
                                        style={{ margin: '25px' }}
                                        // className={classes.cardRes} 
                                        key={'promo-' + index}>
                                        {promo.status ? (
                                          <CustomChip size='small' style={{ marginBottom: '10px' }} label={`Active`}
                                            // icon={<VisibilityIcon />} 
                                            color='primary'></CustomChip>
                                        ) : (
                                            <Chip size='small' style={{ marginBottom: '10px' }} label='Inactive'
                                              //  icon={<VisibilityOffIcon />} 
                                              variant='outline'></Chip>
                                          )}
                                        <Card style={{
                                          // width: '95%', margin: '7px auto', minWidth: '250px',
                                          background: 'rgb(255,255,255)',
                                          background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,252 ,238,1) 100%)',
                                          boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.3), 0px 1px 1px 0px rgba(0,0,0,0.3), 0px 1px 3px 0px rgba(0,0,0,0.3)'
                                        }}>
                                          <CardContent style={{ paddingBottom: '0', padding: '16px' }}>

                                            <h4 className='ellipses3' style={{ textAlign: 'center', textTransform: 'uppercase', fontWeight: 'bold' }}>{promo.description1}</h4>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '25px' }}>
                                              {/* <img src={require('assets/img/FF linear logo 2.png')} style={{ width: '30%' }} /> */}
                                              <span
                                                style={{ fontFamily: "'EB Garamond', serif", fontWeight: 'bold', cursor: 'pointer', color: '#0476B5' }}
                                                color='primary'
                                                component="button"
                                                variant="body2"
                                                onClick={() => this.openPromoDetail(promo)}
                                              >
                                                View Details/Edit
                                            </span>
                                            </div>

                                          </CardContent>
                                        </Card>
                                      </div>
                                    )
                                  })}
                                </div>
                              </span>

                            ) : <CircularProgress />}
                          </span>
                        )
                      }
                    ]}
                  />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <Footer />

        {/* Merchant Dialog */}
        {this.state.showMerchantModal ? (
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.showMerchantModal,
            }}
            className={classNames(classes.resDia, classes.editMerchant)}
            open={this.state.showMerchantModal}
            keepMounted
            onClose={() => this.setState({ showMerchantModal: false, cropImageMode: false })}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description">

            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
              style={{ padding: '0 0 16px 0', margin: '16px 24px 0', borderBottom: '1px solid #252326' }}
            >
              <IconButton
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => { this.setState({ showMerchantModal: false, cropImageMode: false }) }}>

                <Close className={classes.modalClose} />
              </IconButton>

              <h3 className={classes.modalTitle} style={{ margin: '0' }}>{this.state.cropImageMode ? 'Adjust Logo' : 'Edit Business Info'}</h3>
            </DialogTitle>

            {/* Crop Image */}
            <DialogContent id='cropScreen' style={{ display: `${this.state.cropImageMode ? 'block' : 'none'}`, paddingTop: '16px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                // style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <label htmlFor="upload-photo" >
                    <input style={{ display: 'none' }} id="upload-photo" name="upload-photo" type="file" accept="image/*, image/heic, image/heif" onChange={(e) => this.handleFileUpLoadChange(e, 1)} />

                    {!this.state.editLogoFileErr ? (
                      <Fab size="medium" component="span" aria-label="add" variant="extended" style={{ backgroundColor: '#FECE00', borderRadius: '5px' }}>
                        {this.state.editLogoFile.name ? <CheckCircleIcon style={{ fontWeight: 'bold', color: '#00784A', marginRight: '7px' }} /> : <AddPhotoAlternateIcon style={{ marginRight: '7px' }} />} {this.state.editLogoFile.name || 'Upload New Logo'}
                      </Fab>
                    ) : (
                        <span >
                          <Fab size="medium" component="span" aria-label="add" variant="extended" style={{ backgroundColor: '#FECE00', borderRadius: '5px' }}>
                            <BlockIcon style={{ color: 'red', marginRight: '7px' }} /> {this.state.editLogoFile.name}
                          </Fab>
                          <span style={{ color: 'red', fontSize: '0.8rem', display: 'block', textAlign: 'end' }}>
                            {this.state.editLogoFileErrMsg}
                          </span>
                        </span>
                      )}
                  </label>
                  {this.state.croppedLogoUrl && (
                    <div className='cropPreview' style={{ display: 'flex', flexDirection: 'column' }}>

                      <img
                        style={{ width: '150px', height: '150px' }}
                        alt="Crop"
                        src={this.state.croppedLogoUrl}

                      />

                      <p style={{ margin: '0' }}>preview</p>
                    </div>
                  )}

                </div>

                {this.state.logoUrl && (
                  <ReactCrop
                    style={{ width: 'fit-content' }}

                    src={this.state.logoUrl}
                    crop={this.state.crop}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                )}


              </div>

            </DialogContent>

            {/* Edit Merchant */}
            <DialogContent id="modal-slide-description" className={classes.modalBody} style={{ display: `${this.state.cropImageMode ? 'none' : 'block'}` }}>

              <form className={classes.root} autoComplete="off">
                <span>
                  <h4 style={{ border: '0', margin: '0' }}> Business Info</h4>
                  <div style={{ margin: "7px 0px" }}>
                    <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                      <TextField error={this.state.editMerchantNameErr ? true : false} helperText={this.state.editMerchantNameErr ? this.state.editMerchantNameErr : null} name="editMerchantName" onFocus={() => this.setState({ focusedInput: "MerchantName" })} label="Business Name" value={this.state.editMerchantName} onChange={this.handleInputChange} variant="filled" />
                    </span>

                    <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px 0 15px' }}>

                      <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                        {this.state.savedLogoFile ?
                          <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 15px 0 0' }}>
                            <h4 style={{ border: 'none', margin: '0' }}>Current Logo</h4>
                            <div style={{ width: '200px' }}>
                              <img src={this.state.savedLogoFile || noLogo} style={{ border: '1px solid #3c4858', borderRadius: '5px', maxHeight: '100%', maxWidth: '100%' }} />
                            </div>
                            {!this.state.stagedLogoFile ?
                              <Fab size="medium" component="span" aria-label="delete current logo" variant="extended" style={{ color: 'red', margin: '5px 0 0 0', borderRadius: '5px' }} onClick={() => this.setState({ shouldRemoveLogo: true, logoBackUp: this.state.savedLogoFile }, () => this.setState({ savedLogoFile: null }))}>
                                Delete <DeleteIcon />
                              </Fab> : ''}
                          </span>
                          : ''}

                        {this.state.stagedLogoFile ?
                          <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0 15px 0 0' }}>
                            <h4 style={{ border: 'none', margin: '0' }}>New Logo</h4>
                            <div style={{ width: '200px' }}>
                              <img src={this.state.croppedLogoUrl || noLogo} style={{ border: '1px solid #3c4858', borderRadius: '5px', maxHeight: '100%', maxWidth: '100%' }} />
                            </div>
                            <Fab size="medium" component="span" aria-label="delete new logo" variant="extended" style={{ color: 'red', margin: '5px 0 0 0', borderRadius: '5px' }} onClick={this.deleteStagedLogo}>
                              Delete <DeleteIcon />
                            </Fab>
                          </span>
                          : ''}
                      </span>
                      {!this.state.savedLogoFile ? (
                        <Fab size="medium" component="span" aria-label="upload logo" variant="extended" style={{ backgroundColor: '#FECE00', padding: '25px 10px', height: '56px', borderRadius: '5px' }} onClick={this.handleCropping}>
                          <AddPhotoAlternateIcon style={{ marginRight: '7px' }} />
                        Add Logo
                        </Fab>) : null}
                    </span>
                    {this.state.shouldRemoveLogo ? (
                      <span style={{ color: 'red', marginTop: '15px', fontSize: '0.9rem', fontStyle: 'italic' }}>
                        Remember to click on "Save" down below to save your changes!
                      </span>
                    ) : null}

                  </div>
                  <h4>Category</h4>
                  {this.state.isWrongCat ? (
                    <span style={{ color: 'red', fontSize: '0.8rem' }}>Please select your category/subcategory and click save.</span>
                  ) : null}
                  <div style={{ margin: '7px 0px' }}>
                    {/* <span>
                    LinkedID: {this.state.linkCatID}
                    Category: {this.state.categoryID}
                    Subcategory: {this.state.subcategoryID}
                    </span> */}
                    <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                      <FormControl
                        // style={{ width: '275px' }}
                        variant="filled"
                        className={classes.formControl}
                        required
                        error={this.state.selectedCatErr ? true : false}
                      >
                        <InputLabel id="catSelectLabel">Category</InputLabel>
                        <Select
                          required
                          labelId="catSelectLabel"
                          id="catSelect"
                          value={this.state.selectedCat}
                          onChange={(e) => { this.setState({ selectedCat: e.target.value, selectedSubCat: '' }) }}
                        >
                          <MenuItem style={{ display: 'none' }} disabled value='' />
                          {this.state.catOptions.map(cat => {
                            return <MenuItem key={cat.categoryID} value={cat}>{cat.categoryName}</MenuItem>
                          })}
                        </Select>
                        {this.state.selectedCatErr ? <FormHelperText>{this.state.selectedCatErr}</FormHelperText> : null}

                      </FormControl>
                    </span>
                    {this.state.selectedCat &&
                      <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                        <FormControl
                          // style={{ width: '275px' }}
                          variant="filled"
                          className={classes.formControl}
                          required
                          error={this.state.selectedSubCatErr ? true : false}
                        >
                          <InputLabel id="subCatSelectLabel">Subcategory</InputLabel>
                          <Select
                            required
                            labelId="subCatSelectLabel"
                            id="subCatSelect"
                            value={this.state.selectedSubCat}
                            onChange={(e) => { this.setState({ selectedSubCat: e.target.value }) }}
                          >
                            <MenuItem style={{ display: 'none' }} disabled value='' />
                            {this.state.subCatOptions.map(subCat => {
                              if (subCat.categoryID === this.state.selectedCat.categoryID) {
                                return <MenuItem key={subCat.subCategoryID} value={subCat}>{subCat.subCategoryName}</MenuItem>
                              }
                            })}
                          </Select>
                          {this.state.selectedSubCatErr ? <FormHelperText>{this.state.selectedSubCatErr}</FormHelperText> : null}

                        </FormControl>
                      </span>
                    }


                  </div>
                  <h4 style={{ marginBottom: '0px' }}>Address</h4>
                  <Typography variant='caption'>Required so users can find you on the map!</Typography>
                  <div style={{ margin: "7px 0px" }}>
                    <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                      <TextField required error={this.state.editAddress1Err ? true : false} helperText={this.state.editAddress1Err ? this.state.editAddress1Err : null} name="editAddress1" onFocus={() => this.setState({ focusedInput: "address1" })} label="Address" value={this.state.editAddress1} onChange={this.handleInputChange} variant="filled" />
                    </span>
                    <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                      <TextField required error={this.state.editCityErr ? true : false} helperText={this.state.editCityErr ? this.state.editCityErr : null} name="editCity" onFocus={() => this.setState({ focusedInput: "city" })} label="City" value={this.state.editCity} onChange={this.handleInputChange} variant="filled" />
                    </span>
                  </div>
                  <div style={{ margin: "7px 0px" }}>
                    <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                      <TextField required error={this.state.editStateErr ? true : false} helperText={this.state.editStateErr ? 'Invalid Entry' : null} name="editState" onFocus={() => this.setState({ focusedInput: "state" })} label="State" value={this.state.editState} onChange={this.handleInputChange} variant="filled" />
                    </span>
                    <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                      <TextField required error={this.state.editZipErr ? true : false} helperText={this.state.editZipErr ? this.state.editZipErr : null} name="editZip" onFocus={() => this.setState({ focusedInput: "zip" })} label="Zip Code" value={this.state.editZip} onChange={this.handleInputChange} variant="filled" />
                    </span>
                  </div>
                  <h4>Public Contact Info</h4>
                  <div style={{ margin: "7px 0px" }}>
                    <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                      <TextField error={this.state.editContactNameErr ? true : false} helperText={this.state.editContactNameErr ? this.state.editContactNameErr : null} name="editContactName" onFocus={() => this.setState({ focusedInput: "merchantContactName" })} label="Contact Name" value={this.state.editContactName} onChange={this.handleInputChange} variant="filled" />
                    </span>
                    <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                      <TextField error={this.state.editPhoneErr ? true : false} helperText={this.state.editPhoneErr ? this.state.editPhoneErr : null} name="editPhone" onFocus={() => this.setState({ focusedInput: "merchantPhone" })} label="Phone" value={this.state.editPhone} onChange={this.handleInputChange} variant="filled" />
                    </span>
                  </div>
                  <div style={{ margin: "7px 0px" }}>
                    <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                      <TextField error={this.state.editMerchantEmailErr ? true : false} helperText={this.state.editMerchantEmailErr ? this.state.editMerchantEmailErr : null} name="editMerchantEmail" onFocus={() => this.setState({ focusedInput: "MerchantEmail" })} label="Business Email" value={this.state.editMerchantEmail} onChange={this.handleInputChange} variant="filled" />
                    </span>
                    <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                      <TextField error={this.state.editWebsiteErr ? true : false} helperText={this.state.editWebsiteErr ? this.state.editWebsiteErr : 'e.g.: www.yourSite.com'} name="editWebsite" onFocus={() => this.setState({ focusedInput: "website" })} label="Website" value={this.state.editWebsite} onChange={this.handleInputChange} variant="filled"
                      // InputProps={{
                      //   startAdornment: <InputAdornment position="start">http://</InputAdornment>
                      // }} 
                      />
                    </span>
                    {/* <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                            <TextField error={this.state.editLogoFileErr ? true : false} helperText={this.state.editLogoFileErr ? "Invalid Entry" : null} name="editLogoFile" onFocus={() => this.setState({ focusedInput: "logo" })} label="Logo" value={this.state.editLogoFile} onChange={this.handleInputChange} variant="filled" />
                          </span> */}

                  </div>

                  <h4>Affiliated Organizations</h4>
                  <div style={{ margin: '7px 0px' }}>
                    <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                      <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="affiliated orgs">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Organization Name</StyledTableCell>
                              <StyledTableCell align="center">City</StyledTableCell>
                              <StyledTableCell align="center">State</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.orgList.map((org) => (
                              <StyledTableRow key={org.merchantOrganizationID}>
                                <StyledTableCell component="th" scope="row">
                                  {org.organizationName}
                                </StyledTableCell>
                                <StyledTableCell align="center">{org.city ? org.city : ''}</StyledTableCell>
                                <StyledTableCell align="center">{org.state ? org.state : ''}</StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </span>
                  </div>
                  <h4>Business Hours</h4>
                  <TextField variant="filled" label="Note about business Hours" helperText="e.g.: By appointment..." name='businessHoursNote' value={this.state.businessHoursNote} onChange={this.handleInputChange} style={{ margin: '15px 0', maxWidth: 'unset', minWidth: '325px' }} />
                  <span className={classes.hoursTable}>
                    <NavPills
                      color="primary"
                      component={'span'}
                      className="business-hours"
                      tabs={[
                        {
                          tabButton: "Set Day By Day",
                          tabContent: (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="customized table">
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell>Day</StyledTableCell>
                                      <StyledTableCell>Open</StyledTableCell>
                                      <StyledTableCell>Opening</StyledTableCell>
                                      <StyledTableCell>Closing</StyledTableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {/* Monday */}
                                    <StyledTableRow>
                                      <StyledTableCell>Mon</StyledTableCell>
                                      <StyledTableCell>
                                        <CustomCheckbox checked={this.state.isOpenMonday} onChange={this.handleOpenDayChange} name="isOpenMonday" />
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {this.state.isOpenMonday ? (
                                          <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Monday Opening Hour', 'monOpen', this.state.monOpen)}>
                                            <span style={{ fontSize: '1.1rem' }}>{this.state.monOpen}</span>
                                            <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                          </span>) : (<span>Closed</span>)}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {this.state.isOpenMonday ? (
                                          <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Monday Closing Hour', 'monClose', this.state.monClose)}>
                                            <span style={{ fontSize: '1.1rem' }}>{this.state.monClose}</span>
                                            <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                          </span>) : (<span>Closed</span>)}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                    {/* Tuesday */}
                                    <StyledTableRow>
                                      <StyledTableCell>Tue</StyledTableCell>
                                      <StyledTableCell>
                                        <CustomCheckbox checked={this.state.isOpenTuesday} onChange={this.handleOpenDayChange} name="isOpenTuesday" />
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {this.state.isOpenTuesday ? (
                                          <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Tuesday Opening Hour', 'tueOpen', this.state.tueOpen)}>
                                            <span style={{ fontSize: '1.1rem' }}>{this.state.tueOpen}</span>
                                            <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                          </span>) : (<span>Closed</span>)}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {this.state.isOpenTuesday ? (
                                          <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Tuesday Closing Hour', 'tueClose', this.state.tueClose)}>
                                            <span style={{ fontSize: '1.1rem' }}>{this.state.tueClose}</span>
                                            <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                          </span>) : (<span>Closed</span>)}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                    {/* Wednesday */}
                                    <StyledTableRow>
                                      <StyledTableCell>Wed</StyledTableCell>
                                      <StyledTableCell>
                                        <CustomCheckbox checked={this.state.isOpenWednesday} onChange={this.handleOpenDayChange} name="isOpenWednesday" />
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {this.state.isOpenWednesday ? (
                                          <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Wednesday Opening Hour', 'wedOpen', this.state.wedOpen)}>
                                            <span style={{ fontSize: '1.1rem' }}>{this.state.wedOpen}</span>
                                            <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                          </span>) : (<span>Closed</span>)}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {this.state.isOpenWednesday ? (
                                          <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Wednesday Closing Hour', 'wedClose', this.state.wedClose)}>
                                            <span style={{ fontSize: '1.1rem' }}>{this.state.wedClose}</span>
                                            <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                          </span>) : (<span>Closed</span>)}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                    {/* Thursday */}
                                    <StyledTableRow>
                                      <StyledTableCell>Thu</StyledTableCell>
                                      <StyledTableCell>
                                        <CustomCheckbox checked={this.state.isOpenThursday} onChange={this.handleOpenDayChange} name="isOpenThursday" />
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {this.state.isOpenThursday ? (
                                          <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Thursday Opening Hour', 'thuOpen', this.state.thuOpen)}>
                                            <span style={{ fontSize: '1.1rem' }}>{this.state.thuOpen}</span>
                                            <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                          </span>) : (<span>Closed</span>)}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {this.state.isOpenThursday ? (
                                          <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Thursday Closing Hour', 'thuClose', this.state.thuClose)}>
                                            <span style={{ fontSize: '1.1rem' }}>{this.state.thuClose}</span>
                                            <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                          </span>) : (<span>Closed</span>)}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                    {/* Friday */}
                                    <StyledTableRow>
                                      <StyledTableCell>Fri</StyledTableCell>
                                      <StyledTableCell>
                                        <CustomCheckbox checked={this.state.isOpenFriday} onChange={this.handleOpenDayChange} name="isOpenFriday" />
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {this.state.isOpenFriday ? (
                                          <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Friday Opening Hour', 'friOpen', this.state.friOpen)}>
                                            <span style={{ fontSize: '1.1rem' }}>{this.state.friOpen}</span>
                                            <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                          </span>) : (<span>Closed</span>)}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {this.state.isOpenFriday ? (
                                          <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Friday Closing Hour', 'friClose', this.state.friClose)}>
                                            <span style={{ fontSize: '1.1rem' }}>{this.state.friClose}</span>
                                            <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                          </span>) : (<span>Closed</span>)}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                    {/* Saturday */}
                                    <StyledTableRow>
                                      <StyledTableCell>Sat</StyledTableCell>
                                      <StyledTableCell>
                                        <CustomCheckbox checked={this.state.isOpenSaturday} onChange={this.handleOpenDayChange} name="isOpenSaturday" />
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {this.state.isOpenSaturday ? (
                                          <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Saturday Opening Hour', 'satOpen', this.state.satOpen)}>
                                            <span style={{ fontSize: '1.1rem' }}>{this.state.satOpen}</span>
                                            <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                          </span>) : (<span>Closed</span>)}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {this.state.isOpenSaturday ? (
                                          <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Saturday Closing Hour', 'satClose', this.state.satClose)}>
                                            <span style={{ fontSize: '1.1rem' }}>{this.state.satClose}</span>
                                            <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                          </span>) : (<span>Closed</span>)}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                    {/* Sunday */}
                                    <StyledTableRow>
                                      <StyledTableCell>Sun</StyledTableCell>
                                      <StyledTableCell>
                                        <CustomCheckbox checked={this.state.isOpenSunday} onChange={this.handleOpenDayChange} name="isOpenSunday" />
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {this.state.isOpenSunday ? (
                                          <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Sunday Opening Hour', 'sunOpen', this.state.sunOpen)}>
                                            <span style={{ fontSize: '1.1rem' }}>{this.state.sunOpen}</span>
                                            <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                          </span>) : (<span>Closed</span>)}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {this.state.isOpenSunday ? (
                                          <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Sunday Closing Hour', 'sunClose', this.state.sunClose)}>
                                            <span style={{ fontSize: '1.1rem' }}>{this.state.sunClose}</span>
                                            <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                          </span>) : (<span>Closed</span>)}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </MuiPickersUtilsProvider>
                          )
                        },
                        {
                          tabButton: "Set all week Days In One",
                          tabContent: (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', textAlign: 'center' }}>
                                <span style={{ margin: '7px auto' }}>
                                  <span style={{ display: 'block', marginBottom: '15px' }}>Opening Hour</span>
                                  <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('7 days opening hour', 'allDayOpenH', this.state.allDayOpenH)}>
                                    <span style={{ fontSize: '1.1rem' }}>{this.state.allDayOpenH}</span>
                                    <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                  </span>
                                </span>
                                <span style={{ margin: '7px auto' }}>
                                  <span style={{ display: 'block', marginBottom: '15px' }}>Closing Hour</span>
                                  <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('7 days closing hour', 'allDayCloseH', this.state.allDayCloseH)}>
                                    <span style={{ fontSize: '1.1rem' }}>{this.state.allDayCloseH}</span>
                                    <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                  </span>
                                </span>
                              </span>
                            </MuiPickersUtilsProvider>
                          )
                        },
                        {
                          tabButton: "Set Week days and weekend",
                          tabContent: (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <span style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', textAlign: 'center' }}>
                                <h4>Week Days</h4>
                                <span style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: '7px' }}>
                                  <span style={{ margin: '7px auto' }}>
                                    <span style={{ display: 'block', marginBottom: '15px' }}>Opening Hour</span>
                                    <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Mon-Fri opening hour', 'weekDayOpenH', this.state.weekDayOpenH)}>
                                      <span style={{ fontSize: '1.1rem' }}>{this.state.weekDayOpenH}</span>
                                      <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                    </span>
                                  </span>
                                  <span style={{ margin: '7px auto' }}>
                                    <span style={{ display: 'block', marginBottom: '15px' }}>Closing Hour</span>
                                    <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Mon-Fri closing hour', 'weekDayCloseH', this.state.weekDayCloseH)}>
                                      <span style={{ fontSize: '1.1rem' }}>{this.state.weekDayCloseH}</span>
                                      <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                    </span>
                                  </span>
                                </span>
                                <h4>Weekend Days</h4>
                                <span style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: '7px' }}>
                                  <span style={{ margin: '7px auto' }}>
                                    <span style={{ display: 'block', marginBottom: '15px' }}>Opening Hour</span>
                                    <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Sat & Sun opening hour', 'weekendDayOpenH', this.state.weekendDayOpenH)}>
                                      <span style={{ fontSize: '1.1rem' }}>{this.state.weekendDayOpenH}</span>
                                      <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                    </span>
                                  </span>
                                  <span style={{ margin: '7px auto' }}>
                                    <span style={{ display: 'block', marginBottom: '15px' }}>Closing Hour</span>
                                    <span style={{ cursor: 'pointer', borderBottom: '1px solid #7d7c7c', paddingBottom: '10px' }} onClick={() => this.openTimeEditor('Sat & Sun closing hour', 'weekendDayCloseH', this.state.weekendDayCloseH)}>
                                      <span style={{ fontSize: '1.1rem' }}>{this.state.weekendDayCloseH}</span>
                                      <EditIcon style={{ margin: '0 10px', verticalAlign: 'middle', fontSize: '1.1rem' }} />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </MuiPickersUtilsProvider>
                          )
                        }
                      ]}>
                    </NavPills>
                  </span>
                </span>
              </form>

            </DialogContent>
            {this.state.cropImageMode ?
              (
                //dialog actions for crop image screen
                <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                  <Button onClick={() => this.setState({ cropImageMode: false })}>cancel</Button>
                  <Button disabled={this.state.editLogoFile ? false : true} onClick={this.saveCrop} color="primary">
                    Save Logo
                  </Button>
                </DialogActions>
              ) :
              (
                // dialog actions for edit merchant screen
                <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                  <Button className={classes.hideSmall} onClick={() => {
                    this.setState({ showMerchantModal: false, cropImageMode: false })
                    if (this.state.shouldRemoveLogo) {
                      this.setState({ savedLogoFile: this.state.logoBackUp, shouldRemoveLogo: false });
                    }
                  }}>cancel</Button>
                  <Button onClick={this.submitMerchant} color="primary">
                    Save
                  </Button>
                </DialogActions>
              )
            }


          </Dialog>
        ) : null}

        {/* Promotion Dialog */}
        {this.state.showPromoModal ? (
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.showPromoModal
            }}
            className={classNames(classes.resDia, classes.promoDetail)}
            open={this.state.showPromoModal}
            keepMounted
            onClose={() => this.setState({ showPromoModal: false, previewPromo: false })}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description"
            style={{ overflowX: 'hidden' }}>

            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
              style={{ display: 'flex', flexDirection: 'column', padding: '0', margin: '24px 24px 0', borderBottom: '1px solid #252326' }}
            >
              <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h3 className={classes.modalTitle} style={{ margin: '0' }}>
                  {!this.state.isDetailPromoDialog ? 'New Promotion' : 'Promotion Details'}
                </h3>
                <IconButton
                  style={{ margin: '0' }}
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => this.setState({ showPromoModal: false, previewPromo: false })}>
                  <Close className={classes.modalClose} />
                </IconButton>
              </div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <h5 style={{ textTransform: 'uppercase' }}>
                  {this.props.user.userInfo.merchantList[0].merchantName}
                </h5>
                <span>{`${this.state.activePromoCount} / ${this.state.selectedMerchantLimit} Active Promotions`}</span>
              </div>
            </DialogTitle>

            {/* preview promo */}
            <DialogContent style={{ display: `${this.state.previewPromo ? 'block' : 'none'}` }}>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '7px', margin: '7px 0px', alignItems: 'center' }}>
                <h4 style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'center', margin: '1px' }}>{this.state.Description1}</h4>

                <div style={{ display: 'flex' }} >
                  <span component={Button} disabled
                    style={{ fontWeight: 'bold', fontStyle: 'italic', fontSize: '1.5rem', fontFamily: "'EB Garamond', serif", color: '#0476B5', cursor: 'pointer', }}>{this.props.user.userInfo.merchantList[0].merchantName}</span>
                </div>

                {this.state.promoStartDate && this.state.promoEndDate ? (
                  <span style={{ margin: '5px 0px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <DateRangeIcon style={{ marginRight: '10px', verticalAlign: 'middle', fontSize: '15px', color: '#0476B5' }} />
                    <span>{moment(this.state.promoStartDate).format("MMM Do YYYY") + " - " + moment(this.state.promoEndDate).format("MMM Do YYYY")}</span>
                  </span>
                ) : null}

              </div>


              {this.state.Description2 || this.state.Description3 || this.state.Coupon || this.state.Barcode ?
                <div className={classes.rowSmallCol} style={{ display: 'flex', padding: '7px', margin: '7px 0px', justifyContent: 'space-evenly', alignItems: 'center', borderTop: '1px solid #252326' }}>

                  {this.state.Description2 || this.state.Description3 ? <div className={classes.sm2} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    <span>{this.state.Description2}</span>
                    <span>{this.state.Description3}</span>
                  </div> : <> </>
                  }

                  {this.state.Coupon || this.state.Barcode ?
                    <div className={classes.sm1} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                      {this.state.Coupon ? (
                        <div style={{ margin: '5px 0px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <img src={this.state.Coupon} style={{ maxHeight: '100%', maxWidth: '100%', margin: '' }} alt="coupon" />
                        </div>
                      ) : null}
                      {this.state.Barcode ? (
                        <span>
                          Coupon: {this.state.Barcode}
                        </span>
                      ) : null}
                    </div> : <> </>
                  }

                </div>
                : <></>}


              <span style={{ padding: '5px 0', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderTop: '1px solid #252326' }}>
                <Button disabled style={{ width: '50%', marginRight: '10px' }} color="primary" >Share</Button>
                <Button disabled style={{ width: '50%', marginLeft: '10px' }} >Close</Button>
              </span>

              <div style={{
                padding: '10px 0', display: 'flex', justifyContent: 'center'
              }}>
                * brought to you by <span style={{ fontSize: '1.2rem', marginLeft: '5px' }} className='ff-finding'>Finding</span><span style={{ fontSize: '1.5rem' }} className='ff-faber'>Faber</span><span className='ff-tm'>&trade;</span>
              </div>
            </DialogContent>

            {/* edit fields */}
            <DialogContent id="modal-slide-description" className={classes.modalBody} style={{ display: `${this.state.previewPromo ? 'none' : 'block'}` }}>
              <form className={classes.root} noValidate autoComplete="off">
                <span>
                  <div style={{ display: 'flex', paddingTop: '15px', alignItems: 'center', marginBottom: '5px', flexWrap: 'wrap' }}>
                    <h4 style={{ margin: '0 10px 0 0' }}>Promo Title</h4>
                    <p style={{ margin: '0', fontStyle: 'italic', fontFamily: '"EB Garamond", serif' }}>(First words consumer sees during search)</p>
                  </div>
                  <TextField
                    multiline={true} fullWidth={true} rows={5} error={this.state.Description1Err ? true : false} helperText={this.state.Description1Err ? this.state.Description1Err : null} name="Description1" onFocus={() => this.setState({ focusedInput: "Description1" })} label="Title" value={this.state.Description1} onChange={this.handleInputChange} variant="filled" />

                  <h4 style={{ borderTop: '1px solid #252326', paddingTop: '15px', margin: '25px 0 5px 0' }}>
                    {!this.state.isDetailPromoDialog ? 'Set Time Period for Promo' : 'Promo Period'}
                  </h4>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardDatePicker
                        error={this.state.promoStartDateErr ? true : false}
                        helperText={this.state.promoStartDateErr ? this.state.promoStartDateErr : null}
                        margin="normal"
                        id="date-picker-dialog"
                        label="Promotion Start Date"
                        format="MM/dd/yyyy"
                        value={this.state.promoStartDate}
                        onChange={(val) => this.setState({ promoStartDate: val, promoStartDateErr: false })}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }} />
                      <KeyboardDatePicker
                        error={this.state.promoEndDateErr ? true : false}
                        helperText={this.state.promoEndDateErr ? this.state.promoEndDateErr : null}
                        margin="normal"
                        id="date-picker-dialog"
                        label="Promotion End Date"
                        format="MM/dd/yyyy"
                        value={this.state.promoEndDate}
                        onChange={(val) => this.setState({ promoEndDate: val, promoEndDateErr: false })}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }} />
                    </Grid>
                  </MuiPickersUtilsProvider>

                  <div style={{ borderTop: '1px solid #252326', display: 'flex', paddingTop: '15px', alignItems: 'center', margin: '25px 0 5px 0', flexWrap: 'wrap' }}>
                    <h4 style={{ margin: '0 10px 0 0' }}>Description</h4>
                    <p style={{ margin: '0', fontStyle: 'italic', fontFamily: '"EB Garamond", serif' }}>(Briefly describe your offer to your consumer)</p>
                  </div>
                  <TextField
                    multiline={true} fullWidth={true} rows={5} error={this.state.Description2Err ? true : false} helperText={this.state.Description2Err ? "Invalid Entry" : null} name="Description2" onFocus={() => this.setState({ focusedInput: "Description2" })} label="Description" value={this.state.Description2} onChange={this.handleInputChange} variant="filled" />

                  <div style={{ borderTop: '1px solid #252326', display: 'flex', paddingTop: '15px', alignItems: 'center', margin: '25px 0 5px 0', flexWrap: 'wrap' }}>
                    <h4 style={{ margin: '0 10px 0 0' }}>Terms &amp; Restrictions</h4>
                    <p style={{ margin: '0', fontStyle: 'italic', fontFamily: '"EB Garamond", serif' }}>(If restrictions other than expiration date apply; i.e. not to be combined, one per customer, etc.)</p>
                  </div>

                  <TextField
                    multiline={true} fullWidth={true} rows={5} error={this.state.Description3Err ? true : false} helperText={this.state.Description3Err ? "Invalid Entry" : null} name="Description3" onFocus={() => this.setState({ focusedInput: "Description3" })} label="Description" value={this.state.Description3} onChange={this.handleInputChange} variant="filled" />

                  <h4 style={{ borderTop: '1px solid #252326', paddingTop: '15px', margin: '25px 0 5px 0' }}>Coupon Code</h4>
                  <span>
                    <TextField
                      error={this.state.BarcodeErr ? true : false} helperText={this.state.BarcodeErr ? "Invalid Entry" : null} name="Barcode" onFocus={() => this.setState({ focusedInput: "Barcode" })} label="Promotion Code" value={this.state.Barcode} onChange={this.handleInputChange} variant="filled" />

                    <label htmlFor="upload-photo">
                      <input style={{ display: 'none' }} id="upload-photo" name="upload-photo" type="file" accept="image/*, image/heic, image/heif" onChange={(e) => this.handleFileUpLoadChange(e, 2)}
                      />

                      {!this.state.CouponFileErr ? (
                        <Fab
                          color="primary" size="medium" component="span" aria-label="add" variant="extended"
                          style={{ backgroundColor: '#0476B5', margin: '0', height: '100%', borderRadius: '5px' }}>
                          {this.state.CouponFile || this.state.Coupon ? <CheckCircleIcon style={{ color: '#FECE00', marginRight: '7px' }} /> : <AddPhotoAlternateIcon style={{ marginRight: '7px' }} />}
                          {this.state.isDetailPromoDialog ? (this.state.Coupon ? 'Image Added' : 'No Image') : (this.state.CouponFile.name || 'Upload Coupon Picture')}
                        </Fab>
                      ) : (
                          <span>
                            <Fab color="primary" size="medium" component="span" aria-label="add" variant="extended"
                              style={{ backgroundColor: '#0476B5', margin: '0', height: '100%', borderRadius: '5px' }}>
                              <BlockIcon style={{ color: 'red', marginRight: '7px' }} /> {this.state.CouponFile.name}
                            </Fab>
                            <span style={{ color: 'red', fontSize: '0.8rem', display: 'block', textAlign: 'end' }}>
                              {this.state.CouponFileErrMsg}
                            </span>
                          </span>
                        )}
                    </label>
                  </span>
                </span>
              </form>
            </DialogContent >
            <DialogActions className={classes.modalFooterCenter}
              style={{
                padding: '',
                margin: '0',
                flexDirection: 'column',
                borderTop: '1px solid  #252326'
              }}>

              <div style={{ display: 'flex', width: '100%', justifyContent: this.state.isDetailPromoDialog ? 'space-between' : 'flex-start' }}>

                {!this.state.promoLoading ? (
                  <span style={{ display: 'flex' }}>
                    {this.state.previewPromo ? <Button onClick={() => this.setState({ previewPromo: false })} color="primary"> Edit </Button> : <Button onClick={() => this.setState({ previewPromo: true })} color="primary"> Preview </Button>}

                    {!this.state.isDetailPromoDialog ?
                      <Button onClick={() => this.submitPromo(0)} color="primary"> Save as Draft </Button> : //inactive by default
                      <>
                        {!this.state.promoIsActive ?
                          <Button color='success' onClick={() => this.submitPromo(0)}>Save Edits</Button> : //if this btn shows up, promo is inactive and we saving as such
                          <Button color='success' onClick={() => this.submitPromo(0)}>Deactivate</Button> //promo is active and we use this btn to deactivate
                        }
                      </>
                    }
                    {this.state.activePromoCount < this.state.selectedMerchantLimit || this.state.promoIsActive ?
                      <Button color='warning'
                        onClick={() => this.submitPromo(1)} style={{ color: '#252326' }}>Activate
                </Button>
                      :
                      <Button color='warning'
                        disabled style={{ color: '#252326' }}>
                        Active Promotion Limit Reached
                </Button>
                    }

                    <Button className={classes.hideSmall} onClick={() => this.setState({ showPromoModal: false, previewPromo: false })}>cancel</Button>

                  </span>) : <CircularProgress />}
                {this.state.isDetailPromoDialog && <Button className={classes.hide430} onClick={this.deletePromo} color="danger"> Delete </Button>}
                {this.state.isDetailPromoDialog &&
                  <IconButton aria-label="delete" className={classes.show430} onClick={this.deletePromo} color="danger" style={{ color: '#f44336' }}>
                    <DeleteIcon color="danger" />
                  </IconButton>
                }
              </div>

              <div style={{ margin: '10px 0 0 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div>
                  <span style={{ fontSize: '1.2rem' }} className='ff-finding'>Finding</span><span style={{ fontSize: '1.5rem' }} className='ff-faber'>Faber</span><span className='ff-tm'>&trade;</span>
                </div>
                <p style={{ fontFamily: '"EB Garamond", serif', fontSize: '12px', lineHeight: '1.25rem', margin: '0', padding: '0' }}>
                  * By clicking 'Activate on Finding Faber', the above named business is under agreement with John Patrick Publishing to honor/accept promotions submitted through the affiliate Finding Faber by a consumer. Non-compliance penalties and restrictions apply. 	&#169;2020
                  </p>
              </div>

            </DialogActions>
            {/* {this.state.shouldSnackBarOpen ? (
              <span style={{ zIndex: 9999 }}>
                <Snackbar
                  style={{ zIndex: 9999 }}
                  // anchorOrigin={{ "bottom", "center" }}
                  open={this.state.shouldSnackBarOpen}
                  onClose={this.handleSnackBarClose}
                  message={this.state.snackMsg}
                  key="key"
                />
              </span>
            ) : null} */}
          </Dialog >
        ) : null
        }

        {/* Manager Info Dialog */}
        {this.state.showManagerModal &&
          <>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.showManagerModal
              }}
              className={classes.resDia}
              open={this.state.showManagerModal}
              style={{ maxWidth: '750px', margin: 'auto' }}
              keepMounted
              onClose={() => { this.setState({ showManagerModal: false }); this.cancelEditPw() }}
              aria-labelledby="modal-slide-title"
              aria-describedby="modal-slide-description">

              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}>
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => { this.setState({ showManagerModal: false }); this.cancelEditPw() }}>

                  <Close className={classes.modalClose} />
                </IconButton>

                <h3 style={{ margin: '0' }} className={classes.modalTitle}>{this.state.updatePwMode ? 'Change Password' : 'Edit Account Info'}</h3>
              </DialogTitle>

              <DialogContent>

                {this.state.updatePwMode ? (
                  <>
                    <div style={{ margin: "7px 0px" }}>

                      <TextField fullWidth type='password' error={this.state.editManagerPasswordErr ? true : false} helperText={this.state.editManagerPasswordErr ? `${this.state.editManagerPasswordErr}` : null} name="editManagerPassword" onFocus={() => this.setState({ focusedInput: "ManagerPassword" })} label="New Password" value={this.state.editManagerPassword} onChange={this.handleInputChange} variant="filled" />

                    </div>
                    <div style={{ margin: "7px 0px" }}>
                      <TextField fullWidth type='password' error={this.state.editManagerConfirmPwErr ? true : false} helperText={this.state.editManagerConfirmPwErr ? `${this.state.editManagerConfirmPwErr}` : null} name="editManagerConfirmPw" onFocus={() => this.setState({ focusedInput: "ManagerConfirmPw" })} label="Confirm Password" value={this.state.editManagerConfirmPw} onChange={this.handleInputChange} variant="filled" />
                    </div>
                  </>
                ) : (
                    <form className={classes.root} noValidate autoComplete="off">
                      <span>
                        <h4>Log In Info</h4>
                        <div style={{ margin: "7px 0px" }}>
                          <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                            <TextField error={this.state.editManagerNameErr ? true : false} helperText={this.state.editManagerNameErr ? this.state.editManagerNameErr : null} name="editManagerName" onFocus={() => this.setState({ focusedInput: "ManagerName" })} label="Manager Name" value={this.state.editManagerName} onChange={this.handleInputChange} variant="filled" />
                          </span>
                          <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                            <TextField error={this.state.editManagerEmailErr ? true : false} helperText={this.state.editManagerEmailErr ? this.state.editManagerEmailErr : null} name="editManagerEmail" onFocus={() => this.setState({ focusedInput: "ManagerEmail" })} label="Manager Email" value={this.state.editManagerEmail} onChange={this.handleInputChange} variant="filled" />
                          </span>
                        </div>
                        <h4>Password</h4>
                        <Fab color="primary" size="medium" component="span" aria-label="add" variant="extended" style={{ backgroundColor: '#0476B5', borderRadius: '5px' }} onClick={() => this.setState({ updatePwMode: true })}>
                          Change Password
                          </Fab>
                      </span>
                    </form>
                  )}

              </DialogContent>

              <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                {this.state.updatePwMode ? (
                  <>
                    <Button onClick={this.cancelEditPw}>cancel</Button>
                    <Button onClick={this.updateManagerPw} color="primary">
                      Save
                            </Button>
                  </>
                ) : (
                    <>
                      <Button onClick={() => this.setState({ showManagerModal: false })}>cancel</Button>
                      <Button onClick={this.updateManagerInfo} color="primary">
                        Apply changes
                          </Button>
                    </>
                  )}

              </DialogActions>
            </Dialog>
          </>
        }

        {/* Manager Info Dialog */}
        {this.state.showTimePicker &&
          <>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.showTimePicker
              }}
              className={classes.resDia}
              open={this.state.showTimePicker}
              style={{ maxWidth: '750px', margin: 'auto' }}
              keepMounted
              onClose={() => { this.setState({ showTimePicker: false }) }}
              aria-labelledby="modal-slide-title"
              aria-describedby="modal-slide-description">

              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader} style={{ backgroundColor: "#0476B5", color: 'white', width: '100%' }}>
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => { this.setState({ showTimePicker: false }) }}>

                  <Close className={classes.modalClose} />
                </IconButton>

                <h3 style={{ margin: '0' }} className={classes.modalTitle}>{this.state.selectedTime.displayName}</h3>
              </DialogTitle>
              {/* variant="outlined" */}
              <DialogContent style={{ backgroundColor: '#d6d6d6' }}>
                <span style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '15px 15px', padding: '10px', borderRadius: '4px' }}>
                  <span style={{ display: 'flex', flexDirection: 'column', maxWidth: '70px' }}>
                    <FormControl className={classes.formControl} style={{ margin: '0px 7px' }}>
                      {/* <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel> */}
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.hour}
                        onChange={(e) => this.setState({ hour: e.target.value })}
                        label="Age"
                        className='hours'
                      >

                        {this.state.hours.map(h => {
                          return (
                            <MenuItem key={'h' + h} value={h}>
                              <em>{h}</em>
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                    <span style={{ fontSize: '0.6rem', fontStyle: 'oblic', marginLeft: '10px' }}>Hr</span>
                  </span>
                  <span style={{ fontSize: '1.3rem', margin: '0px 7px' }}>:</span>
                  <span style={{ display: 'flex', flexDirection: 'column', maxWidth: '70px' }}>
                    <FormControl className={classes.formControl} style={{ margin: '0px 7px' }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.minute}
                        onChange={(e) => this.setState({ minute: e.target.value })}
                        label="Age">

                        {this.state.minutes.map(min => {
                          return (
                            <MenuItem key={'min' + min} value={min}>
                              <em>{min}</em>
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                    <span style={{ fontSize: '0.6rem', fontStyle: 'oblic', marginLeft: '10px' }}>Min</span>
                  </span>
                  <span style={{ display: 'flex', flexDirection: 'column', maxWidth: '100px' }}>
                    <FormControl variant="filled" className={classes.formControl} style={{ margin: '0px 7px' }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.dayTime}
                        onChange={(e) => this.setState({ dayTime: e.target.value })}
                        label="AM/PM"
                      >

                        <MenuItem value='AM'>
                          <em>AM</em>
                        </MenuItem>

                        <MenuItem value='PM'>
                          <em>PM</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <span style={{ fontSize: '0.6rem', fontStyle: 'oblic', marginLeft: '10px' }}>AM/PM</span>
                  </span>
                </span>
              </DialogContent>

              <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                <Button color="primary" onClick={this.applyTimeChange}>OK</Button>
                <Button onClick={() => { this.setState({ showTimePicker: false }) }}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </>
        }

        {/* Error dialog only for JPPC Admins */}
        {/* this.state.showErrorDialog &&  */}
        {(this.state.showErrorDialog && this.props.type == 'admin') &&
          <>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.showErrorDialog
              }}
              className={classes.resDia}
              open={(this.state.showErrorDialog && this.props.type == 'admin')}
              style={{ maxWidth: '750px', margin: 'auto' }}
              keepMounted
              onClose={() => { this.setState({ showErrorDialog: false }) }}
              aria-labelledby="modal-slide-title"
              aria-describedby="modal-slide-description">

              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader} style={{ backgroundColor: "#0476B5", color: 'white', width: '100%' }}>
                <IconButton
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => { this.setState({ showErrorDialog: false }) }}>

                  <Close className={classes.modalClose} />
                </IconButton>

                <h3 style={{ margin: '0' }} className={classes.modalTitle}>Error</h3>
              </DialogTitle>
              {/* variant="outlined" */}
              <DialogContent style={{ backgroundColor: '#d6d6d6' }}>
                <span> Please report this error to the development team. Click on "Copy" to copy this error. </span>
                {/* ref={textAreaRef} */}
                <TextareaAutosize value={this.state.jppcAdminError} id="jppc-error" style={{ maxHeight: '200px', overflowY: 'scroll', width: '100%' }}>{this.state.jppcAdminError}</TextareaAutosize>
              </DialogContent>

              <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                <span style={{ color: 'red' }}>
                  {this.state.isErrorCopied ? "Error copied! Please send it to the development team!" : null}
                </span>
                <Button color="primary" onClick={this.copyErr}>Copy</Button>
                <Button onClick={() => { this.setState({ showErrorDialog: false }) }}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </>
        }

        {this.state.shouldSnackBarOpen ? (
          <Portal>
            <Snackbar
              // anchorOrigin={{ "bottom", "center" }}
              open={this.state.shouldSnackBarOpen}
              onClose={this.handleSnackBarClose}
              message={this.state.snackMsg}
              key="key"
            />
          </Portal>
        ) : null}
      </div>
    );
  }
}


export default withStyles(styles, { withTheme: true })(ProfilePage);