/*eslint-disable*/
import React, { useEffect, Component } from "react";
import { Redirect } from 'react-router-dom';
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import SearchIcon from '@material-ui/icons/Search';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

// FireBase
import firebase from '../../config/firebase';

import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import findingFaberLogo from '../../assets/img/FF header portion minus shadow.png';

// const useStyles = makeStyles(styles);

class HeaderLeftLinks extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      password: "",
      isFirebaseInit: false,
      activeUser: "",
      classes: null,
      isLoading: true,
      isUser: false,
      isLogOut: false,
      searchK: ''
    }
  }


  componentDidMount() {
    if (this.props.user) {
      this.setState({ activeUser: this.props.user })
    }
  }



  render() {
    const { classes } = this.props;
    return (
      <List className={classes.list}>
        <Link to='/'><img src={findingFaberLogo} width="" height="75px"></img></Link>
        {this.state.isLogOut ? <Redirect to='/' /> : null}
        {this.props.user ? (
          <ListItem className={classes.listItem}>
            {/* <span>{this.props.user.userInfo.managerName}</span> */}
          </ListItem>
        ) : null}
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HeaderLeftLinks);