import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLeftLinks from 'components/Header/HeaderLeftLinks.js';
import HeaderRightLinks from "components/Header/HeaderRightLinks.js";
import SectionMerchants from "./Sections/SectionMerchants.js";

//Models
import model from '../../models/Model';

// Chart
import { ResponsiveLine } from '@nivo/line'

import styles from "assets/jss/material-kit-react/views/components.js";
import findingFaberLogo from '../../assets/img/FindingFaber-Logo/FFapp_icon-tag.png'
//import parallaxImg from '../../assets/img/marigold-bg.png'

const data = [
  {
    "id": "Serie 1",
    "data": [
      {
        "x": 2000,
        "y": 5
      },
      {
        "x": 2001,
        "y": 5
      },
      {
        "x": 2002,
        "y": 7
      },
      {
        "x": 2003,
        "y": 1
      },
      {
        "x": 2004,
        "y": 3
      }
    ]
  },
  {
    "id": "Serie 2",
    "data": [
      {
        "x": 2000,
        "y": 9
      },
      {
        "x": 2001,
        "y": 9
      },
      {
        "x": 2002,
        "y": 12
      },
      {
        "x": 2003,
        "y": 8
      },
      {
        "x": 2004,
        "y": 2
      }
    ]
  },
  {
    "id": "Serie 3",
    "data": [
      {
        "x": 2000,
        "y": 7
      },
      {
        "x": 2001,
        "y": 8
      },
      {
        "x": 2002,
        "y": 9
      },
      {
        "x": 2003,
        "y": 2
      },
      {
        "x": 2004,
        "y": 1
      }
    ]
  },
  {
    "id": "Serie 4",
    "data": [
      {
        "x": 2000,
        "y": 11
      },
      {
        "x": 2001,
        "y": 3
      },
      {
        "x": 2002,
        "y": 8
      },
      {
        "x": 2003,
        "y": 4
      },
      {
        "x": 2004,
        "y": 12
      }
    ]
  }
]


class PGDash extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      newMembModal: false
    }
  }

  componentDidMount() {
    var newMerchant = model.Merchant();
    console.log(newMerchant);
  }




  render() {
    const { classes } = this.props;
    return (
      <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        {!this.props.user ? (
          <Redirect to="/" />
        ) : (this.props.user.userInfo.role != 'admin' ? <Redirect to="/" /> : null)}
        <Header
          color="transparent"
          rightLinks={<HeaderRightLinks user={this.props.user} setUser={(u) => this.props.setUser(u)} />}
          leftLinks={<HeaderLeftLinks />}
          user={this.props.user}
          setUser={(u) => this.props.setUser(u)}
          fixed
          changeColorOnScroll={{
            height: 100,
            color: "white"
          }}
        />
        {/* <Parallax small
          className={classes.ParallaxStyle}
          image={require("assets/img/shamrock-bg.png")}
          // style={{ height: '20vh' }}
          >
          <div className={classes.container}>
          </div>
        </Parallax> */}

        <div className={classNames(classes.main)} style={{paddingTop: '120px'}}>
        <img src={require('assets/img/FF_map_only.png')}
            style={{ position: 'fixed', zIndex: '-10', width: '100%', bottom: '0', opacity: '5%' }}
          />
          <SectionMerchants user={this.props.user} setUser={(u) => this.props.setUser(u)} showTimeOutWarning={() => this.props.showTimeOutWarning()} sessionCounter={this.props.sessionCounter} setSessionCounter={(t) => this.props.setSessionCounter(t)} />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PGDash);