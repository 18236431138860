import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product
import PGDash from "views/PGDash/PGDash.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import Manager from "views/Manager/Manager.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import AboutPage from "views/AboutPage/AboutPage.js";
import Download from "views/Download/Download";
import ContactPage from "views/ContactPage/ContactPage.js";
import PrivacyPage from "views/PrivacyPage/PrivacyPage.js";
import ListResult from "views/SearchResultPages/ListResult.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { createBrowserHistory } from "history";
import api from "utils/api";
import Button from "components/CustomButtons/Button.js";

const hist = createBrowserHistory();

export default class Routes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLodading: false,
            user: false,
            userZip: null,
            searchObj: '',
            showTimeOutW: false,
            sessionCounter: 0,
            autoLogOutCounter: 0
        };
    }

    setUser = (u) => {
        console.log("route setUser: ", u);
        this.setState({ user: u });
    }

    setUserZip = (zip) => {
        console.log("route setUserZip: ", zip)
        this.setState({ userZip: zip });
    }

    setSearchObj = (obj) => {
        console.log("route setSearchObj: ", obj);
        if (!obj.SourceZipCode) {
            obj.SourceZipCode = this.state.userZip;
        }
        this.setState({ searchObj: obj });
    }

    setSessionCounter = (t) => {
        this.setState({ sessionCounter: t});
    }

    autoLogOutInterval = null;

    showTimeOutWarning = () => {
        this.setState({ showTimeOutW: true }, () => {
            this.autoLogOutInterval = setInterval(()=> {
                var newAutoLogO = this.state.autoLogOutCounter + 60000;
                this.setState({ autoLogOutCounter: newAutoLogO });
                if(newAutoLogO > 5*60000){ // after 5min
                    this.setState({ showTimeOutW: false });
                    this.setUser(null);
                    api.logout();
                }
            }, 60000)
        });
    }

    handleTimeOut = async (extended) => {
        if(extended){
            clearInterval(this.autoLogOutInterval);
            var isExtended = await api.extendUserSession();
            if(isExtended){
                this.setState({ showTimeOutW: false });
                this.setSessionCounter(0);
            } else {
                this.setUser(null);
                api.logout();
                this.setState({ showTimeOutW: false });
            }
        } else {
            this.setState({ showTimeOutW: false });
            this.setUser(null);
            api.logout();
        }
    }

    render() {
        return (
            <Router history={hist}>
                <Switch>
                    <Route exact path="/Manager" render={() => (<Manager sessionCounter={this.state.sessionCounter} setSessionCounter={(t) => this.setSessionCounter(t)} showTimeOutWarning={() => this.showTimeOutWarning()} user={this.state.user} setUser={(user) => this.setUser(user)} type={'manager'} userZip={this.state.userZip} setUserZip={(zip) => this.setUserZip(zip)} />)} />
                    <Route exact path="/login" render={() => (<LoginPage user={this.state.user} setUser={(user) => this.setUser(user)} userZip={this.state.userZip} setUserZip={(zip) => this.setUserZip(zip)} />)} />
                    <Route exact path="/admin" render={() => (<PGDash sessionCounter={this.state.sessionCounter} setSessionCounter={(t) => this.setSessionCounter(t)} showTimeOutWarning={() => this.showTimeOutWarning()} user={this.state.user} setUser={(user) => this.setUser(user)} userZip={this.state.userZip} setUserZip={(zip) => this.setUserZip(zip)} />)} />
                    <Route exact path='/about' render={() => (<AboutPage user={this.state.user} setUser={(user) => this.setState(user)} userZip={this.state.userZip} setUserZip={(zip) => this.setUserZip(zip)} />)} />
                    {/* <Route exact path='/contact' render={() => (<ContactPage user={this.state.user} setUser={(user) => this.setState(user)} userZip={this.state.userZip} setUserZip={(zip) => this.setUserZip(zip)}/>)}/> */}
                    <Route exact path='/terms' render={() => (<PrivacyPage user={this.state.user} setUser={(user) => this.setState(user)} userZip={this.state.userZip} setUserZip={(zip) => this.setUserZip(zip)} />)} />
                    <Route path="/search/:merchantID/:promotionID" render={(props) => {
                        return <ListResult routeParams={props.match.params} user={this.state.user} setUser={(user) => this.setUser(user)} userZip={this.state.userZip} setUserZip={(zip) => this.setUserZip(zip)} searchObj={this.state.searchObj} setSearchObj={(o) => this.setSearchObj(o)} />
                    }} />
                    <Route exact path='/download' render={() => (<Download user={this.state.user} setUser={(user) => this.setState(user)} userZip={this.state.userZip} setUserZip={(zip) => this.setUserZip(zip)} />)} />
                    <Route path="/" render={() => (<LandingPage user={this.state.user} setUser={(user) => this.setUser(user)} userZip={this.state.userZip} setUserZip={(zip) => this.setUserZip(zip)} searchObj={this.state.searchObj} setSearchObj={(o) => this.setSearchObj(o)} />)} />
                </Switch>
                {/* TimeOut */}
                <Dialog
                    open={this.state.showTimeOutW}
                    // TransitionComponent={Transition}
                    onClose={this.handleHideLogin} >
                    <DialogTitle>
                        Session Time Out
                    </DialogTitle>
                    <DialogContent>
                        <span>
                            You have been inactive for the past 45min. You will be logged out.
                            <br />
                            Need more time?
                        </span>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={() => this.handleTimeOut(true)}>Yes</Button>
                        <Button color="warning" onClick={() => this.handleTimeOut(false)}>No</Button>
                    </DialogActions>
                </Dialog>
            </Router>
        )
    }
}