import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom';
import Manager from "views/Manager/Manager.js";
// nodejs library that concatenates classes
import classNames from "classnames";
// plugin that creates slider
import Slider from "nouislider";
// @material-ui/core components
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from "@material-ui/core/Switch";
import Divider from '@material-ui/core/Divider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputBase from '@material-ui/core/InputBase';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Fab from '@material-ui/core/Fab';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple';
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import People from "@material-ui/icons/People";
import Check from "@material-ui/icons/Check";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import BlockIcon from '@material-ui/icons/Block';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";
import Paginations from "components/Pagination/Pagination.js";
import Badge from "components/Badge/Badge.js";
import Card from "components/Card/Card";
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

// Expansion from material ui
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

//Dialog
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/SnackBar
import Snackbar from '@material-ui/core/Snackbar';
import Portal from '@material-ui/core/Portal';
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import MapIcon from '@material-ui/icons/Map';
import LanguageIcon from '@material-ui/icons/Language';
//Models
import model from "../../../models/Model";
// Validator
import validator from "../../../validator/validator";

//JPP API
import api from '../../../utils/api';

import "../customStyle/customStyle.css";

import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
import { tr } from "date-fns/locale";


class SectionMerchants extends Component {
  // export default function SectionMerchants(props) {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = {
      activeMerchant: '',
      merchantOpen: false,
      isLoading: false,
      expanded: false,
      NewMerchModal: false,
      updloadMenu: null,
      updloadType: null,
      newMerchantList: [],
      focusedInput: '',
      isSubmit: false,
      shouldSnackBarOpen: false,
      snackMsg: "",
      newManagerName: "",
      newManagerNameErr: "",
      newMerchantEmail: "",
      newMerchantEmailErr: "",
      newMerchantName: "",
      newMerchantNameErr: '',
      newAddress1: "",
      newAddress1Err: '',
      newAddress2: "",
      newAddress2Err: '',
      newCity: "",
      newCityErr: '',
      newState: "",
      newStateErr: '',
      newZip: "",
      newZipErr: '',
      newContactName: "",
      newContactNameErr: '',
      newPhone: "",
      newPhoneErr: '',
      newEmail: "",
      newEmailErr: '',
      newWebsite: "",
      newWebsiteErr: '',
      newLogoFile: "",
      newLogoFileErr: '',
      newPassword: "",
      newPasswordErr: '',
      newPromotionLimit: 3,
      newPromotionLimitErr: '',
      searchOrgInput: '',
      searchOrgInputErr: '',
      searchOrgTerm: '',
      searchOrgList: '',
      selectedOrg: '',
      selectedOrgErr: '',
      catOptions: [],
      subCatOptions: [],
      newMerchantCat: '',
      newMerchantCatErr: '',
      catInput: '',
      searchMerchant: '',
      searchMerchantErr: '',
      MerchantListErr: '',
      MerchantList: [],
      selectedCat: '',
      selectedCatErr: '',
      selectedSubCat: '',
      selectedSubCatErr: '',
      searchSetting: 'name',
      accessCount: 0,
      followerCount: 0,
      openUploadDialog: false,
      uploadFileErr: false,
      uploadFile: null,
      authU: ['whelsel@jppc.net',
        'pmcginleyjr@jppc.net',
        'ben@parishgiving.org',
        'Thomas@parishgiving.org',
        'pgraham@jppc.net'
      ],
      isAuth: false,
      isFileUploading: false
    }
  }
  handleUploadClick = (event) => {
    this.setState({ updloadMenu: event.currentTarget });
  };

  handleUploadMenuClose = () => {
    this.setState({ updloadMenu: null });
  };

  handleUploadMenuSelect = (type) => {
    this.setState({ updloadMenu: null, updloadType: type, openUploadDialog: true });
  }

  handlePanelChange = (panel) => (event, isExpanded) => {
    isExpanded ? this.setState({ expanded: panel }) : this.setState({ expanded: false });
  };

  componentDidMount() {
    // newMerchantList
    var arr = [];
    arr.push(model.Merchant());
    this.formatSubCatOptions();
    this.setState({
      newMerchantList: arr,
      MerchantList: ''
    });
    console.log(this.props.user);
    this.checkIsAuthorized()
  }

  checkIsAuthorized = () => {
    console.log("here1");
    if (this.props.user.role === 'admin') {
      console.log("isadmin", this.props.user.userEmail);
      this.state.authU.forEach(email => {
        console.log("email: ", email);
        if (email.toLocaleLowerCase() === this.props.user.userEmail.toLocaleLowerCase()) {
          this.setState({ isAuth: true })
        }
      });
    }
    //this.setState({ isAuth: true })
  }

  openSnackBar = (msg) => {
    this.setState({ isSubmit: false, snackMsg: msg, shouldSnackBarOpen: true });
  }

  handleSnackBarClose = () => {
    this.setState({ shouldSnackBarOpen: false, snackMsg: '' });
    //console.log("handle");
  }

  validateInput = (type, value) => {
    var control = {
      MerchantName: this.state.newMerchantName,
      ManagerName: this.state.newManagerName,
      Email: this.state.newEmail,
      ContactName: this.state.newContactName
    }
    var isOkay = true;
    //console.log("type: ", type, "value: ", value);

    switch (true) {
      case (type == "newMerchantName" || this.state.isSubmit): {
        if (type == "newMerchantName" && !this.state.isSubmit) {
          var v = validator.name(value);
          // console.log(v);
          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.name(this.state.newMerchantName);
          // console.log("here: ", v);
          if (!v.valid) {
            this.setState({ isSubmit: false });
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      case (type == "newManagerName" || this.state.isSubmit): {
        if (type == "newManagerName" && !this.state.isSubmit) {
          var v = validator.name(value);
          // console.log(v);
          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.name(this.state.newManagerName);
          // console.log("here: ", v);
          if (!v.valid) {
            this.setState({ isSubmit: false });
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      case (type == 'newAddress1' || this.state.isSubmit): {
        if (type == 'newAddress1' && !this.state.isSubmit) {
          //required
          if (!value) {
            return 'Address is required!';
          }
          var v = validator.address(value);
          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.address(this.state.newAddress1);
          if (!v.valid) {
            this.setState({ isSubmit: false })
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      case (type == 'newCity' || this.state.isSubmit): {
        if (type == 'newCity' && !this.state.isSubmit) {
          //required
          if (!value) {
            return 'City is required!';
          }
          if (value.length > 3) {
            return false;
          } else {
            return 'Input too short!';
          }
        }
        if (this.state.isSubmit) {
          if (this.state.newCity.length < 3) {
            this.setState({ isSubmit: false })
            this.openSnackBar("Please review City");
            return false;
          }
        }
      }
      case (type == 'newState' || this.state.isSubmit): {
        if (type == 'newState' && !this.state.isSubmit) {
          //required
          if (value) {
            return false;
          } else {
            return 'State is required!';
          }
        }
        if (this.state.isSubmit) {
          if (!this.state.newState) {
            this.setState({ isSubmit: false })
            this.openSnackBar("Please review State");
            return false;
          }
        }
      }
      case (type == 'newZip' || this.state.isSubmit): {
        if (type == 'newZip' && !this.state.isSubmit) {
          //required
          if (!value) {
            return 'Zipcode is required!';
          }
          var v = validator.zip(value);
          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.zip(this.state.newZip);
          if (!v.valid) {
            this.setState({ isSubmit: false })
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      case (type == 'newContactName' || this.state.isSubmit): {
        if (type == 'newContactName' && !this.state.isSubmit) {
          //not required, but if entered needs validation.
          if (!value) {
            return false;
          }
          var v = validator.name(value);
          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.name(this.state.newContactName);
          if (!v.valid) {
            this.setState({ isSubmit: false })
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      case (type == 'newPhone' || this.state.isSubmit): {
        if (type == 'newPhone' && !this.state.isSubmit) {
          //not required, but if entered needs validation.
          if (!value) {
            return false;
          }
          var v = validator.phone(value);
          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.phone(this.state.newPhone);
          if (!v.valid) {
            this.setState({ isSubmit: false })
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      case (type == 'newWebsite' || this.state.isSubmit): {
        if (type == 'newWebsite' && !this.state.isSubmit) {
          //not required, but if entered needs validation.
          if (!value) {
            return false;
          }
          var v = validator.website(value);
          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.website(this.state.newWebsite);
          if (!v.valid) {
            this.setState({ isSubmit: false })
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
      // case 'logo':{
      //   if(this.state.focusedInput == type || this.state.isSubmit){

      //   return false;

      //   }
      // }
      case (type == 'newEmail' || this.state.isSubmit): {
        if (type == 'newEmail' && !this.state.isSubmit) {
          //required
          if (!value) {
            return 'Email is required to log in!';
          }
          var v = validator.email(value);
          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.email(this.state.newEmail);
          if (!v.valid) {
            this.setState({ isSubmit: false })
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }

      case (type == 'newMerchantEmail' || this.state.isSubmit): {
        if (type == 'newMerchantEmail' && !this.state.isSubmit) {
          //not required, but if entered needs validation.
          if (!value) {
            return false;
          }
          var v = validator.email(value);
          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.email(this.state.newMerchantEmail);
          if (!v.valid) {
            this.setState({ isSubmit: false })
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }

      case (type == 'newPassword' || this.state.isSubmit): {
        if (type == 'newPassword' && !this.state.isSubmit) {
          //required
          if (!value) {
            return 'Password is required!';
          }
          var v = validator.validatePassword(value, control);
          if (v.valid) {
            return false;
          } else {
            return v.reason;
          }
        }
        if (this.state.isSubmit) {
          var v = validator.validatePassword(this.state.newPassword, control);
          if (!v.valid) {
            this.setState({ isSubmit: false })
            this.openSnackBar(v.reason);
            return false;
          }
        }
      }
    }
    return isOkay;
  }

  handleInputChange = (event) => {

    var value = event.target.value;
    var name = event.target.name;
    // console.log(`${name}: ${value}`)
    this.setState({
      [name]: value
    }, () => {
      var result = this.validateInput(name, value);
      var checker = name + "Err"
      this.setState({
        [checker]: result
      })
    })
  }

  //save new merchant
  saveNewM = () => {
    console.log('saving...');

    // if required fields are blank...
    //send error for the field and return false

    //if required fields have errors
    //send error for the field and return false

    if (!this.state.newManagerName || this.state.newManagerNameErr || !this.state.newEmail || this.state.newEmailErr || !this.state.newPassword || this.state.newPasswordErr || !this.state.newMerchantName || this.state.newMerchantNameErr || !this.state.selectedSubCat || !this.state.selectedOrg) {
      if (!this.state.newManagerName) {
        this.setState({ newManagerNameErr: 'Name is required!' });
      }
      if (!this.state.newEmail) {
        this.setState({ newEmailErr: 'Email is required!' });
      }
      if (!this.state.newPassword) {
        this.setState({ newPasswordErr: 'Password is required!' });
      }
      if (!this.state.newMerchantName) {
        this.setState({ newMerchantNameErr: 'Name is required!' });
      }
      if (!this.state.selectedSubCat) {
        if (!this.state.selectedCat) {
          this.setState({ selectedCatErr: 'Select a Category!' })
        }
        this.setState({ selectedSubCatErr: 'Select Subcategory!' })
      }
      if (!this.state.selectedOrg) {
        //if there's no select, put error on searchbar
        if (this.state.searchOrgList.length) {
          this.setState({ selectedOrgErr: 'Merchant must be linked to a church!' })
        } else {
          this.setState({ searchOrgListErr: 'Church is required! Search for a church!' })
        }
      }

      this.openSnackBar('Please review errors!');
      return false;
    }

    this.setState({ isSubmit: true, isLoading: true }, () => {
      console.log('function...');
      var isOkay = true;
      // if(!this.validateInput()){
      //   console.log("Good to go");
      var dt = {
        MerchantName: this.state.newMerchantName,
        Address1: this.state.newAddress1,
        Address2: "null", // ?????
        City: this.state.newCity,
        State: this.state.newState,
        Zip: this.state.newZip,
        ContactName: this.state.newContactName,
        Phone: this.state.newPhone,
        Email: this.state.newEmail,
        Website: this.state.newWebsite,
        // LogoFile: 'null',
        Password: this.state.newPassword,
        ManagerName: this.state.newManagerName,
        MerchantEmail: this.state.newMerchantEmail,
        PromotionLimit: Number(this.state.newPromotionLimit) || 0,
        SubCategoryID: this.state.selectedSubCat.subCategoryID,
        OrganizationID: this.state.selectedOrg,
        AccessCount: 0,
        FollowerCount: 0
      }

      var d = {
        data: dt,
        idToken: this.props.user.idToken
      }
      api.createManager(d).then((res) => {

        this.setState({ isLoading: false })
        if (res.status == 200) {
          console.log(res);
          this.openSnackBar("Merchant Added!");
          this.clearInput();
        } else if (res.data) {
          if (res.statusMessage) {
            console.log(res)
            console.log(res.message);
            console.log(res.data);
            this.setState({ snackMsg: res.statusMessage });
            this.openSnackBar(res.statusMessage);
          } else {
            console.log(res)
            console.log(res.statusMessage);
            console.log(res.data);
            this.openSnackBar("An unexpected error has occurred!");
          }
        } else {
          this.openSnackBar("An unexpected error has occurred!");
        }
      });
      this.setState({ isSubmit: false });
      // } else {
      //   console.log("Not Good at all");
      //   this.setState({ isSubmit: false });
      // }
    });
  }

  clearInput = () => {
    this.setState({
      newManagerName: "",
      newManagerNameErr: "",
      newMerchantEmail: "",
      newMerchantEmailErr: "",
      newMerchantName: "",
      newMerchantNameErr: '',
      newAddress1: "",
      newAddress1Err: '',
      newAddress2: "",
      newAddress2Err: '',
      newCity: "",
      newCityErr: '',
      newState: "",
      newStateErr: '',
      newZip: "",
      newZipErr: '',
      newContactName: "",
      newContactNameErr: '',
      newPhone: "",
      newPhoneErr: '',
      newEmail: "",
      newEmailErr: '',
      newWebsite: "",
      newWebsiteErr: '',
      newLogoFile: "",
      newLogoFileErr: '',
      newPassword: "",
      newPasswordErr: '',
      newPromotionLimit: 3,
      newPromotionLimitErr: '',
      selectedCat: '',
      selectedCatErr: '',
      selectedSubCat: '',
      selectedSubCatErr: '',
      searchOrgInput: '',
      searchOrgList: '',
      searchOrgListErr: '',
      selectedOrg: '',
      selectedOrgErr: '',
      searchSetting: 'name',
      accessCount: 0,
      followerCount: 0
    });
  }

  cancelMerchantCreation = () => {
    this.clearInput();
    this.setState({ NewMerchModal: false });
  }

  formatSubCatOptions = () => {

    api.getCategories()
      .then(res => {
        this.setState({
          catOptions: res.data.categoryModelList,
          subCatOptions: res.data.subCategoryModelList
        })
      })
      .catch(err => console.log(err));
  }

  handleClearSearch = (name) => {

    this.setState({ [name]: '' });
  }

  handleSearchOrgs = () => {
    if (this.state.searchOrgInput) {
      let searchData = {
        OrganizationName: '',
        SourceZipCode: '',
        MaxDistance: 0,
        CHURCH_ID: '',
      };

      if (this.state.searchSetting === 'id') {
        searchData.CHURCH_ID = this.state.searchOrgInput;
      } else {
        searchData.OrganizationName = this.state.searchOrgInput
      }

      let data = {
        data: searchData,
        idToken: this.props.user.idToken
      };

      api.searchOrg(data).then(res => {
        console.log(res);
        if (res.status === 200) {
          //if response is good, 
          if (res.data.length > 0) {
            //if response has results, 
            this.setState({
              searchOrgList: res.data,
              searchOrgListErr: false
            })
          } else {
            this.setState({
              searchOrgList: res.data,
              searchOrgListErr: 'No results found!'
            })
          }
        } else {
          this.setState({
            searchOrgList: [],
            searchOrgListErr: 'There was an error with the search!'
          })
        }

      }).catch(err => {
        console.log(err);
        this.setState({
          searchOrgListErr: 'There was an error with the connection!'
        })
      })
    }
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSearchMerchant();
    }
  }

  handleSearchMerchant = () => {
    this.setState({ isLoading: true });
    if (this.state.searchMerchant) {
      console.log(this.state.searchMerchant);
      let data = {
        MerchantName: this.state.searchMerchant,
        SourceZipCode: '',
        MaxDistance: 0,
        CategoryID: 0,
        SubCategoryID: 0
      };
      api.searchMerchant(data).then(res => {
        if (res.status === 200) {
          console.log(res.data);
          //if there are results
          if (res.data.length > 0) {
            this.setState({
              MerchantListErr: '',
              MerchantList: res.data,
              isLoading: false
            })
          } else {
            //if search is successful but term has no results
            this.setState({
              MerchantListErr: 'No results found!',
              MerchantList: [],
              isLoading: false
            })
          }

        } else {
          this.setState({
            MerchantListErr: 'Search error!',
            isLoading: false
          })
        }
      }).catch(err => {
        console.log(err);
        this.setState({
          MerchantListErr: 'Connection error!',
          isLoading: false
        })
      })
    } else {
      this.setState({
        searchMerchantErr: 'no search term!',
        isLoading: false
      })
    }
  }

  clearMerchModal = () => {
    this.setState({
      NewMerchModal: false
    })
    this.clearInput();
  }

  handleHideLogin = () => {
    this.setState({
      merchantOpen: false,
      // activeMerchant: ''
    })
  }

  handleUploadDialogClose = () => {
    this.setState({ uploadFile: false, openUploadDialog: false })
  }

  handleFileUpLoadChange = (event) => {
    console.log(event);
    const files = event.target.files;
    console.log("file", files);
    if (files[0]) {
      if (files[0].type.split("/")[1].indexOf('excel') > -1 || files[0].type.split("/")[1].indexOf('sheet') > -1) {
        this.setState({ uploadFile: files[0], uploadFileErr: false });
      } else {
        this.setState({ uploadFile: files[0], uploadFileErr: true });
      }
    }
  }

  handleUploadFile = () => {
    // console.log("send File");
    // console.log("this.state.uploadFile: ", this.state.uploadFile);
    if (!this.state.uploadFileErr) {
      this.setState({ isFileUploading: true });
      // DeactiveMerchantList  NewMerchantListks
      if (this.state.updloadType == 1) {
        api.NewMerchantList({ data: this.state.uploadFile, idToken: this.props.user.idToken }).then((res) => {
          console.log(res);
          this.setState({ isFileUploading: false });
          if (res.status == 200) {
            this.openSnackBar("File uploaded!");
          } else {
            this.openSnackBar("Error, We were not able to upload this file.");
          }

        });
      } else {
        api.DeactiveMerchantList({ data: this.state.uploadFile, idToken: this.props.user.idToken }).then((res) => {
          console.log(res);
          this.setState({ isFileUploading: false });
          if (res.status == 200) {
            this.openSnackBar("File uploaded!");
          } else {
            this.openSnackBar("Error, We were not able to upload this file.");
          }
        });
      }
    } else {
      if (this.state.uploadFile) {
        this.openSnackBar("Please select a valid excel file.");
      } else {
        this.openSnackBar("Please select the file to upload");
      }
    }
  }

  render() {
    const { classes } = this.props;
    const Transition = React.forwardRef(function Transition(props, ref) {
      return <Slide direction="down" ref={ref} {...props} />;
    });
    const CustomRadioLabel = withStyles(() => ({
      label: {
        fontSize: '14px',
        fontWeight: 'normal'
      },
    }))(FormControlLabel);
    const CustomRadio = withStyles(() => ({

    }))(Radio);
    const CustomFormLabel = withStyles(() => ({

    }))(FormLabel);
    return (
      <div className={classNames(classes.sections)} >
        <div className={classNames(classes.container, classes.mainTray)}>
          <div id="inputs">
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}
                style={{ display: 'flex', padding: '0px' }}
              >
                <GridContainer style={{ width: '100%', margin: '0' }}>
                  <GridItem xs={12} sm={6} style={{ display: 'flex', flexDirection: 'row', padding: '0' }}>
                    <Button style={{ marginBottom: '0', fontWeight: 'bold', color: '#252326' }} color="warning" onClick={() => this.setState({ NewMerchModal: true })}>Add Business</Button>
                  </GridItem>
                  <GridItem xs={12} sm={6} style={{ display: 'flex', flexDirection: 'row', padding: '0' }}>
                    {this.state.isAuth ? (
                      <>
                        <Button style={{ marginBottom: '0', fontWeight: 'bold', color: '#252326' }} color="warning" aria-controls="simple-menu" aria-haspopup="true"
                          onClick={this.handleUploadClick} >
                          Upload Weekly File
                      </Button>
                        <Menu id="simple-menu" anchorEl={this.state.updloadMenu} keepMounted={false}
                          open={Boolean(this.state.updloadMenu)}
                          onClose={this.handleUploadMenuClose}>
                          <MenuItem onClick={() => this.handleUploadMenuSelect(1)}>New Businesses</MenuItem>
                          <MenuItem onClick={() => this.handleUploadMenuSelect(2)}>Businesses To Disable</MenuItem>
                        </Menu>
                      </>
                    ) : null}
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}
                className={classes.searchArea}
                style={{ display: 'flex', alignItems: 'flex-end', padding: '0px' }}
              >
                <span xs={8} sm={7} md={8} lg={8} style={{ display: 'inline' }}>
                  <FormControl
                    style={{ width: '100%' }}
                  // className={clsx(classes.margin, classes.textField)}
                  >
                    <InputLabel htmlFor="searchMerchant">Business Name</InputLabel>
                    <Input
                      id="searchMerchant"
                      name='searchMerchant'
                      onKeyPress={this.handleKeyPress}
                      value={this.state.searchMerchant || ''}
                      onChange={(e) => this.setState({ searchMerchant: e.target.value })}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            size='small'
                            aria-label="clear search input"
                            onClick={() => { this.setState({ searchMerchant: '' }) }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </span>

                <span xs={4} sm={5} md={4} lg={4} style={{ display: 'inline' }}>
                  <Button color="warning" style={{ fontWeight: 'bold', color: '#252326', margin: '0', width: '100%' }} className={classes.verticalMargin} onClick={this.handleSearchMerchant}>Search</Button>
                </span>
              </GridItem>
            </GridContainer>
          </div>
          <div className={classes.space30} />
          {this.state.isLoading ?
            <CircularProgress size='20%' color='primary' style={{ margin: '15% 40%' }} />
            :
            <div
              className={classes.merchResults}
              style={{ marginTop: '1rem', overflowY: 'auto' }}>
              {this.state.MerchantList ? (
                <>
                  <span>
                    <Chip label={'Results: ' + this.state.MerchantList.length} />
                  </span>

                  {this.state.MerchantList.length > 0 ? (
                    <div className={classes.root}>
                      {this.state.MerchantList.map((Merchant, i) => {
                        return (
                          <ExpansionPanel key={Merchant.merchantName + '-' + i}
                            expanded={this.state.expanded === 'panel' + i}
                            onChange={this.handlePanelChange('panel' + i)}>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header" style={{ width: '100%' }}>
                              <Typography component={'span'} className={classes.heading} style={{ width: '100%' }}>
                                <span className={classes.merchHeader} style={{ width: '100%' }}>
                                  <h4 style={{ margin: '0', fontWeight: 'bold' }}>{Merchant.merchantName} (ID: {Merchant.merchantID})</h4>
                                  <span>
                                    {/* <TouchRipple> */}
                                    <Button
                                      color='success'
                                      onClick={() => {
                                        console.log(Merchant.merchantID)
                                        let d = {
                                          merchantID: Merchant.merchantID,
                                          idToken: this.props.user.idToken
                                        }
                                        api.loginMerchant(d)
                                          .then(res => {
                                            console.log(res);
                                            res.data.role = 'manager';
                                            var activeUser = {
                                              idToken: this.props.user.idToken,
                                              role: 'manager',
                                              userInfo: res.data
                                            };
                                            this.setState({ activeMerchant: activeUser, merchantOpen: true }, () => console.log(this.state.activeMerchant))
                                          })
                                          .catch(err => {
                                            console.log(err)
                                          })
                                      }}
                                    >Login</Button>
                                    {/* </TouchRipple> */}
                                  </span>
                                </span>
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              {/* <Typography component={'span'}> */}
                              <span style={{ width: '100%', padding: '0 10px 10px 10px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start' }}>

                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                  <h4 style={{ margin: '0' }}>
                                    {Merchant.merchantName}
                                  </h4>
                                  <div style={{ display: 'flex' }}>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                      {/* <MapIcon style={{ marginRight: '10px' }} /> */}
                                      {`${Merchant.address1}, ` || ''}
                                    </span>
                                    <span style={{ display: 'flex', alignItems: 'center', marginLeft: ".25rem" }}>
                                      {Merchant.city || 'City'}, {Merchant.state || 'State'} {Merchant.zip || 'Zip'}
                                    </span>
                                  </div>

                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '15px' }}>
                                  <h6 style={{ margin: '0' }}>Contact</h6>
                                  <div>
                                    <span style={{ display: 'flex', alignItems: '', margin: "" }}>
                                      <AccountCircleIcon style={{ marginRight: '10px', color: '#0476B5' }} /> {Merchant.contactName ? (Merchant.contactName) : ('')}
                                    </span>
                                    <span style={{ display: 'flex', alignItems: 'center', margin: "" }}>
                                      <PhoneIcon style={{ marginRight: '10px', color: '#0476B5' }} /> {Merchant.phone ? (Merchant.phone) : ('')}
                                    </span>
                                    <span style={{ display: 'flex', alignItems: 'center', margin: "" }}>
                                      <EmailIcon style={{ marginRight: '10px', color: '#0476B5' }} /> {Merchant.merchantEmail ? (Merchant.merchantEmail) : ('')}
                                    </span>
                                    <span style={{ display: 'flex', alignItems: 'center', margin: "" }}>
                                      <LanguageIcon style={{ marginRight: '10px', color: '#0476B5' }} /> {Merchant.website ? (Merchant.website) : ('')}
                                    </span>
                                  </div>

                                </div>
                              </span>
                              {/* </Typography> */}
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        )
                      })}
                    </div>
                  ) : (<div style={{ textAlign: 'center', margin: '3rem auto' }}>{this.state.MerchantListErr}</div>)}
                </>
              ) : (
                  <div style={{ textAlign: 'center', margin: '3rem auto', color: this.state.MerchantListErr && 'red' }}>
                    {this.state.MerchantListErr || 'Use the Searchbar to find Businesses!'}
                  </div>
                )
              }
            </div>
          }

        </div>
        {/* Campaign Dialog */}
        {this.state.NewMerchModal ? (
          <Dialog
            open={this.state.NewMerchModal}
            // TransitionComponent={Transition}
            className={classes.dialog}
            keepMounted
            onClose={this.clearMerchModal}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description">

            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}>
              <IconButton
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.clearMerchModal}>
                <Close className={classes.modalClose} />
              </IconButton>
              <h4 className={classes.modalTitle} style={{ textAlign: 'center' }}>Create Manager</h4>
            </DialogTitle>
            <DialogContent id="modal-slide-description" className={classes.modalBody} style={{ maxWidth: '100%' }}>
              <div>
                <form className={classes.root} autoComplete="off">
                  <span>
                    <h4>Manager Info</h4>
                    <div style={{ margin: "7px 0px" }}>
                      <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                        <TextField required error={this.state.newManagerNameErr ? true : false} helperText={this.state.newManagerNameErr ? this.state.newManagerNameErr : null} name="newManagerName" onFocus={() => this.setState({ focusedInput: "ManagerName" })} label="Manager Name" value={this.state.newManagerName} onChange={this.handleInputChange} variant="filled" />
                      </span>
                      <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                        <TextField required error={this.state.newEmailErr ? true : false} helperText={this.state.newEmailErr ? this.state.newEmailErr : "This email will be used  by the new manager to login"} name="newEmail" onFocus={() => this.setState({ focusedInput: "email" })} label="Manager Email" value={this.state.newEmail} onChange={this.handleInputChange} variant="filled" />
                      </span>
                      <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                        <TextField required error={this.state.newPasswordErr ? true : false} helperText={this.state.newPasswordErr ? this.state.newPasswordErr : null} name="newPassword" onFocus={() => this.setState({ focusedInput: "password" })} label="Password" value={this.state.newPassword} onChange={this.handleInputChange} variant="filled" />
                      </span>
                    </div>
                    <Divider />
                    <h4>Business Info</h4>
                    <div style={{ margin: "7px 0px" }}>
                      <span style={{ margin: '7px 15px 7px 0px' }}>
                        <TextField required error={this.state.newMerchantNameErr ? true : false} helperText={this.state.newMerchantNameErr ? this.state.newMerchantNameErr : null} name="newMerchantName" onFocus={() => this.setState({ focusedInput: "MerchantName" })} label="Merchant Name" value={this.state.newMerchantName} onChange={this.handleInputChange} variant="filled" />
                      </span>
                    </div>
                    {/* select category */}
                    <h4>Category</h4>
                    <div style={{ margin: '7px 0px' }}>
                      <span style={{ margin: '7px 15px 7px 0px' }}>
                        <FormControl
                          variant="filled"
                          className={classes.formControl}
                          required
                          error={this.state.selectedCatErr ? true : false}
                        >
                          <InputLabel id="catSelectLabel">Category</InputLabel>
                          <Select
                            autoWidth
                            required
                            labelId="catSelectLabel"
                            id="catSelect"
                            value={this.state.selectedCat}
                            onChange={(e) => { console.log(e.target.value); this.setState({ selectedCat: e.target.value, selectedSubCat: '', selectedCatErr: '', selectedSubCatErr: '' }) }}
                          >
                            <MenuItem style={{ display: 'none' }} disabled value='' />
                            {this.state.catOptions.map(cat => {
                              return <MenuItem key={cat.categoryID} value={cat}>{cat.categoryName}</MenuItem>
                            })}
                          </Select>
                          {this.state.selectedCatErr ? <FormHelperText>{this.state.selectedCatErr}</FormHelperText> : null}

                        </FormControl>
                      </span>
                      {this.state.selectedCat &&
                        <span style={{ margin: '7px 15px 7px 0px' }}>
                          <FormControl
                            variant="filled"
                            className={classes.formControl}
                            required
                            error={this.state.selectedSubCatErr ? true : false}
                          >
                            <InputLabel id="subCatSelectLabel">Subcategory</InputLabel>
                            <Select
                              required
                              labelId="subCatSelectLabel"
                              id="subCatSelect"
                              value={this.state.selectedSubCat}
                              onChange={(e) => { this.setState({ selectedSubCat: e.target.value, selectedSubCatErr: '' }) }}
                            >
                              <MenuItem style={{ display: 'none' }} disabled value='' />
                              {this.state.subCatOptions.map(subCat => {
                                if (subCat.categoryID === this.state.selectedCat.categoryID) {
                                  return <MenuItem key={subCat.subCategoryID} value={subCat}>{subCat.subCategoryName}</MenuItem>
                                }
                              })}
                            </Select>
                            {this.state.selectedSubCatErr ? <FormHelperText>{this.state.selectedSubCatErr}</FormHelperText> : null}

                          </FormControl>
                        </span>
                      }
                    </div>
                    <h4>Affiliated Church</h4>
                    <div style={{ margin: "7px 0px" }}>

                      <span
                        style={{ margin: '7px 15px 7px 0px' }}
                      >
                        <FormControl
                          component='fieldset'>
                          <CustomFormLabel component='legend'>
                            Search by...
                          </CustomFormLabel>
                          <RadioGroup row aria-label='search-setting' name='searchsetting' value={this.state.searchSetting} onChange={(e) => { console.log(e.target.value); this.setState({ searchSetting: e.target.value }) }}>
                            <CustomRadioLabel value='name' control={<CustomRadio size='small' style={{ color: '#0476B5' }} />} label='Name' />
                            <CustomRadioLabel value='id' control={<CustomRadio size='small' style={{ color: '#0476B5' }} />} label='ID' />
                          </RadioGroup>
                        </FormControl>
                      </span>
                    </div>

                    <div style={{ margin: "7px 0px" }}>
                      <span
                        // style={{ margin: '7px 15px 7px 0px' }}
                        id='searchOrgInput'
                      >
                        <TextField
                          required
                          name="searchOrgInput"
                          label="Search Churches"
                          value={this.state.searchOrgInput || ''}
                          onChange={(e) => this.setState({ searchOrgInput: e.target.value })}
                          variant="filled"
                          error={this.state.searchOrgListErr ? true : false}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              this.handleSearchOrgs();
                            }
                          }}
                          helperText={this.state.searchOrgListErr ? this.state.searchOrgListErr : (`Search by Organization ${this.state.searchSetting}`)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  // light
                                  size='small'
                                  aria-label="clear search input"
                                  onClick={() => { this.setState({ searchOrgInput: '' }) }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                        <Button id='searchOrgBtn' color="primary" edge='end' aria-label="search" style={{ margin: '0px', padding: '19px', backgroundColor: '#0476B5' }}
                          onClick={this.handleSearchOrgs}
                        >
                          <SearchIcon fontSize='large' />
                        </Button>
                      </span>
                      {this.state.searchOrgList.length > 0 &&
                        <span style={{ margin: '7px 15px 7px 0px' }}>
                          <FormControl
                            variant="filled"
                            className={classes.formControl}
                            required
                            error={this.state.selectedOrgErr ? true : false}
                            id='searchOrgSelect'
                          >
                            <InputLabel id="orgSelectLabel">Select Church</InputLabel>
                            <Select
                              placeholder='(ID) Name - Location'
                              required
                              labelId="orgSelectLabel"
                              id="orgSelect"
                              value={this.state.selectedOrg}
                              onChange={(e) => { this.setState({ selectedOrg: e.target.value, selectedOrgErr: '' }) }}
                            >
                              <MenuItem disabled value=''>(ID) Name - Location</MenuItem>
                              {this.state.searchOrgList.map(org => {
                                return <MenuItem key={org.organizationID} value={org.organizationID}>({org.churcH_ID}) {org.organizationName} - {org.city && `${org.city}, `}{org.state && org.state} {org.zip}</MenuItem>
                              })}
                            </Select>
                            <FormHelperText> {this.state.selectedOrgErr ? this.state.selectedOrgErr : `${this.state.searchOrgList.length} Results`} </FormHelperText>
                          </FormControl>
                        </span>
                      }

                    </div>

                    <h4 style={{ marginBottom: '0px' }}>Address Info</h4>
                    <Typography variant='caption'>Required so users can find you on the map!</Typography>
                    <div style={{ margin: "7px 0px" }}>
                      <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                        <TextField required error={this.state.newAddress1Err ? true : false} helperText={this.state.newAddress1Err ? this.state.newAddress1Err : null} name="newAddress1" onFocus={() => this.setState({ focusedInput: "address1" })} label="Address" value={this.state.newAddress1} onChange={this.handleInputChange} variant="filled" />
                      </span>
                      <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                        <TextField required error={this.state.newCityErr ? true : false} helperText={this.state.newCityErr ? this.state.newCityErr : null} name="newCity" onFocus={() => this.setState({ focusedInput: "city" })} label="City" value={this.state.newCity} onChange={this.handleInputChange} variant="filled" />
                      </span>
                    </div>
                    <div style={{ margin: "7px 0px" }}>
                      <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                        <TextField required error={this.state.newStateErr ? true : false} helperText={this.state.newStateErr ? this.state.newStateErr : null} name="newState" onFocus={() => this.setState({ focusedInput: "state" })} label="State" value={this.state.newState} onChange={this.handleInputChange} variant="filled" />
                      </span>
                      <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                        <TextField required error={this.state.newZipErr ? true : false} helperText={this.state.newZipErr ? this.state.newZipErr : null} name="newZip" onFocus={() => this.setState({ focusedInput: "zip" })} label="Zip Code" value={this.state.newZip} onChange={this.handleInputChange} variant="filled" />
                      </span>
                    </div>
                    <h4>Business Contact Info</h4>
                    <div style={{ margin: "7px 0px" }}>
                      <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                        <TextField error={this.state.newContactNameErr ? true : false} helperText={this.state.newContactNameErr ? this.state.newContactNameErr : null} name="newContactName" onFocus={() => this.setState({ focusedInput: "merchantContactName" })} label="Contact Name" value={this.state.newContactName} onChange={this.handleInputChange} variant="filled" />
                      </span>
                      <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                        <TextField error={this.state.newPhoneErr ? true : false} helperText={this.state.newPhoneErr ? this.state.newPhoneErr : null} name="newPhone" onFocus={() => this.setState({ focusedInput: "merchantPhone" })} label="Phone" value={this.state.newPhone} onChange={this.handleInputChange} variant="filled" />
                      </span>
                    </div>
                    <div style={{ margin: "7px 0px" }}>
                      <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                        <TextField error={this.state.newMerchantEmailErr ? true : false} helperText={this.state.newMerchantEmailErr ? this.state.newMerchantEmailErr : null} name="newMerchantEmail" onFocus={() => this.setState({ focusedInput: "MerchantEmail" })} label="Merchant Email" value={this.state.newMerchantEmail} onChange={this.handleInputChange} variant="filled" />
                      </span>
                      <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                        <TextField error={this.state.newWebsiteErr ? true : false} helperText={this.state.newWebsiteErr ? this.state.newWebsiteErr : null} name="newWebsite" onFocus={() => this.setState({ focusedInput: "website" })} label="Website" value={this.state.newWebsite} onChange={this.handleInputChange} variant="filled" />
                      </span>
                      {/* <span style={{ margin: '7px 15px 7px 0px', width: '350px' }}>
                            <TextField error={this.state.newLogoFileErr ? true : false} helperText={this.state.newLogoFileErr ? "Invalid Entry" : null} name="newLogoFile" onFocus={() => this.setState({ focusedInput: "logo" })} label="Logo" value={this.state.newLogoFile} onChange={this.handleInputChange} variant="filled" />
                          </span> */}
                    </div>
                    <Divider />
                  </span>
                </form>
              </div>
            </DialogContent>
            <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
              {!this.state.isLoading ? (
                <span>
                  <Button onClick={this.cancelMerchantCreation}>Cancel</Button>
                  <Button onClick={this.saveNewM} color="success">Save</Button>
                </span>
              ) : <CircularProgress />}
            </DialogActions>
          </Dialog>
        ) : null}

        {/* manager dialog */}
        <Dialog
          //keepMounted
          fullScreen
          open={this.state.merchantOpen}
          // TransitionComponent={Transition}
          onClose={this.handleHideLogin} >
          <AppBar className={classes.appBar} style={{ zIndex: '9000', background: '#00784A' }}>
            <Toolbar color='success'>
              <IconButton edge="start" color="inherit" onClick={this.handleHideLogin} aria-label="close">
                <CloseIcon />
              </IconButton>
              <h6>
                Manager: {this.state.activeMerchant.userInfo ? `${this.state.activeMerchant.userInfo.managerName} | ${this.state.activeMerchant.userInfo.email}` : ''}
              </h6>
              {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}

            </Toolbar>
          </AppBar>
          {/* <DialogContent> */}
          <span>
            <Manager user={this.state.activeMerchant} setUser={(u) => this.props.setUser(u)} sessionCounter={this.props.sessionCounter} setSessionCounter={(t) => this.props.setSessionCounter(t)} showTimeOutWarning={() => this.props.showTimeOutWarning()} type={'admin'} />
          </span>
          {/* </DialogContent> */}
        </Dialog>
        {/* manager dialog */}
        <Dialog
          className={classes.dialog}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          keepMounted
          fullScreen={false}
          open={this.state.openUploadDialog}
          onClose={this.handleUploadDialogClose} >
          <AppBar className={classes.appBar} style={{ zIndex: '9000', background: 'rgb(4, 118, 181)' }}>
            <Toolbar color='primary'>
              <IconButton edge="start" color="inherit" onClick={this.handleUploadDialogClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <h6>
                Business List File Upload
            </h6>
              {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}

            </Toolbar>
          </AppBar>
          <DialogContent style={{ width: '400px', height: '200px' }}>
            <span style={{ width: '375px', height: '150px', display: 'flex', flexDirection: 'column' }}>
              <span style={{ marginBottom: '15px' }}>
                {this.state.updloadType == 1 ? (
                  <h4>Please attached the file that contains the list of <span style={{ color: 'red', fontWeight: 'bolder' }}>Business to Add</span></h4>
                ) : (
                    <h4>Please attached the file that contains the list of <span style={{ color: 'red', fontWeight: 'bolder' }}>Business to Deactivate</span></h4>
                  )}
              </span>
              <span>
                <label htmlFor="upload-merchant-list">
                  <input style={{ display: 'none' }} id="upload-merchant-list" name="upload-merchant-list" type="file" accept=".xlsx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => this.handleFileUpLoadChange(e)} />
                  {this.state.uploadFileErr ? (
                    <span >
                      <Fab color="primary" size="medium" component="span" aria-label="add" variant="extended" style={{ backgroundColor: '#0476B5' }}>
                        <BlockIcon style={{ color: 'red', marginRight: '7px' }} /> {this.state.uploadFile.name}
                      </Fab>
                      <span style={{ color: 'red', fontSize: '1rem', display: 'block', textAlign: 'end', marginTop: '10px' }}>
                        File not supported. Click to select an excell file.
                  </span>
                    </span>
                  ) : (
                      <Fab color="primary" size="medium" component="span" aria-label="add" variant="extended" style={{ backgroundColor: '#0476B5' }}>
                        <AddPhotoAlternateIcon style={{ marginRight: '7px' }} /> {this.state.uploadFile ? this.state.uploadFile.name : 'Click to select a file'}
                      </Fab>
                    )}
                </label>
              </span>
            </span>
          </DialogContent>
          <DialogActions>
            {!this.state.isFileUploading ? (<Button onClick={() => this.handleUploadDialogClose()}>Cancel</Button>) : null}
            {!this.state.isFileUploading ? (<Button color="primary" onClick={() => this.handleUploadFile()}>Save</Button>) : null}
            {this.state.isFileUploading ? (<span><CircularProgress size="20px" style={{ margin: '0 7px' }}/>Uploading...</span>) : null}
          </DialogActions>
        </Dialog>

        {this.state.shouldSnackBarOpen ? (
          <Portal>
            <Snackbar
              // anchorOrigin={{ "bottom", "center" }}
              open={this.state.shouldSnackBarOpen}
              onClose={this.handleSnackBarClose}
              message={this.state.snackMsg}
              key="key"
            />
          </Portal>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SectionMerchants);