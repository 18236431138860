import React, { useEffect, Component } from "react";
import { Redirect, Link } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import HeaderRightLinks from "components/Header/HeaderRightLinks.js";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.js";

// JPP API
import api from '../../utils/api';

// FireBase
import firebase from '../../config/firebase';

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/FF_map_only.png";
import { CircularProgress } from "@material-ui/core";

import findingFaberLogo from '../../assets/img/FindingFaber-Logo/FFapp_icon only.png'

class LoginPage extends Component {
  // export default function LoginPage(props) {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      password: "",
      isFirebaseInit: false,
      activeUser: "",
      classes: null,
      isLoading: false,
      isRedirectToMerchant: false,
      isRedirectToAdmin: false,
      loginErrMsg: null,
      emailErr: null,
      forgotPw: false,
      emailSent: false
    }
  }

  firebaseLogin = async function (dt) {
    try {
      var firebaseUserData = await firebase.login(dt);
      //      console.log("firebaseUserData: ", firebaseUserData);
      var t = await firebase.getUserData();
      if (t.status == 200) {
        // console.log("data: ", t);
        api.login(t.idToken).then(res => {
          console.log("JPPC Server response: ", res);
          if (res.data) {
            var activeUser = {
              idToken: t.idToken,
              role: res.data.role,
              userInfo: res.data,
              userEmail: this.state.email
            }
            this.props.setUser(activeUser);
            this.setState({ activeUser: activeUser }, () => {
              if (activeUser.role == 'manager') {
                this.setState({ isRedirectToMerchant: true });
              } else if (activeUser.role == 'admin') {
                this.setState({ isRedirectToAdmin: true });
              }
            });
          }
        }).catch(err => {
          console.log(err);
        });
      } else {
        console.log(t)
        this.setState({ loginErrMsg: 'Invalid Credentials. No user found!', isLoading: false });
      }

    } catch (err) {
      console.log(err);
      return err;
    }
  }

  handleLogin = () => {
    // console.log("email: ", this.state.email);
    // console.log("Password: ", this.state.password);
    var data = {
      email: this.state.email,
      password: this.state.password
    }
    this.setState({ isLoading: true, loginErrMsg: '' })
    this.firebaseLogin(data).then((t) => {
      console.log("data: ", t);
      if (t) {
        if (t.message) {
          this.setState({ loginErrMsg: 'Invalid Credentials.', isLoading: false });
        }
      }
    })
  }

  handleSendReset = () => {

    if (this.state.email) {
      this.setState({ isLoading: true })
      console.log("email: ", this.state.email);
      api.sendPwReset(this.state.email)
        .then(res => {
          if (res.status === 200) {
            console.log('sent');
            this.setState({
              emailSent: true,
              isLoading: false,
              emailErr: null,
              loginErrMsg: null
            })
          } else {
            //if api call bad
            console.log(res.message);
            this.setState({
              emailSent: false,
              isLoading: false,
              emailErr: true,
            })
          }
        }).catch(err => {
          console.log(`error: ${err}`)
          this.setState({
            emailSent: false,
            isLoading: false,
            loginErrMsg: `Sorry we're having trouble with this service. Please try again later or contact us!`
          })
        })
    }
  }

  handleShowResetScreen = () => {
    this.setState({
      forgotPw: true,
      email: '',
      password: '',
      loginErrMsg: null,
      emailErr: null
    })
  }

  handleHideResetScreen = () => {
    this.setState({
      email: '',
      password: '',
      forgotPw: false,
      emailSent: false,
      loginErrMsg: null,
      emailErr: null
    })
  }

  handleInputChange = (type, event) => {
    switch (type) {
      case 'email': {
        this.setState({ email: event.target.value })
        break;
      }
      case 'password': {
        this.setState({ password: event.target.value })
        break;
      }
      default: break;
    }
  }

  componentDidMount() {
    console.log(this.state.activeUser);
    setTimeout(() => {
      this.setState({ cardAnimaton: "" });
    }, 700);
    if (!this.state.activeUser) {

      this.getUserData()
    }
  }

  getUserData = async () => {
    var t = await firebase.getUserData();
    if (t.status == 200) {
      console.log("data: ", t);
      api.login(t.idToken).then(res => {
        console.log("JPPC Server response: ", res);
        var activeUser = {
          idToken: t.idToken,
          role: res.data.role,
          userInfo: res.data,
          userEmail: t.email
        }
        this.props.setUser(activeUser);
        this.setState({ activeUser: activeUser }, () => {
          if (activeUser.role == 'manager') {
            this.setState({ isRedirectToMerchant: true });
          } else if (activeUser.role == 'admin') {
            this.setState({ isRedirectToAdmin: true });
          }
        });
      });
    }
  }


  render() {
    const { classes } = this.props;
    return (
      <div
        style={{
          minHeight: '100vh',
          // backgroundImage: "url(" + image + ")",
          //       backgroundSize: "100%",
          //       backgroundPosition: "bottom", 
          //       // minHeight: '100vh', 
          //       backgroundRepeat: 'no-repeat',
          display: 'flex', flexDirection: 'column'
        }}
      >
        {this.state.isRedirectToAdmin ? <Redirect to='/admin' /> : null}
        {this.state.isRedirectToMerchant ? <Redirect to='/Manager' /> : null}
        <Header
          absolute
          color="white"
          brand=""
          user={this.props.user}
          setUser={(u) => this.props.setUser(u)}
          // rightLinks={<HeaderRightLinks user={this.props.user} setUser={(u) => this.props.setUser(u)} />}
          leftLinks={<HeaderLeftLinks user={this.props.user} setUser={(u) => this.props.setUser(u)} />}
        // {...rest}
        />
        {/* {this.state.isLoading ? ( */}
        {/* <div
          // className={classes.pageHeader}
          // style={{
          //   backgroundImage: "url(" + image + ")",
          //   backgroundSize: "100%",
          //   backgroundPosition: "bottom", 
          //   // minHeight: '100vh', 
          //   backgroundRepeat: 'no-repeat',
          //   display: 'flex', flexDirection: 'column',
          // }}
          > */}

        <div className={classes.container} style={{ display: 'flex', justifyContent: 'center' }}>
          {/* <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}> */}
          <img src={require('assets/img/FF_map_only.png')}
            style={{position: 'fixed', zIndex: '-10', width: '100%', bottom: '0', opacity: '5%'}}
          />
          <Card id='loginCard' className={classes[this.state.cardAnimaton]} style={{ border: '1px solid black' }}>
            <form className={classes.form}>
              {/* <CardHeader color="warning" className={classes.cardHeader}>
                      {this.state.forgotPw ? (<h4>Reset Password</h4>) : (<h4>Login</h4>)}
                    </CardHeader> */}
              <CardBody style={{ textAlign: 'center' }}>
                {this.state.forgotPw ? (<h4>Reset Password</h4>) : (<h4>Business Owner Login</h4>)}
                {this.state.loginErrMsg ? (
                  <span style={{ color: 'red', fontSize: 'medium' }}>
                    {this.state.loginErrMsg}
                  </span>
                ) : (
                    <>
                      {this.state.forgotPw && <p style={{ textAlign: 'center' }}>
                        Tell us your email, and we'll send a reset link.
                         </p>}
                    </>
                  )}

                <CustomInput
                  error={this.state.emailErr ? true : false}
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "email",
                    value: this.state.email,
                    onChange: (event) => this.handleInputChange('email', event),
                    // autoFocus: 'true',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputIconsColor} />
                      </InputAdornment>
                    )
                  }}
                />
                {this.state.emailErr ? <p style={{ color: 'red', textAlign: 'center', marginTop: '-1rem', fontSize: '10px' }}>Email not in our system. Please try again!</p> : null}
                {!this.state.forgotPw &&

                  <CustomInput
                    labelText="Password"
                    id="pass"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "password",
                      value: this.state.password,
                      onChange: (event) => this.handleInputChange('password', event),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputIconsColor}>
                            lock_outline
                            </Icon>
                        </InputAdornment>
                      ),
                      autoComplete: "on"
                    }}
                  />
                }

                {!this.state.forgotPw && <span
                  style={{ fontStyle: 'italic', fontFamily: "'EB Garamond', serif", fontWeight: 'bold', marginBottom: '', cursor: 'pointer', color: '#0476B5' }}
                  color='primary'
                  component="button"
                  variant="body2"
                  onClick={this.handleShowResetScreen}
                >
                  Forgot Password?
                      </span>
                }

              </CardBody>

              <CardFooter className={classes.cardFooter}>

                {!this.state.forgotPw ? (<>
                  {!this.state.isLoading ? (
                    <Button color="success" size="lg" onClick={this.handleLogin} fullWidth>
                      Login
                    </Button>
                  ) : <CircularProgress />}</>) : (
                    <div style={{ display: 'flex', flexDirection: 'row', margin: '0', width: '100%', justifyContent: 'space-between' }}>
                      <span
                        style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center', justifySelf: 'flex-start', cursor: 'pointer' }}
                        component="button"
                        variant="body2"
                        onClick={this.handleHideResetScreen}
                      >
                        <ArrowBackIosIcon fontSize='small' style={{ margin: '0 -.3rem' }}>

                        </ArrowBackIosIcon> <span>Back</span>
                      </span>

                      {!this.state.isLoading ? (
                        <Button color={!this.state.emailSent ? 'success' : ''} size="sm" style={{ justifySelf: 'center' }} onClick={this.handleSendReset}>
                          {!this.state.emailSent ? (
                            'Send'
                          ) : ('Sent')}</Button>
                      ) : (
                          <CircularProgress />
                        )}

                    </div>
                  )
                }


              </CardFooter>
            </form>
          </Card>
          {/* </GridItem>
            </GridContainer> */}
        </div>
        <Footer darkFont />
        {/* </div> */}
        {/* ) : (<CircularProgress />)} */}
      </div>
    )
  }
}


export default withStyles(styles, { withTheme: true })(LoginPage);


  // Get User Session
  // firebase.isInitialized().then(user => {
  //   console.log("user: ", user);
  //   if (user) {
  //     // setIsUser(true)
  //   } else {
  //     // setIsUser(false);
  //   }
  // });

  // Creating user Not Used here
  // async function registerMerchant(dt){
  //   try {
  //     await firebase.register(dt)
  //   } catch(err){
  //     console.log(err);
  //   }
  // }