import React, { Component } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderRightLinks from "components/Header/HeaderRightLinks.js";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.js";

import './PrivacyPage.css';
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
const useStyles = makeStyles(styles);
const dashboardRoutes = [];

class PrivacyPage extends Component {
    // const classes = useStyles();

    componentDidMount() {
        window.scrollTo(0, 0);
    };

    render() {
        const { classes } = this.props;
        return (
            <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
                <Header
                    color="white"
                    routes={dashboardRoutes}
                    rightLinks={<HeaderRightLinks />}
                    leftLinks={<HeaderLeftLinks />}
                    fixed
                    changeColorOnScroll={{
                        height: 400,
                        color: "white"
                    }}
                //{...rest}
                />
                <div className={classes.container} style={{ marginTop: '100px', minHeight: '60vh' }}>
                    <img src={require('assets/img/FF_map_only.png')}
                        style={{ position: 'fixed', zIndex: '-10', width: '100%', bottom: '0', opacity: '3%' }}
                    />
                    <h2 style={{ fontWeight: '600', color: '#00784A', borderBottom: '3px solid #FECE00' }}>Terms &amp; Conditions</h2>
                    <div id='' style={{}}>

                        <p>The following conditions apply to the use of the Finding Faber service.</p>

                        <p>Effective Date: August 31,2020</p>

                        <p>
                            John Patrick Publishing Company (hereinafter referred to as JPP) and its subsidiary Finding Faber provide access to business information and digital coupons for independent merchants and professionals in the United States of America  which includes, and is accessible via,  the websites published at <a href='https://www.findingfaber.com' target='_blank'>https://www.findingfaber.com</a> (and any other websites owned or operated by JPP link to these Terms (collectively, the "Applications"). The Services, for example, allow users to access promotions of merchants’ and professionals’ service discounts or other,  for online or in-store use.
                        </p>

                        <p>
                            By using the Services, you agree to comply with and be legally bound by the terms and conditions of these Terms of Use ("Terms") as presented to you, the user, without any changes. These Terms govern your access to and use of the Services, and constitute a binding legal agreement between you and the merchant or professional practice. If you do not agree to these terms, please do not use the Services. Your agreement with us regarding compliance with these Terms becomes effective immediately upon commencement of your use of the Services.
                        </p>

                        <p>
                            Promotions as hereafter defined are available to individual users of Finding Faber. Participation in any promotion are offered at the sole discretion of the merchant. Finding Faber has the right to remove, change these promotion terms in whole or at any time, without notice.  JPP is not responsible for language included in any merchant or professional coupons, special or promotion ,and reserves the right to modify word choice or images used if JPP deems it necessary.
                        </p>

                        <p>
                            The merchant and professional practices can run up to three concurrent promotions at a given time. Promotions need not have an end date, but if initiated by the merchant, it is expected to be honored at point of purchase. Any disagreement should be handled between the merchant and consumer. The merchant is responsible for updating the Promotion Landing Page including any changes to hours of operation, and assumes risks of misinformation of said promotion, availability and hours or operation.
                        </p>

                        <p>
                            Reselling or copying Finding Faber merchant promotions for other uses, or commercial use is strictly forbidden.
                        </p>

                        <h6 style={{ fontWeight: '700' }}>CHANGES TO THE TERMS</h6>
                        <p>
                            We may modify the Terms from time to time. The most current version of the Terms will be located here. You understand and agree that your access to or use of the Service is governed by the Terms effective at the time of your access to or use of the Service.
                        </p>

                        <h6 style={{ fontWeight: '700' }}>Eligibility</h6>
                        <p>
                            {/* <span style={{ fontWeight: '600' }}>Eligibility.</span>  */}
                            To access or use the Service, you must agree to these Terms. You may not access or use the Service if you are a competitor of JPP or if we have previously banned you from the Service or closed your Account.
                        </p>

                        <h6 style={{ fontWeight: '700' }}>Permission to Use the Service</h6>
                        <p>
                            {/* <span style={{ fontWeight: '600' }}>Permission to Use the Service.</span>  */}
                            We grant you permission to use the Service subject to these Terms. Your use of the Service is at your own risk, including the risk that you might be exposed to content that is offensive, indecent, inaccurate, objectionable, incomplete, fails to provide adequate warning about potential risks or hazards, or is otherwise inappropriate.
                        </p>

                        <h6 style={{ fontWeight: '700' }}>Accounts</h6>
                        <p>
                            {/* <span style={{ fontWeight: '600' }}>Accounts.</span>  */}
                            You must create an Account and provide certain information about yourself in order to use some of the features that are offered through the Service. You are responsible for maintaining the confidentiality of your Account password.  Business owner is responsible for all activities that occur in connection with your Account. You agree to notify us immediately of any unauthorized use of your Account. You may not impersonate someone else, provide an email address other than your own, create multiple Accounts, or transfer your account to another business or professional practice without Finding Faber’s prior approval.
                        </p>

                        <h6 style={{ fontWeight: '700' }}>Privacy</h6>
                        <p>
                            {/* <span style={{ fontWeight: '600' }}>Privacy.</span>  */}
                            At JPP and Finding Faber, we take online privacy seriously and we respect the concerns of our community of users. We do not collect or store personal data on any of our users, nor do we allow outside sources to access any personal contact information. The only data that is stored is the name of the business establishment and contact information, i.e. business phone and email address. This data is maintained by the business administrator and can be updated at their discretion. We do not provide, sell, or rent to any third party, any personal information about our users. Should a consumer opt-in to the location services, only the location will be used in order to present promotions in that specific area. Our service may have the ability to use your geographic location to deliver content, services and promotions tailored to your location. The safety and security of your personal information also depends on you. Although we take measures to protect your personal information, no security system is impenetrable. Be mindful when using public computers and internet networks to access Finding Faber, as we are not liable for any information that may be tampered with. This privacy statement is subject to updates when necessary.
                        </p>

                        <h6 style={{ fontWeight: '700' }}>REPRESENTATIONS AND WARRANTIES</h6>
                        <p>
                            We are under no obligation to enforce the Terms on your behalf against a user. While we encourage you to let us know if you believe a promotion is in error or has been misused, or in some other way a user has violated the Terms, we reserve the right to investigate and take appropriate action at our sole discretion. Finding Faber is the proprietary property of John Patrick Publishing Company. While it partners with the churches for which it has other contracted business, it shares no legal connection to any specific Catholic Church or diocese. JPP shall not be liable for any loss, damage or injury suffered or sustained (even if caused by negligence) as a result of accepting and/or using the special promotion. JPP accepts no responsibility for any failure to receive a promotion put forth by a merchant or professional practice on Finding Faber website or mobile app. Unless prior written consent is given by an authorized JPP employee, you may not use, copy, reproduce, modify, create derivative works of, any part of Finding Faber logos, terminology or partnership information.
                        </p>

                        <p>
                            Contact John Patrick Publishing Company at 1707 Fourth Street, Trenton, NJ 08638 or <a href='mailto:info@jppc.net'>info@jppc.net</a> if you have any questions or need further clarification. You may call during standard weekday business hours at <a href='tel:1-609-883-2700'>609-883-2700</a>.
                        </p>

                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(PrivacyPage);