import React, { Component } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.js";

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderRightLinks from "components/Header/HeaderRightLinks.js";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.js";

import './ContactPage.css';
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
const useStyles = makeStyles(styles);
const dashboardRoutes = [];
const clearFields = {
    usersName: '',
    usersAddress: '',
    usersPhone: '',
    usersEmail: '',
    usersMessage: ''
}

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userType: '',
            usersName: '',
            usersAddress: '',
            usersPhone: '',
            usersEmail: '',
            usersMessage: ''
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    };

    selectUserType = (event) => {
        this.setState({ userType: event.target.value, clearFields }, console.log(this.state));
    }

    render() {
        const { classes } = this.props;
        return (
            <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
                <Header
                    color="white"
                    routes={dashboardRoutes}
                    rightLinks={<HeaderRightLinks />}
                    leftLinks={<HeaderLeftLinks />}
                    fixed
                    changeColorOnScroll={{
                        height: 400,
                        color: "white"
                    }}
                />

                <div className={classes.container} style={{ marginTop: '100px', minHeight: '60vh' }}>
                    <img src={require('assets/img/FF_map_only.png')}
                        style={{ position: 'fixed', zIndex: '-10', width: '100%', bottom: '0', opacity: '5%' }}
                    />

                    <h2 style={{ fontWeight: '600', color: '#00784A', borderBottom: '3px solid #FECE00' }}>Contact Us</h2>
                    <div id='' style={{ display: 'flex', flexDirection: 'row', }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%', padding: '20px' }}>
                            <p>
                                Thank you for contacting us at Finding Faber. We are here to help you connect to local customers in real time. In order to do so, please fill out the following fields and we will get back to you right away.
                            </p>
                            <FormHelperText>What is your role?</FormHelperText>
                            <FormControl variant='outlined' color='primary' style={{ margin: '', minWidth: '250px' }}>

                                <InputLabel id='usertypelabel'>I am a...</InputLabel>
                                <Select labelId='usertypelabel' id="usertypeselect" label='I am a...'
                                    value={this.state.userType}
                                    onChange={this.selectUserType}
                                >
                                    <MenuItem value='business'>Business Owner</MenuItem>
                                    <MenuItem value='user'>Finding Faber User</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%', padding: '20px' }}>
                            {this.state.userType === 'business' || this.state.userType === 'user' ?
                                <>
                                    <TextField
                                        // helperText={this.state.editMerchantNameErr ? this.state.editMerchantNameErr : null} 
                                        variant='outlined'
                                        name="usersName" label={this.state.userType === 'business' ? 'Business Name' : 'Name'}
                                    // onFocus={() => this.setState({ focusedInput: "MerchantName" })} 
                                    // value={this.state.editMerchantName} onChange={this.handleInputChange} 
                                    />

                                    {this.state.userType === 'business' ?
                                        <TextField
                                            // helperText={this.state.editMerchantNameErr ? this.state.editMerchantNameErr : null} 
                                            variant='outlined'
                                            name="usersAddress" label='Business Address'
                                        // onFocus={() => this.setState({ focusedInput: "MerchantName" })} 
                                        // value={this.state.editMerchantName} onChange={this.handleInputChange} 
                                        /> : <> </>}

                                    <TextField
                                        // helperText={this.state.editMerchantNameErr ? this.state.editMerchantNameErr : null} 
                                        variant='outlined'
                                        name="usersPhone" label='Phone'
                                    // onFocus={() => this.setState({ focusedInput: "MerchantName" })} 
                                    // value={this.state.editMerchantName} onChange={this.handleInputChange} 
                                    />

                                    <TextField
                                        // helperText={this.state.editMerchantNameErr ? this.state.editMerchantNameErr : null} 
                                        variant='outlined'
                                        name="usersEmail" label='Email'
                                    // onFocus={() => this.setState({ focusedInput: "MerchantName" })} 
                                    // value={this.state.editMerchantName} onChange={this.handleInputChange} 
                                    />

                                    <TextField
                                        // helperText={this.state.editMerchantNameErr ? this.state.editMerchantNameErr : null} 
                                        variant='outlined'
                                        name="usersMessage" label='Your Message'
                                        multiline='true'
                                        rows='3'
                                    // onFocus={() => this.setState({ focusedInput: "MerchantName" })} 
                                    // value={this.state.editMerchantName} onChange={this.handleInputChange} 
                                    />

                                    <Button>Submit</Button>

                                </> : <></>}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ContactPage);