import React, { Component } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderRightLinks from "components/Header/HeaderRightLinks.js";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.js";

import './download.css';
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
const useStyles = makeStyles(styles);
const dashboardRoutes = [];

class AboutPage extends Component {
    // const classes = useStyles();

    constructor(props) {
        super(props);
        this.state = {
            OStype: ''
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        var type = this.getMobileOperatingSystem();
        this.setState({
            OStype: type
        });

        window.location.href = type == "iOS" ? 'https://apps.apple.com/us/app/id1528978308' : 'https://play.google.com/store/apps/details?id=com.findingfaber';

    };

    getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    render() {
        const { classes } = this.props;
        return (
            <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
                <Header
                    color="white"
                    routes={dashboardRoutes}
                    rightLinks={<HeaderRightLinks />}
                    leftLinks={<HeaderLeftLinks />}
                    fixed
                    changeColorOnScroll={{
                        height: 400,
                        color: "white"
                    }}
                //{...rest}
                />
                <div className={classes.container} style={{ marginTop: '100px', minHeight: '60vh' }}>
                    <img src={require('assets/img/FF_map_only.png')}
                        style={{ position: 'fixed', zIndex: '-10', width: '100%', bottom: '0', opacity: '3%' }}
                    />
                    <h2 style={{ fontWeight: '600', color: '#00784A', borderBottom: '3px solid #FECE00' }}>Get The App</h2>
                    <span id='aboutSpan' style={{ display: 'flex', flexDirection: 'column' }}>
                        <span id='appPreview' className='rowSmCol' style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                            <img src={require('assets/img/findingfaberAppCropped.png')} alt='screenshot of Finding Faber app on map view' style={{
                                border: '10px solid black', borderRadius: '15px', maxWidth: '250px'
                            }} />
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <h3>Get the app!</h3>
                                    {/* link to app store here: */}
                                    <a href={this.state.OStype === 'iOS' ? 'https://apps.apple.com/us/app/id1528978308' : 'https://play.google.com/store/apps/details?id=com.findingfaber'} target='_blank'>
                                        <img src={require('assets/img/fake QR codes.png')} alt='qr placeholder' style={{ maxWidth: '500px', maxHeight: '300px', padding: '1rem', margin: '1rem', border: '2px dashed #FECE00' }} />
                                    </a>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <h5>Follow us!</h5>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <a target='_blank' href='https://www.facebook.com/finding.faber'><img style={{ maxWidth: '59px' }} src={require('assets/img/facebook.webp')} alt='facebook icon' /></a>
                                        <a target='_blank' href='https://twitter.com/FaberFinding'><img style={{ maxWidth: '50px' }} src={require('assets/img/twitter.webp')} alt='twitter icon' /></a>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </span>
                </div>
                <Footer />
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(AboutPage);