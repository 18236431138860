/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import Divider from '@material-ui/core/Divider';
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer style={{ zIndex: '1000'}} className={footerClasses}>
      {/* <Divider/> */}
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://www.jppc.net/"
                className={classes.block}
                target="_blank"
              >
                John Patrick Publishing
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link className={classes.block} to='/about'>
                About us
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
            <a className={classes.block} href='https://forms.gle/RYb4veiWnCs4i4Aw6' target='_blank'>
                Contact us
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
            <Link className={classes.block} to='/terms'>
                Terms &amp; Conditions
              </Link>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()} , JPPC | All rights reserved
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
