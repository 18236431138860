import { container, title } from "assets/jss/material-kit-react.js";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";
import { boxShadow } from "assets/jss/material-kit-react";

const profilePageStyle = theme => ({
  container,
  sm1: {
    order: '2',
    [theme.breakpoints.down('xs')]: {
      order: '1',
      marginBottom: '10px'
    }
  },
  sm2: {
    order: '1',
    [theme.breakpoints.down('xs')]: {
      order: '2',
    }
  },
  rowSmCol: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)"
    }
  },
  hoursTable: {
    '& .MuiTab-root': {
      margin: '0!important'
    }
  },
  mainTray: {
    [theme.breakpoints.down('xs')]: {
      padding: '0'
    }
  },
  paraBtn: {
    // alignSelf: 'flex-end',
    marginTop: '100px',
    marginBottom: '10%',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0'
    }
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: "#999",
    textAlign: "center !important"
  },
  name: {
    marginTop: "-80px"
  },
  ...imagesStyle,
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
    paddingBottom: '20px'
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down('xs')]: {
      margin: '0'
    }
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  smFullWidth: {
    width: 'unset',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  promoCardDiv: {
    width: '350px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  navWrapper: {
    margin: "20px auto 50px auto",
    textAlign: "center"
  },
  gridItem: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down("sm")]: {
      justifyContent: 'center'
    }
  },
  expansionRes: {
    margin: '10px',
    [theme.breakpoints.down('xs')]: {
      margin: '0'
    }
  },
  cardRes: {
    margin: '7px 15px',
    minWidth: '250px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      margin: '0',
      padding: '7px',
      width: '100%'
    },
    '& .MuiCard-root':{
      height: 'fit-content',
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.3), 0px 1px 1px 0px rgba(0,0,0,0.3), 0px 1px 3px 0px rgba(0,0,0,0.3)'
    },
    '& #businessHours': {
      [theme.breakpoints.down('xs')]: {
       justifyContent: 'center'
      },
    },

  },
  mobileMarginless: {
    [theme.breakpoints.down('xs')]: {
      margin: '0'
    }
  },
  hide430: {
    "@media (max-width: 455px)": {
      display: 'none',
    }
  },
  show430:{
    display: 'none',  
    "@media (max-width: 455px)": {
      display: 'flex',
      '& .MuiInputBase-root':{
        fontSize: '12px',
        "@media (max-width: 320px)": {
          width: 'min-content'
        }
      },
      '& .MuiButtonBase-root':{
        padding: '0'
      },
    }
  },
  mobilePadless: {
    [theme.breakpoints.down('xs')]: {
      padding: '0'
    }
  },
  editMerchant: {
    '& .MuiDialog-paper': {
      '& .MuiDialogContent-root': {
        '& .MuiFormControl-root': {
          width: '275px',
        },
        '& .MuiButtonBase-root': {
          margin: '3px',
        },
        '& .MuiTableCell-root':{
          "@media (max-width: 320px)": {
            padding: '8px'
          },
          '& .MuiFormControl-root': {
            width: '100%',
          },
        },
        '& h4': {
          margin: '25px 0 0 0',
          padding: '15px 0',
          borderTop: '1px solid #252326'
        }
      },
      '& #cropScreen': {
        '& div:first-child': {
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            '& span': {
              width: '100%'
            },
          },
          '& .cropPreview': {
            padding: '0 0 10px 0',
            [theme.breakpoints.down('xs')]: {
              alignItems: 'center',
              padding: '10px 0'
            }
          }
        }
      },
      '& .MuiDialogActions-root': {
        [theme.breakpoints.down('xs')]: {
          padding: '8px 16px'
        },
      }
    }

  },
  hideSmall: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
    }
  },
  //promo dialog
  promoDetail: {
    '& .MuiDialog-paper': {
      '& .MuiDialogContent-root': {
        '& .MuiButtonBase-root': {
          margin: '7px',
        },
        '& .MuiCheckbox-root':{
          margin: '0 7px',
          padding: '0 9px',
        },
      },
      '& form>span>span:first-child': {
        display: 'flex',
        flexWrap: 'row',
          [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
          },
      },
      '& form>span>span:last-child':{
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column'
        },
        '& .MuiFormControl-root':{
          width: '70%',
          [theme.breakpoints.down('xs')]: {
            width: '100%'
          },
        },
        '& label':{
            width: '100%'
        },
        '& .MuiButtonBase-root': {
          width: '100%'
        },
      },
  }
},
resDia: {
  zIndex: '9500',
  margin: '0 auto',
  maxWidth: '800px',
  '& .MuiDialog-paper': {
    [theme.breakpoints.down('xs')]: {
      margin: '0',
      width: '100%'
    },
    '& .MuiDialogTitle-root': {

      // [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flex: 'row',
        justifyContent: 'flex-start',
        padding: '16px',
        alignItems: 'center',
      // },

      '& .MuiButtonBase-root': {
        [theme.breakpoints.down('xs')]: {
          marginRight: '20px'
        },
      }
    },
    '& .MuiDialogContent-root':{
      [theme.breakpoints.down('xs')]: {
        padding: '8px 16px'
      },
      '& .MuiFormControl-root': {
        [theme.breakpoints.down('xs')]: {
          width: '100%'
        },      
      },
      '& .MuiButtonBase-root': {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          margin: '7px 0'
        },
      },
    },
    '& .MuiDialogActions-root': {
      '& .MuiButton-root':{
        fontWeight: 'bold'
      }
    },
  }
},
});

export default profilePageStyle;
