
var validator = {

    csc(csc) {
        var v = {
            valid: false,
            reason: "Invalid CSC/CVV",
            type: 1
        }
        if (csc.length > 2 && csc.length < 5 && !isNaN(csc)) {
            v = {
                valid: true,
                reason: "",
                type: 1
            }
        }
        return v;
    },

    month(month, year) {
        var v = {
            valid: false,
            reason: "",
            type: 1
        }
        if (month.length > 0 && month.length < 3 && !isNaN(month)) {
            if (year.length == 4) {
                var date = new Date(Number(year), Number(month) - 1);
                //console.log(date);
                if (date < new Date()) {
                    v = {
                        valid: false,
                        reason: "This card has expired",
                        type: 1
                    }
                } else {
                    v = {
                        valid: true,
                        reason: "",
                        type: 1
                    }
                }
            } else {
                v = {
                    valid: true,
                    reason: "",
                    type: 1
                }
            }
        } else {
            v = {
                valid: false,
                reason: "Invalid Month",
                type: 1
            }
        }
        return v;
    },

    year(month, year) {
        var v = {
            valid: false,
            reason: "",
            type: 1
        }
        if (year.length == 4 && !isNaN(year)) {
            if (month.length > 0 && month.length < 3) {
                var date = new Date(Number(year), Number(month) - 1);
                //console.log(date);
                if (date < new Date()) {
                    return {
                        valid: false,
                        reason: "This card has expired",
                        type: 1
                    }
                } else {
                    v = {
                        valid: true,
                        reason: "",
                        type: 1
                    }
                }
            } else {
                v = {
                    valid: true,
                    reason: "",
                    type: 1
                }
            }
        } else {
            v = {
                valid: false,
                reason: "Invalid Year",
                type: 1
            }
        }
        return v;
    },


    email(email) {
        var v = {
            valid: true,
            reason: '',
            type: 1
        }
        var patt = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
        if (patt.test(email) == false) {
            v.valid = false;
            v.type = 1;
            v.reason = "Invalid Email Address.";
            return v;
        }
        return v;
    },

    acc_number(number) {
        var v = {
            valid: true,
            reason: '',
            type: 1
        }
        if (!number) {
            v.valid = false;
            v.type = 1;
            v.reason = "Invalid Account Number.";
            return v;
        }
        if (this.account_number.test(number) == false) {
            v.valid = false;
            v.type = 1;
            v.reason = "Invalid Account Number.";
            return v;
        }
        return v;
    },


    routing_number(routing) {
        var v = {
            valid: true,
            reason: '',
            type: 1
        }
        if (!routing) {
            v.valid = false;
            v.reason = "Invalid Routing Number."
            return v;
        }
        if (routing.length !== 9) {
            v.valid = false;
            v.reason = "Invalid Routing Number."
            return v;
        }

        var checksumTotal = (7 * (parseInt(routing.charAt(0), 10) + parseInt(routing.charAt(3), 10) + parseInt(routing.charAt(6), 10))) +
            (3 * (parseInt(routing.charAt(1), 10) + parseInt(routing.charAt(4), 10) + parseInt(routing.charAt(7), 10))) +
            (9 * (parseInt(routing.charAt(2), 10) + parseInt(routing.charAt(5), 10) + parseInt(routing.charAt(8), 10)));

        var checksumMod = checksumTotal % 10;
        if (checksumMod !== 0) {
            v.valid = false;
            v.reason = "Invalid Routing Number."
        }
        return v;
    },

    validatePassword(np, control) {
        var v = {
            valid: true,
            reason: '',
            type: 1
        };
        v.valid = true;
        v.reason = '';
        // if (np != np2) {
        //   v.valid = false;
        //   v.type = 2;
        //   v.reason = 'New passwords do not match. Passwords are case sensitive.';
        //   return v;
        // }
        if (np.length < 8) {
            v.valid = false;
            v.type = 1;
            v.reason = "Password must be at least 8 characters long.";
            return v;
        }
        var patt1 = /\d/g;
        if (patt1.test(np) == false) {
            v.valid = false;
            v.type = 1;
            v.reason = "Password must contain at least 1 number.";
            return v;
        }
        patt1 = /[A-Z]/i;
        if (patt1.test(np) == false) {
            v.valid = false;
            v.type = 1;
            v.reason = "Password must contain at least 1 letter.";
            return v;
        }
        if (np == control.MerchantName) {
            v.valid = false;
            v.type = 1;
            v.reason = "Password cannot be identical to the Merchant Name.";
            return v;
        }
        if (np == control.Email) {
            v.valid = false;
            v.type = 1;
            v.reason = "Password cannot be Merchant Email address.";
            return v;
        }
        if (np == control.ContactName) {
            v.valid = false;
            v.type = 1;
            v.reason = "Password cannot be Merchant contact name.";
            return v;
        }
        return v;
    },

    name(name) {
        var v = {
            valid: true,
            reason: '',
            type: 1
        };
        var pattern = /[*|\":<>[\]{}`\\();@&$?]/
        if(!name){
            v.valid = false;
            v.type = 1;
            v.reason = "Name is required.";
            return v;
        } else  if (pattern.test(name)) {
            v.valid = false;
            v.type = 1;
            v.reason = "Name can not contain special characters.";
            return v;
        }
        return v;
    },

    address(address) {
        var v = {
            valid: true,
            reason: '',
            type: 1
        };
        if (address.split(" ").length < 2) {
            v.valid = false;
            v.type = 1;
            v.reason = "Please review address.";
            return v;
        }
        return v;
    },

    zip(zip) {
        var v = {
            valid: true,
            reason: '',
            type: 1
        };
        var pattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
        if(!pattern.test(zip)){
            v.valid = false;
            v.type = 1;
            v.reason = "Please review zipcode.";
            return v;
        }
        return v;
    },

    phone(phone) {
        var v = {
            valid: true,
            reason: '',
            type: 1
        };
        var patt = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
        if(!patt.test(phone)){
            v.valid = false;
            v.type = 1;
            v.reason = "Please review phone number.";
            return v; 
        }
        return v;
    },

    website(site) {
        var v = {
            valid: true,
            reason: '',
            type: 1
        };
        var pattern = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
        if(!pattern.test(site)){
            v.valid = false;
            v.type = 1;
            v.reason = "Please review website.";
            return v;
        }
        return v;
    }
}


export default validator;