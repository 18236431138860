import axios from 'axios';
import Geocode from "react-geocode";
// FireBase
import firebase from '../config/firebase';

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyA1j2uFfuMreyOwK5pkJ6XysUtlHkhCllQ");
// set response language. Defaults to english.
Geocode.setLanguage("en");
// Enable or disable logs. Its optional.
Geocode.enableDebug();

async function checkSession(){
    var t = await firebase.getUserData();
    if(t.status != 200){
        return false;
    } else {
        return true;
    }
}

var api = {

    extendUserSession: () => {
        return checkSession();
    },

    // Google Map APIs START /////
    // Get latidude & longitude from address or place name.
    getLatLong: async (data) => {
        if(await !checkSession()){
            return {sessionOut: "Your Session Has Expired. Please log back in."};
        }
        return Geocode.fromAddress(data).then(
            response => {
              return response;
            },
            error => {
              console.error(error);
              return error;
            }
        );
    },

    // Get address from latidude & longitude.
    getAddress: async (data) => {
        if(await !checkSession()){
            return {sessionOut: "Your Session Has Expired. Please log back in."};
        }
        // console.log("data: ", data);
        return Geocode.fromLatLng(data.lat, data.long).then(
            response => {
              const address = response.results[0].formatted_address;
              //console.log("address 32: ", address);
              return response;
            },
            error => {
              console.error(error);
              return error;
            }
        );
    },
    // Google Map APIs End ///

    logout: () => {
        firebase.logout();
    },

    login: async (data) => {
        var uri = "/api/authenticate"
        return axios.post(uri, data,
            {
                headers: {
                    Authorization: 'Bearer ' + data
                }
            }
        ).then((res) => {
            return res;
        }).catch((err) => {
            return err;
        });
    },
    loginMerchant: async (data) => {
        var uri = "/api/authenticate/" + data.merchantID
        return axios.post(uri, data.idToken,
            {
                headers: {
                    Authorization: 'Bearer ' + data.idToken
                }
            }
        ).then((res) => {
            //console.log(res);
            return res;
        }).catch((err) => {
            return err;
        });
    },
    createManager: async (d) => {
        if(await !checkSession()){
            return {sessionOut: "Your Session Has Expired. Please log back in."};
        }
        var uri = "/api/createManager"
         //console.log(d);
        return axios.post(uri, d.data,
            {
                headers: {
                    Authorization: 'Bearer ' + d.idToken
                }
            }
        ).then((res) => {
            //console.log(res);
            return res;
        }).catch((err) => {
            //console.log(err);
            return err;
        });
    },
    updateManager: async (d) => {
        if(await !checkSession()){
            return {sessionOut: "Your Session Has Expired. Please log back in."};
        }
        var uri = '/api/updateManager';
        //console.log(d);
        return axios.post(uri, d.data, {
            
            headers: {
                Authorization: 'Bearer ' + d.idToken
            }
        }
        ).then((res) => {
            return res;
        }).catch((err) => {
            return err;
        })
    },
    updatePassword: async (d) => {
        if(await !checkSession()){
            return {sessionOut: "Your Session Has Expired. Please log back in."};
        }
        var uri = 'api/updatePassword';
        return axios.post(uri, d.data, {
            
            headers: {
                Authorization: 'Bearer ' + d.idToken
            }
        }
        ).then((res) => {
            return res;
        }).catch((err) => {
            return err;
        })
    },
    createPromotion: async (d) => {
        if(await !checkSession()){
            return {sessionOut: "Your Session Has Expired. Please log back in."};
        }
        var uri = "/api/createPromotion"
         ////console.log(d);
        return axios.post(uri, d.data,
            {
                headers: {
                    Authorization: 'Bearer ' + d.idToken
                }
            }
        ).then((res) => {
            return res;
        }).catch((err) => {
            return err;
        });
    },
    searchPromotion: async (d) => {
        var uri = "/api/searchPromotion";
        //console.log(d);
        return axios.post(uri, d.data,
            {
                headers: {
                    Authorization: 'Bearer ' + d.idToken
                }
            }
        ).then((res) => {
            return res;
        }).catch((err) => {
            return {data: [], err: err};
        });
    },
    updateMerchant: async (d) => {
        if(await !checkSession()){
            return {sessionOut: "Your Session Has Expired. Please log back in."};
        }
        //console.log(d.data);
        var uri = "/api/updateMerchant";
        // //console.log(d);
        return axios.post(uri, d.data,
            {
                headers: {
                    Authorization: 'Bearer ' + d.idToken
                }
            }
        ).then((res) => {
            return res;
        }).catch((err) => {
            return err;
        });
    },
    searchMerchant: async (d) => {
        //console.log(d);
        var uri = '/api/searchMerchant';
        return axios.post(uri, d).then((res) => {
            //console.log(res);
            return res;
        }).catch((err) => {
            console.log(err);
            return {data: [], err: err};
        });
    },
    getMerchantPromotion: async (d) => {
        //console.log(d);
        var uri = '/api/Promotions/' + d.merchantID;
        return axios.get(uri, {
            headers: {
                Authorization: 'Bearer ' + d.idToken
            }
        }).then(res => {
            if(res.data){
                for(var i = 0; i < res.data.length; i++){
                    if(res.data[i].status != 1){
                        res.data.splice(i, 1);
                        i--;
                    }
                }
            }
            return  res;
        }).catch(err =>{
            return {data: [], err: err};
        });
    },
    getMerchantInfos: merchantID => {
        //console.log("merchantID: ", merchantID);
        var uri = "/api/Merchants/" + merchantID;
        return axios.get(uri).then(res => { return res; }).catch(err => {return err})
    },
    deletePromotion: async (d) => {
        if(await !checkSession()){
            return {sessionOut: "Your Session Has Expired. Please log back in."};
        }
        var uri = '/api/Promotions/' + d.merchantPromotionID;
        return axios.delete(uri, {
            headers: {
                Authorization: 'Bearer ' + d.idToken
            }
        }).catch(err => {return err});
    },
    updatePromotion: async (d) => {
        if(await !checkSession()){
            return {sessionOut: "Your Session Has Expired. Please log back in."};
        }
        //console.log(d);
        var uri = '/api/updatePromotion';
        return axios.post(uri, d.data, {
            headers: {
                Authorization: 'Bearer ' + d.idToken
            }
        }).then(res => {
            return res;
        }).catch(err => {
            //console.log(err);
            return err;
        })
    },
    updateSubCat : async (d) => {
        if(await !checkSession()){
            return {sessionOut: "Your Session Has Expired. Please log back in."};
        }
        //console.log(d);
        var uri ='/api/updateMerchantSubcat';
        return axios.post(uri, d.data,
            {
                headers: {
                    Authorization: 'Bearer ' + d.idToken
                }
            }
        ).then((res) => {
            var uri2 = "/api/Merchants/" + d.merchantID;
            return axios.get(uri2).then(r => { 
                return r; 
            }).catch(er => {return er})
            // return res;
        }).catch((err) => {
            return err;
        });
        // merchantID: 18390
    },

    NewMerchantList: async (d) => {
        if(await !checkSession()){
            return {sessionOut: "Your Session Has Expired. Please log back in."};
        }
        //console.log(d);
        var uri = '/api/NewMerchantList'
        var fileDt = new FormData();
        //console.log(d.data);
        fileDt.append("file", d.data);
        return axios.post(uri, fileDt, {
            headers: { 
                Authorization: 'Bearer ' + d.idToken,
                'Content-Type': 'multipart/form-data'
             }
        }).then((res) => {
            return res;
        }).catch((err) => {
            return err;
        });
    },
    
    DeactiveMerchantList: async (d) => {
        if(await !checkSession()){
            return {sessionOut: "Your Session Has Expired. Please log back in."};
        }
        //console.log(d);
        var uri = '/api/DeactiveMerchantList'
        var fileDt = new FormData();
        //console.log(d.data);
        fileDt.append("file", d.data);
        return axios.post(uri, fileDt, {
            headers: { 
                Authorization: 'Bearer ' + d.idToken,
                'Content-Type': 'multipart/form-data'
             }
        }).then((res) => {
            return res;
        }).catch((err) => {
            return err;
        });
    },

    upLoadImg: async (d) => {
        if(await !checkSession()){
            return {sessionOut: "Your Session Has Expired. Please log back in."};
        }
        //console.log(d);
        var uri = '/api/UploadMerchantFile'
        var fileDt = new FormData();
        //console.log(d.data);
        fileDt.append("file", d.data);
        return axios.post(uri, fileDt, {
            headers: { 
                Authorization: 'Bearer ' + d.idToken,
                'Content-Type': 'multipart/form-data'
             }
        }).then((res) => {
            return res;
        }).catch((err) => {
            return err;
        });
    },
    sendPwReset: async (d) => {
        if(await !checkSession()){
            return {sessionOut: "Your Session Has Expired. Please log back in."};
        }
        //console.log(d);
        var uri = '/api/resetPassword/'+ d;
        return axios.get(uri)
        .then((res) => {
            //console.log(res);
            return res;
        }).catch((err) => {
            //console.log(err);
            return err;
        })
    },
    searchOrg: async (d) => {
        if(await !checkSession()){
            return {sessionOut: "Your Session Has Expired. Please log back in."};
        }
        //console.log(d);
        var uri ='/api/searchOrganization';
        return axios.post(uri, d.data, {
            headers: { 
                Authorization: 'Bearer ' + d.idToken
             }
        }).then(res => {
            //console.log(res);
            return res;
        }).catch(err => {
            //console.log(err);
            return err;
        })

    },
    getCategories: async () => {
        var uri = '/api/GetAllCategory'
        return axios.get(uri)
        .then((res) => {
            ////console.log(res);
            return res;
        }).catch((err) => {
            //console.log(err);
            return err;
        })
    },
    increaseMerchantCount: async (merchantID) => {
        var uri = '/api/increaseMerchantCount/' + merchantID
        return axios.get(uri)
        .then((res) => {
            ////console.log(res);
            return res;
        }).catch((err) => {
            //console.log(err);
            return err;
        })
    },
    increaseMerchantFollower: async (merchantID) => {
        var uri = '/api/increaseMerchantFollower/' + merchantID
        return axios.get(uri)
        .then((res) => {
            ////console.log(res);
            return res;
        }).catch((err) => {
            //console.log(err);
            return err;
        })
    },
    decreaseMerchantFollower: async (merchantID) => {
        var uri = '/api/decreaseMerchantFollower/' + merchantID
        return axios.get(uri)
        .then((res) => {
            ////console.log(res);
            return res;
        }).catch((err) => {
            //console.log(err);
            return err;
        })
    },
    increasePromotionCount: async (merchantPromotionID) => {
        var uri = '/api/increasePromotionCount/' + merchantPromotionID
        return axios.get(uri)
        .then((res) => {
            ////console.log(res);
            return res;
        }).catch((err) => {
            //console.log(err);
            return err;
        })
    }
}

export default api;