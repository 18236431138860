import {
  roseColor,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor
} from "assets/jss/material-kit-react.js";

const navPillsStyle = theme => ({
  root: {
    // marginTop: "20px",
    paddingLeft: "0",
    marginBottom: "0",
    overflow: "visible !important",
    lineHeight: "24px",
    textTransform: "uppercase",
    fontSize: "18px",
    fontWeight: "600",
    position: "relative",
    display: "block",
    color: "inherit",
  },
  flexContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexWrap: "wrap"
    }
  },
  displayNone: {
    display: "none !important"
  },
  fixed: {
    overflow: "visible !important"
  },
  horizontalDisplay: {
    display: "block"
  },
  pills: {
    float: "left",
    position: "relative",
    display: "block",
    borderRadius: "30px",
    minWidth: "100px",
    textAlign: "center",
    transition: "all .3s",
    padding: "10px 15px",
    color: "#555555",
    height: "auto",
    opacity: "1",
    maxWidth: "100%",
    margin: "0 5px"
  },
  pillsWithIcons: {
    borderRadius: "4px"
  },
  tabShape: {
    // width: '30%',
    display: "block",
    backgroundColor: '#f6f6f6',
    // minWidth: "145px",
    textAlign: "center",
    transition: "all .3s",
    padding: "15px",
    borderRadius: '5px 5px 0px 0px',
    boxShadow: '0px -15px 15px rgba(158,158,158,.2)'
  },
  tabIcon: {
    width: "30px",
    height: "30px",
    display: "block",
    margin: "15px 0 !important",
    "&, & *": {
      letterSpacing: "normal !important"
    }
  },
  horizontalPills: {
    width: "100%",
    float: "none !important",
    "& + button": {
      margin: "10px 0"
    }
  },
  contentWrapper: {
    // paddingTop: "20px",
    boxShadow: '15px -15px 20px 0px rgba(158,158,158,.1)'
  },
  primary: {
    zIndex: '3',
    "&,&:hover": {
      // color: "#FFFFFF",
      backgroundColor: '#FFF',
      opacity: '1',
      
      // boxShadow:
      //   "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(1, 118, 181, 0.4)"
    },
  '& .MuiTab-wrapper': {
    // backgroundColor: '#FECE00',
    // border: '1px solid #252326'
    fontWeight: '700',
    fontSize: '16px'
  }
  },
  info: {
    "&,&:hover": {
      color: "#FFFFFF",
      backgroundColor: infoColor,
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4)"
    }
  },
  success: {
    "&,&:hover": {
      color: "#FFFFFF",
      backgroundColor: successColor,
      boxShadow:
        "0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)"
    }
  },
  warning: {
    "&,&:hover": {
      color: "#FFFFFF",
      backgroundColor: warningColor,
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4)"
    }
  },
  danger: {
    "&,&:hover": {
      color: "#FFFFFF",
      backgroundColor: dangerColor,
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4)"
    }
  },
  rose: {
    "&,&:hover": {
      color: "#FFFFFF",
      backgroundColor: roseColor,
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)"
    }
  },
  alignCenter: {
    alignItems: "center",
    justifyContent: "center"
  },
  tabWrapper: {
    borderRadius: '5px',
    opacity: '.7',
    color: "#252326",
    // backgroundColor: 'lightgrey',
    position: "relative",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "600",
    textTransform: "uppercase",
    padding: '5px',
    "&,& *": {
      letterSpacing: "normal"
    }
  }
});

export default navPillsStyle;
