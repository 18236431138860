import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import WebIcon from '@material-ui/icons/Web';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>WHY CHOOSE JOHN PATRICK ADVANTAGE?</h2>
          <h5 className={classes.description}>
          John Patrick Publishing offers a broad range of services which will assist 
          your parish in accomplishing your goal of spreading the good news! Please 
          review some of the many services we offer and do not hesitate to call with 
          any questions or perhaps you have some ideas which we could help you implement. 
          Our staff has extensive experience in communication, printing, website development, 
          and they are available to work along with you in providing a unique package of services 
          designed specifically for your church. John Patrick Publishing excels in customer service 
          and we want to partner with you! Contact us today to find out how we can enrich and enhance 
          your parish mission!
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Printing Services"
              description="We have extensive experience in printing Newsletters, Brochures, Letterhead, Envelopes, Business Cards, Campaign Materials, and much more. We offer design and layout services to make sure your project is perfect from start to finish."
              icon={LocalPrintshopIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Weekly Bulletins"
              description="John Patrick Publishing is the premier church bulletin company serving the Catholic Church, and currently producing weekly bulletins and other publications for over 500 churches all along the Eastern Seaboard from New England to Florida."
              icon={FormatListBulletedIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Bulletin Advertising"
              description="John Patrick Publishing prints and delivers the weekly news for the church. Local businesses are part of the weekly publication. Your support helps to print the bulletin for the church and gives you a constant weekly presence in the community. The bulletin is handed out at church, available throughout the week and online."
              icon={WebIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
